import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

import Avatar from '@/components/Avatar'
import Badge from '@/components/Badge'
import DataTable from '@/components/DataTable'
import More, { MoreButton } from '@/components/More'
import PropTypes from '@/lib/propTypes'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import { RoleStatusType } from '@/api/videobot.schemas'
import { ResendInviteModal } from '@/features/Users/components/resend_invite_modal.component'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import theme, { breakpoints } from '../../../../lib/theme'
import DeleteUserModal from '../DeleteUserModal'
import ResetPaswordModal from '../ResetPaswordModal'

const getVariantByRole = (role) => {
	switch (role) {
		case 'Owner':
			return 'primary'
		case 'Reseller':
			return 'orange'
		case 'Member':
			return 'purple'
		case 'Admin':
			return 'dark'
		default:
			return 'muted'
	}
}

const UserMenu = ({
	row,
	onResetPasswordClick,
	onEditClick,
	onDeleteClick,
	onResendInviteClick,
}) => {
	const activeAccount = useCurrentAccount()
	const user = useCurrentUser()
	const { t } = useTranslation(['user'])
	const canResendInviteEmail = row.role.status !== RoleStatusType.Active

	return (
		<React.Fragment>
			{(user.isSuperuser ||
				['Owner'].includes(activeAccount.role) ||
				row.role.role === 'Member') && (
				<More>
					<React.Fragment>
						<MoreButton onClick={() => onResetPasswordClick(row)}>
							{t('user:sendResetPasswordLink')}
						</MoreButton>
						{canResendInviteEmail && (
							<MoreButton onClick={() => onResendInviteClick(row)}>
								{t('user:resendInviteEmail')}
							</MoreButton>
						)}
						<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
						<MoreButton onClick={() => onDeleteClick(row)} variant="red">
							{t('remove')}
						</MoreButton>
					</React.Fragment>
				</More>
			)}
		</React.Fragment>
	)
}

UserMenu.propTypes = {
	row: PropTypes.object,
	onResetPasswordClick: PropTypes.func,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
}

const UsersList = ({ users, onDelete, pageSize, totalCount, onPageChange, page, accountId }) => {
	const { t } = useTranslation(['account', 'user'])
	const navigate = useNavigate()
	const [userToResetPassword, setUserToResetPassword] = useState(false)
	const [userToDelete, setUserToDelete] = useState(false)
	const [userToResendInvite, setUserToResendInvite] = useState(null)
	const { search: urlQuery } = useLocation()

	const { windowWidth } = useWindowSize()

	const onEditClick = (user) => {
		navigate(`/dashboard/users/${user.id}${urlQuery}`)
	}
	const onResetPasswordClick = (user) => {
		setUserToResetPassword(user)
	}
	const onDeleteClick = (user) => {
		setUserToDelete(user)
	}
	const onResendInviteClick = (user) => {
		setUserToResendInvite(user)
	}

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: windowWidth > breakpoints.lg,
			button: true,
			cell: (row) => {
				return (
					<UserMenu
						row={row}
						onResetPasswordClick={onResetPasswordClick}
						onEditClick={onEditClick}
						onDeleteClick={onDeleteClick}
					/>
				)
			},
		},
		{
			name: '',
			width: '4.7rem',
			selector: (row) => row.role,
			cell: (row) => {
				return (
					<Avatar
						src={row.profilePicture?.url}
						name={row.firstName}
						variant={getVariantByRole(row.role.role)}
					></Avatar>
				)
			},
		},
		{
			name: t('account:fields.email.label'),
			sortable: false,
			grow: 2,
			minWidth: '200px',
			selector: (row) => row.email,
		},
		{
			name: t('account:fields.firstName.label'),
			selector: (row) => row.firstName,
			sortable: false,
		},
		{
			name: t('account:fields.lastName.label'),
			selector: (row) => row.lastName,
			sortable: false,
		},
		{
			name: t('account:fields.role.label'),
			selector: (row) => row.role,
			cell: (row) => {
				return (
					<React.Fragment>
						<Badge variant={getVariantByRole(row.role.role)}>
							{t(`user:roles.${row.role.role.toLowerCase()}`)}
						</Badge>
						{row.role.status === 'Invited' && (
							<Badge variant="yellow">{t('user:invited')}</Badge>
						)}
					</React.Fragment>
				)
			},
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<UserMenu
						row={row}
						onResetPasswordClick={onResetPasswordClick}
						onEditClick={onEditClick}
						onDeleteClick={onDeleteClick}
						onResendInviteClick={onResendInviteClick}
					/>
				)
			},
		},
	]

	const conditionalRowStyles = [
		{
			when: (row) => row.role.status === 'Invited',
			style: {
				color: theme.color.muted,
			},
		},
	]

	return (
		<React.Fragment>
			{users && (
				<DataTable
					columns={columns}
					conditionalRowStyles={conditionalRowStyles}
					onRowClicked={onEditClick}
					data={users.map(
						({ id, firstName, lastName, email, roles, profilePicture }) => ({
							id,
							firstName,
							lastName,
							email,
							role: roles[0],
							profilePicture,
						}),
					)}
					pageSize={pageSize}
					totalCount={totalCount}
					onPageChange={onPageChange}
					page={page}
				/>
			)}
			<AnimatePresence>
				{userToDelete && (
					<DeleteUserModal
						user={userToDelete}
						onClose={() => setUserToDelete(false)}
						onDelete={onDelete}
					/>
				)}
				{userToResetPassword && (
					<ResetPaswordModal
						user={userToResetPassword}
						onClose={() => setUserToResetPassword(false)}
					/>
				)}
				{userToResendInvite && (
					<ResendInviteModal
						user={userToResendInvite}
						accountId={accountId}
						onClose={() => setUserToResendInvite(false)}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

UsersList.propTypes = {
	users: PropTypes.array,
	onDelete: PropTypes.func,
}

UsersList.propTypes = {}

export default UsersList
