import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { produce } from 'immer'
import { maxBy, cloneDeep, kebabCase, capitalize } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FormGroup from '@/components/FormGroup'
import { Col, Row } from '@/components/Grid'
import InfoIcon from '@/components/InfoIcon'
import ListTable from '@/components/ListTable/ListTable'
import More, { MoreButton } from '@/components/More'
import Panel from '@/components/Panel'
import { breakpoints } from '@/lib/theme'
import { getNextId } from '@/lib/utils'
import { useReadAccountIntegrationsById } from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FEATURE_FLAGS } from '@/lib/constants'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

import useEditorFormPrompt from '../../hooks/useEditorPrompt'
import ExitEditorModal from '../ExitEditorModal'
import EditFormFieldPanel from './EditFormFieldPanel'

const FormFieldMenu = ({ row, onEditClick, onDeleteClick }) => {
	const { t } = useTranslation(['user'])

	return (
		<More>
			<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

const EditFormActions = ({ onSave, onDelete, onDuplicate, saveDisabled }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<div className="ml-0 mr-auto">
				<Button onClick={onDelete} variant="red">
					{t('delete')}
				</Button>
				<Button onClick={onDuplicate} variant="secondary">
					{t('duplicate')}
				</Button>
			</div>
			<Button onClick={onSave} disabled={saveDisabled}>
				{t('save')}
			</Button>
		</React.Fragment>
	)
}

const EditFormPanel = () => {
	const { formId } = useParams()

	const { t } = useTranslation(['integrations', 'videobot'])
	const activeAccount = useCurrentAccount()
	const { data: featureData } = useFeatureFlags({ account_id: activeAccount?.id })
	const {
		watch: watchEditor,
		actions: { deleteAction: deleteForm, updateAction: updateForm, addAction },
	} = useVideobotEditor()
	const actions = watchEditor('actions')
	const formData = actions.find((o) => o.id.toString() === formId.toString())

	const navigate = useNavigate()
	const { data: accountIntegrations } = useReadAccountIntegrationsById(activeAccount.id, {
		query: { enabled: featureData?.includes(FEATURE_FLAGS.INTEGRATION) },
	})
	const formRef = useRef()
	const {
		register,
		control,
		setValue,
		formState: { errors, isDirty },
		handleSubmit,
		reset,
		watch,
		getValues,
	} = useForm({
		mode: 'onBlur',
		values: formData,
	})

	const currentForm = watch()

	const hubspotEnabled = watch('hubspot.enabled')
	const linearEnabled = watch('linear.enabled')
	const pipedriveEnabled = watch('pipedrive.enabled')
	const pipedriveTitleValue = watch('pipedrive.titleValue')
	const salesforceEnabled = watch('salesforce.enabled')

	const salesforceFields = [
		{
			type: 'text',
			name: 'last-name',
			label: t('account:fields.lastName.label'),
			required: true,
		},
		{
			type: 'text',
			name: 'company',
			label: t('account:fields.company.label'),
			required: true,
		},
	]

	const hubspotFields = [
		{
			type: 'text',
			name: 'first-name',
			label: t('account:fields.firstName.label'),
			required: true,
		},
		{
			type: 'text',
			name: 'last-name',
			label: t('account:fields.lastName.label'),
			required: true,
		},
		{
			type: 'email',
			name: 'email',
			label: t('account:fields.email.label'),
			required: true,
		},
	]

	const linearFields = [
		{
			type: 'text',
			name: 'first-name',
			label: t('account:fields.firstName.label'),
			required: true,
		},
		{
			type: 'text',
			name: 'last-name',
			label: t('account:fields.lastName.label'),
			required: true,
		},
		{
			type: 'email',
			name: 'email',
			label: t('account:fields.email.label'),
			required: true,
		},
		{
			type: 'text',
			name: 'info',
			label: t('account:fields.info.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'telephone',
			label: t('account:fields.tel.label'),
			required: true,
		},
		{
			type: 'text',
			name: 'source-url',
			label: t('account:fields.sourceUrl.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'utm-source',
			label: t('account:fields.utmSource.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'utm-medium',
			label: t('account:fields.utmMedium.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'utm-campaign',
			label: t('account:fields.utmCampaign.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'utm-term',
			label: t('account:fields.utmTerm.label'),
			required: false,
		},
		{
			type: 'text',
			name: 'utm-content',
			label: t('account:fields.utmContent.label'),
			required: false,
		},
		{
			type: 'postal_code_fi',
			name: 'postal-code',
			label: t('account:fields.postalCode.label'),
			required: false,
		},
		{
			type: 'general_checkbox',
			name: 'gdpr-agreement',
			label: t('account:fields.gdprAgreement.placeholder'),
			required: true,
		},
	]

	const [fieldToEdit, setFieldToEdit] = useState(null)
	const { isSubmitted, setIsSubmitted, showPrompt, acceptPrompt, cancelPrompt } =
		useEditorFormPrompt({
			message: t('leavePageQuestion'),
			when: isDirty,
			condition: (url) => url.match(/.*videobots\/\d.\/content\//g),
		})

	const handleRemoveField = (payload) => {
		const fields = getValues('fields')
		setValue(
			'fields',
			fields.filter((o) => o.id !== payload.id),
			{ shouldDirty: true },
		)
	}

	const checkPipedriveValidation = (field) =>
		field && pipedriveEnabled && field.id === currentForm?.pipedrive?.titleValue

	const checkHubspotValidation = (field) =>
		field && hubspotEnabled && !!hubspotFields.find((o) => o.name === field.name)

	const checkLinearValidation = (field) =>
		field &&
		(linearEnabled || currentForm?.linear?.enabled) &&
		!!linearFields.find((o) => o.name === field.name)

	const checkSalesforceValidation = (field) =>
		field && salesforceEnabled && !!salesforceFields.find((o) => o.name === field.name)

	const optionsColumns = [
		{
			name: t('videobot:formElementFields.type.label'),
			selector: (row) => {
				switch (row.type) {
					case 'general_checkbox':
						return 'Checkbox'
					case 'postal_code_fi':
						return 'Finnish postal code'
					default:
						return capitalize(row.type)
				}
			},
		},
		{
			name: t('videobot:formElementFields.label.label'),
			selector: (row) => row.label,
		},
		{
			name: t('videobot:formElementFields.validation.label'),
			cell: (row) =>
				row.required ? (
					<React.Fragment>
						{t('videobot:formElementFields.validation.required')}
						{checkPipedriveValidation(row) && (
							<InfoIcon noWrap variant="square" name="pipedrive">
								{t('integrations:requiredBy', { name: 'Pipedrive' })}
							</InfoIcon>
						)}
						{checkHubspotValidation(row) && (
							<InfoIcon noWrap variant="square" name="hubspot">
								{t('integrations:requiredBy', { name: 'Hubspot' })}
							</InfoIcon>
						)}
						{checkLinearValidation(row) && (
							<InfoIcon noWrap variant="square" name="linear">
								{t('integrations:requiredBy', { name: 'Linear' })}
							</InfoIcon>
						)}
						{checkSalesforceValidation(row) && (
							<InfoIcon noWrap variant="square" name="salesforce">
								{t('integrations:requiredBy', { name: 'Salesforce' })}
							</InfoIcon>
						)}
					</React.Fragment>
				) : (
					t('videobot:formElementFields.validation.optional')
				),
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<FormFieldMenu
						row={row}
						onDeleteClick={() => handleRemoveField(row)}
						onEditClick={() => setFieldToEdit(row)}
					/>
				)
			},
		},
	]

	const handleSave = (data) => {
		const parsedData = { ...data, isNew: false }
		if (pipedriveEnabled) {
			parsedData.pipedrive.personId = Number.parseInt(parsedData.pipedrive.personId)
			parsedData.pipedrive.titleValue = parsedData.pipedrive.titleValue || ''
		}
		if (salesforceEnabled) {
			parsedData.salesforce.status = parsedData.salesforce.status || ''
		}
		updateForm(formId, parsedData)
		reset()
		setIsSubmitted(true)
	}

	const handleDelete = () => {
		deleteForm(formId)
		navigate('../')
	}

	const handleAddField = () => {
		const fieldPrototype = { id: getNextId(currentForm.fields) }
		setFieldToEdit(fieldPrototype)
	}

	const handleEditField = (field) => {
		setFieldToEdit(field)
	}

	const handleFieldReorder = (payload) => {
		setValue('fields', payload)
	}

	const handleFieldSave = (fieldObj) => {
		const fields = currentForm.fields
		const index = fields.findIndex((o) => o.id === fieldObj.id)

		const newFields = produce(fields, (draft) => {
			if (index !== -1) {
				draft[index] = fieldObj
			} else {
				draft.push({
					name: kebabCase(fieldObj.label.toLowerCase()),
					...fieldObj,
				})
			}
		})

		setValue('fields', newFields, { shouldDirty: true })
	}

	// Hubspot Integration
	useEffect(() => {
		if (!hubspotEnabled) return

		const fieldsToAdd = []
		let fieldId = currentForm.fields.length ? maxBy(currentForm.fields, 'id').id + 1 : 1

		hubspotFields.forEach((field) => {
			if (!currentForm.fields.find((o) => o.name === field.name)) {
				fieldsToAdd.push({
					...field,
					id: fieldId, // Use fieldId without incrementing here
				})
				fieldId += 1 // Increment fieldId separately
			}
		})

		setValue('fields', [...currentForm.fields, ...fieldsToAdd], { shouldDirty: true })

		if (fieldsToAdd.length > 0) {
			toast.info(t('integrations:requiredFieldsAdded', { name: 'Hubspot' }))
		}
	}, [hubspotEnabled, setValue])

	// Linear Integration
	useEffect(() => {
		if (!linearEnabled) return

		const fieldsToAdd = []
		let fieldId = currentForm.fields.length ? maxBy(currentForm.fields, 'id').id + 1 : 1

		linearFields.forEach((field) => {
			if (!currentForm.fields.find((o) => o.name === field.name)) {
				fieldsToAdd.push({
					...field,
					id: fieldId,
				})
				fieldId += 1
			}
		})

		setValue('fields', [...currentForm.fields, ...fieldsToAdd], { shouldDirty: true })

		if (fieldsToAdd.length > 0) {
			toast.info(t('integrations:requiredFieldsAdded', { name: 'Linear' }))
		}
	}, [linearEnabled, setValue])

	// Salesforce Integration
	useEffect(() => {
		if (!salesforceEnabled) return

		const fieldsToAdd = []
		let fieldId = currentForm.fields.length ? maxBy(currentForm.fields, 'id').id + 1 : 1

		salesforceFields.forEach((field) => {
			if (!currentForm.fields.find((o) => o.name === field.name)) {
				fieldsToAdd.push({
					...field,
					id: fieldId, // Use fieldId without incrementing here
				})
				fieldId += 1 // Increment fieldId separately
			}
		})

		setValue('fields', [...currentForm.fields, ...fieldsToAdd], { shouldDirty: true })

		if (fieldsToAdd.length > 0) {
			toast.info(t('integrations:requiredFieldsAdded', { name: 'Salesforce' }))
		}
	}, [salesforceEnabled, setValue])

	// Pipedrive Integration
	useEffect(() => {
		if (pipedriveEnabled && pipedriveTitleValue) {
			setValue(
				'fields',
				produce(currentForm, (draft) => {
					const field = draft.fields.find((o) => o.id === pipedriveTitleValue)
					field.required = true
				}),
				{ shouldDirty: true },
			)
		}
	}, [pipedriveTitleValue, pipedriveEnabled, setValue])

	useEffect(() => {
		if (isSubmitted) {
			navigate('../')
		}
	}, [isSubmitted])

	const handleDuplicate = (e) => {
		e.preventDefault()
		const duplicateSlide = cloneDeep(currentForm)
		duplicateSlide.id = getNextId(actions)
		addAction(duplicateSlide)
		navigate('../')
	}

	return (
		<Panel
			title={t('videobot:editRegularForm')}
			onBack={() => navigate('../')}
			footer={
				<EditFormActions
					onSave={() =>
						formRef.current?.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
					}
					onDelete={() => handleDelete()}
					onDuplicate={handleDuplicate}
					saveDisabled={!isDirty && !currentForm?.isNew}
				/>
			}
		>
			{currentForm && (
				<React.Fragment>
					<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
						{/* Title */}
						<FormField label={t('videobot:formFields.title.label')} required>
							<FieldText
								register={register}
								placeholder={t('videobot:formFields.title.placeholder')}
								name="name"
								error={errors.name}
								required
							/>
						</FormField>
						{/* Description */}
						<FormField label={t('videobot:formFields.description.label')} required>
							<FieldText
								register={register}
								placeholder={t('videobot:formFields.description.placeholder')}
								name="description"
								error={errors.description}
								required
							/>
						</FormField>
						<Row>
							<Col sm={6} xxs={12}>
								{/* Title */}
								<FormField label={t('videobot:formFields.email.label')} required>
									<FieldText
										register={register}
										placeholder={t('videobot:formFields.email.placeholder')}
										name="email"
										type="email"
										error={errors.email}
										required
									/>
								</FormField>
							</Col>
							<Col sm={6} xxs={12}>
								{/* Title */}
								<FormField label={t('videobot:formFields.emailBcc.label')}>
									<FieldText
										register={register}
										placeholder={t('videobot:formFields.emailBcc.placeholder')}
										type="email"
										name="emailBcc"
										error={errors.emailBcc}
									/>
								</FormField>
							</Col>
						</Row>
						{/* Subject */}
						<FormField label={t('videobot:formFields.subject.label')} required>
							<FieldText
								register={register}
								placeholder={t('videobot:formFields.subject.placeholder')}
								name="subject"
								error={errors.subject}
								required
							/>
						</FormField>
						{/* Custom Privacy Policy */}
						<FormGroup
							title={t('videobot:formFields.customPrivacyPolicy.label')}
							description={t('videobot:formFields.customPrivacyPolicy.description')}
							utils={
								<ControlledFieldToggle
									control={control}
									name="customPrivacyPolicy.enabled"
									reversed
								/>
							}
						>
							{currentForm.customPrivacyPolicy?.enabled && (
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('videobot:formFields.customPrivacyPolicy.url')}
										>
											<FieldText
												register={register}
												placeholder={t(
													'videobot:formFields.customPrivacyPolicy.url_placeholder',
												)}
												name="customPrivacyPolicy.url"
												error={errors.customPrivacyPolicy?.url}
												defaultValue={currentForm?.customPrivacyPolicy?.url}
											/>
										</FormField>
									</Col>
								</Row>
							)}
						</FormGroup>
						{/* Hubspot Integration */}
						{accountIntegrations?.find((o) => o.integrationType === 'hubspot') && (
							<FormGroup
								title={t('integrations:hubspotTitle')}
								description={t('integrations:hubspotDescription')}
								hide={!hubspotEnabled}
								utils={
									<ControlledFieldToggle
										control={control}
										name="hubspot.enabled"
										reversed
									/>
								}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('integrations:fields.campaignId.label')}
										>
											<FieldText
												register={register}
												placeholder={t(
													'integrations:fields.campaignId.placeholder',
												)}
												name="hubspot.campaignId"
												error={errors.hubspot?.campaignId}
												defaultValue={currentForm?.hubspot?.campaignId}
											/>
										</FormField>
									</Col>
								</Row>
							</FormGroup>
						)}
						{/* Linear Integration */}
						{accountIntegrations?.find((o) => o.integrationType === 'linear') && (
							<FormGroup
								title={t('integrations:linearTitle')}
								description={t('integrations:linearDescription')}
								hide={!linearEnabled}
								utils={
									<ControlledFieldToggle
										control={control}
										name="linear.enabled"
										reversed
									/>
								}
							></FormGroup>
						)}
						{/* Pipedrive Integration */}
						{accountIntegrations?.find((o) => o.integrationType === 'pipedrive') && (
							<FormGroup
								title={t('integrations:pipedriveTitle')}
								description={t('integrations:pipedriveDescription')}
								hide={!pipedriveEnabled}
								utils={
									<ControlledFieldToggle
										control={control}
										name="pipedrive.enabled"
										reversed
									/>
								}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('integrations:fields.titleValue.label')}
											tooltip={t(
												'integrations:fields.titleValue.description',
											)}
											required={pipedriveEnabled}
										>
											<FieldSelect
												register={register}
												setValue={setValue}
												placeholder={t(
													'integrations:fields.titleValue.placeholder',
												)}
												name="pipedrive.titleValue"
												error={errors.pipedrive?.titleValue}
												defaultValue={
													currentForm.fields?.find(
														(o) =>
															o.id ===
															Number(
																currentForm?.pipedrive?.titleValue,
															),
													)?.id
												}
												options={currentForm.fields?.map((item) => ({
													value: item.id,
													label: item.label,
													item,
												}))}
												required={pipedriveEnabled}
											/>
										</FormField>
									</Col>
									<Col sm={3} xxs={4}>
										<FormField
											label={t('integrations:fields.personId.label')}
											tooltip={t('integrations:fields.personId.description')}
										>
											<FieldText
												register={register}
												placeholder={t(
													'integrations:fields.personId.placeholder',
												)}
												name="pipedrive.personId"
												type="number"
												error={errors.pipedrive?.personId}
												defaultValue={currentForm?.pipedrive?.personId || 0}
											/>
										</FormField>
									</Col>
								</Row>
							</FormGroup>
						)}
						{/* Salesforce Integration */}
						{accountIntegrations?.find((o) => o.integrationType === 'salesforce') && (
							<FormGroup
								title={t('integrations:salesforceTitle')}
								description={t('integrations:salesforceDescription')}
								hide={!salesforceEnabled}
								utils={
									<ControlledFieldToggle
										control={control}
										name="salesforce.enabled"
										reversed
									/>
								}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('integrations:fields.status.label')}
											required={!!currentForm?.salesforce?.enabled}
										>
											<FieldText
												register={register}
												placeholder={t(
													'integrations:fields.status.placeholder',
												)}
												name="salesforce.status"
												error={errors.salesforce?.status}
												defaultValue={currentForm?.salesforce?.status}
												required={!!currentForm?.salesforce?.enabled}
											/>
										</FormField>
									</Col>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('integrations:fields.leadSource.label')}
										>
											<FieldText
												register={register}
												placeholder={t(
													'integrations:fields.leadSource.placeholder',
												)}
												name="salesforce.leadSource"
												error={errors.salesforce?.leadSource}
												defaultValue={currentForm?.salesforce?.leadSource}
											/>
										</FormField>
									</Col>
								</Row>
							</FormGroup>
						)}
					</form>
					{/* Slides List */}
					<ListTable
						dragEnabled
						columns={optionsColumns}
						data={currentForm.fields?.filter((o) => o.type !== 'checkbox')}
						addAction={t('videobot:addField')}
						onAdd={handleAddField}
						onReorder={handleFieldReorder}
						onRowClicked={handleEditField}
					/>
					{/* Edit Option */}
					<AnimatePresence>
						{fieldToEdit && (
							<EditFormFieldPanel
								fieldData={fieldToEdit}
								requiredByIntegration={
									checkPipedriveValidation(fieldToEdit) ||
									checkLinearValidation(fieldToEdit) ||
									checkHubspotValidation(fieldToEdit) ||
									checkSalesforceValidation(fieldToEdit)
								}
								onClose={() => setFieldToEdit(null)}
								onRemove={handleRemoveField}
								onSave={handleFieldSave}
							/>
						)}
					</AnimatePresence>
				</React.Fragment>
			)}
			{showPrompt && (
				<ExitEditorModal
					onSave={() => {
						formRef.current.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
						acceptPrompt()
					}}
					onExit={acceptPrompt}
					onClose={cancelPrompt}
				/>
			)}
		</Panel>
	)
}

export default EditFormPanel
