import styled from 'styled-components'

import media from '../../lib/media'

export const TrialCountdownWrapper = styled.div`
	// Default styles for mobile
	position: fixed; // Makes it stick to the top, below the top bar.
	display: flex; // This ensures that items are displayed in one line for mobile
	align-items: center; // To vertically align the items
	background-color: grey; // Setting default background to grey for mobile

	${media('>lg')} {
		// Existing styles
		width: 15rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 7rem;
		background-color: ${(props) => props.theme.color.body};
		color: ${(props) => props.theme.color.dark};
		border-radius: ${(props) => props.theme.radius.md};
		margin-bottom: 3rem;
		left: -0.8rem;
	}

	${media('<=lg')} {
		top: ${(props) => props.theme.size.topBar.mobile};
		z-index: 100;
		background-color: ${(props) => props.theme.color.light};
		border-radius: ${(props) => props.theme.radius.md};
		color: ${(props) => props.theme.color.dark};
		width: calc(100% - 2rem);
		height: 3rem;
		margin: 1rem;
		flex-direction: row;
		justify-content: flex-start;
		padding-left: 0.5rem;
	}
`

export const TrialCountdownHeading = styled.div`
	${media('>lg')} {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		margin-left: 2.5rem;
		width: 100%;
	}
	${media('<=lg')} {
		display: flex;
		align-items: center;
		margin: 0.5rem;
		margin-left: 0;
	}
`

export const CountdownLabel = styled.span`
	${media('>lg')} {
		font-size: 0.8rem;
		font-weight: bold;
		font-family: ${(props) => props.theme.font.secondary};
		margin-top: 0.3rem;
	}

	${media('<=lg')} {
		font-size: 0.7rem;
		font-size: 1rem;
	}
`

export const CountdownTime = styled.span`
	${media('>lg')} {
		font-size: 1.4rem;
		font-weight: bold;
		font-family: ${(props) => props.theme.font.secondary};
		margin-left: 2.5rem;
		width: 100%;
	}

	${media('<=lg')} {
		font-size: 1rem;
		margin: 0.1rem 0.1rem 0.1rem 0;
		margin-left: 0;
		font-weight: bold;
		font-family: ${(props) => props.theme.font.secondary};
	}
`

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	${media('>lg')} {
		margin-bottom: 0.3rem;
		margin-right: 0.5rem;
	}

	${media('<=lg')} {
		margin-right: 0.5rem;
	}
`
