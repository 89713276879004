import React from 'react'
import { CustomPicker, EditableInput, Hue, Saturation } from '@hello-pangea/color-picker'

import PropTypes from '@/lib/propTypes'

import {
	FieldColorDropdownContent,
	FieldColorInput,
	FieldColorPointer,
	FieldColorPreview,
	FieldColorSaturation,
	FieldColorSlider,
} from './FieldColor.styles'

const FieldColorPickerContents = (props) => {
	const { hex, onChange } = props

	const handleChange = (color, _e) => {
		onChange(color)
	}

	return (
		<React.Fragment>
			<FieldColorSaturation>
				<Saturation
					{...props}
					onChange={handleChange}
					pointer={<FieldColorPointer color={hex} />}
				/>
			</FieldColorSaturation>
			<FieldColorDropdownContent>
				<FieldColorSlider>
					<Hue
						{...props}
						onChange={handleChange}
						pointer={() => <FieldColorPointer color={hex} />}
					/>
				</FieldColorSlider>
				<FieldColorInput>
					<FieldColorPreview color={hex}></FieldColorPreview>
					<EditableInput value={hex} onChange={handleChange} />
				</FieldColorInput>
			</FieldColorDropdownContent>
		</React.Fragment>
	)
}

FieldColorPickerContents.propTypes = {
	hex: PropTypes.string,
	rgb: PropTypes.object,
	color: PropTypes.string,
	setValue: PropTypes.func,
	name: PropTypes.string,
	onChange: PropTypes.func,
}

const FieldColorPicker = CustomPicker(FieldColorPickerContents)

export default FieldColorPicker
