import { Navigate } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { omitBy, isNil } from 'lodash-es'

import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import { useGetAllFeatureFlags, useReadAccounts } from '@/api/videobot'
import { useCurrentUser } from '@/modules/session/auth.store'
import { FlagType } from '@/api/videobot.schemas'
import Button from '@/components/Button'
import { FeatureFlagCreateModal } from '@/features/FeatureFlag/feature_flag_create_modal'

import { FeatureFlagsList } from './feature_flags_list'
import View from '../../layout/View'

const getCleanedParams = (searchParams) => {
	const params = {
		account_id: searchParams.get('accountId'),
		flag_name: searchParams.get('flagName'),
		flag_type: searchParams.get('flagType'),
	}
	return omitBy(params, (value) => isNil(value) || value === '')
}

export const FeatureFlagsView = () => {
	const { t } = useTranslation('feature')
	const [searchParams, setSearchParams] = useSearchParams()
	const cleanedParamsForRequest = getCleanedParams(searchParams)

	const defaultValuesFromURL = {
		account_id: searchParams.get('accountId') || '',
		flag_name: searchParams.get('flagName') || '',
		flag_type: searchParams.get('flagType') || '',
	}

	const { register, handleSubmit, setValue } = useForm({
		defaultValues: defaultValuesFromURL,
	})

	const user = useCurrentUser()

	const { data: allAccountsData } = useReadAccounts({ size: 500 })

	const { data: allFlags, refetch } = useGetAllFeatureFlags(
		{ ...cleanedParamsForRequest },
		{
			query: { staleTime: 1000 * 30 },
		},
	)

	const refetchFlags = () => {
		setTimeout(() => {
			refetch()
		}, 1000)
	}

	const [showCreateModal, setShowCreateModal] = useState(false)

	const handleCreateNewClick = () => {
		setShowCreateModal(true)
	}

	const onFilter = (payload) => {
		const cleanedPayload = omitBy(payload, (value) => isNil(value) || value === '')
		const newSearchParams = new URLSearchParams(cleanedPayload)
		setSearchParams(newSearchParams, { replace: true })
	}

	if (!user.isSuperuser) {
		return <Navigate to="/dashboard" replace />
	}

	const accountSelections =
		allAccountsData?.records.map((account) => ({
			label: account.businessName,
			value: account.id,
		})) || []

	const flagTypeOptions = Object.values(FlagType).map((value) => ({
		label: value,
		value,
	}))

	return (
		<Fragment>
			<View
				header
				title="Feature Flags"
				actions={<Button onClick={handleCreateNewClick}>{t('createNew')}</Button>}
				mobileTopBar
			>
				<form className="mb-2 flex gap-4" onSubmit={handleSubmit(onFilter)}>
					<label className="flex items-center gap-2">
						<span>Flag name</span>
						<FieldText
							register={register}
							placeholder={t('name')}
							name="flagName"
							type="text"
						/>
					</label>
					<label className="flex items-center gap-2">
						<span>Account</span>
						<FieldSelect
							register={register}
							placeholder={t('selectAccountId')}
							name="accountId"
							type="text"
							options={accountSelections}
							setValue={setValue}
						/>
					</label>
					<label className="flex items-center gap-2">
						<span>Flag Type</span>

						<FieldSelect
							register={register}
							placeholder={t('selectFlagType')}
							name="flagType"
							type="text"
							options={flagTypeOptions}
							setValue={setValue}
						/>
					</label>
					<button
						className="rounded bg-primary px-8 text-body transition hover:bg-dark"
						type="submit"
					>
						Filter
					</button>
				</form>
				{allFlags && (
					<FeatureFlagsList featureFlagsList={allFlags} onUpdateFlag={refetchFlags} />
				)}
			</View>
			<AnimatePresence>
				{showCreateModal && (
					<FeatureFlagCreateModal
						onClose={() => setShowCreateModal(false)}
						onFlagCreated={refetchFlags}
					/>
				)}
			</AnimatePresence>
		</Fragment>
	)
}
