import { motion } from 'framer-motion'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAtomValue, useSetAtom } from 'jotai'
import { useAuthenticator } from '@aws-amplify/ui-react'

import Icon from '@/components/Icon'
import {
	isBypassAuth,
	useAuth,
	useCurrentAccount,
	useCurrentUser,
	usePowerUserMode,
} from '@/modules/session/auth.store'
import { useIsTrial } from '@/features/Account/hooks/useAccount'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { isNavigationCollapsedAtom, toggleNavigationCollapsedAtom } from '@/common/layout'
import { FeatureType, RoleStatusType, RoleType } from '@/api/videobot.schemas'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FieldToggle } from '@/components/field_toggle/field_toggle.component'

import Avatar from '../../components/Avatar'
import More, { MoreButton } from '../../components/More'
import TeamSelect from '../../features/Account/components/TeamSelect/TeamSelect'
import {
	NavigationBody,
	NavigationHeader,
	NavigationInner,
	NavigationList,
	NavigationToggler,
	NavigationUser,
	NavigationWrapper,
} from './Navigation.styles'
import NavigationItem from './NavigationItem'
import { TrialCountdown } from '../TrialCountdown'

const Navigation = () => {
	const { t } = useTranslation(['common', 'account'])
	const user = useCurrentUser()
	const { isPowerUser } = usePowerUserMode()
	const activeAccount = useCurrentAccount()
	const { logout } = useAuth()
	const isNavigationCollapsed = useAtomValue(isNavigationCollapsedAtom)
	const toggleNavigationCollapsed = useSetAtom(toggleNavigationCollapsedAtom)
	const inverted = user.isSuperuser
	const [hideNavigationBody, setHideNavigationBody] = useState(false)
	const navigate = useNavigate()
	const isSuperAdmin = user?.isSuperuser
	const isCustomerSuccess = user?.isCustomerSuccess
	const isReseller = user?.isReseller
	const pendingInvites =
		user?.roles?.filter((role) => role.status === RoleStatusType.Invited) || []
	const name = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || user?.email
	const { data: featureFlags } = useFeatureFlags({ account_id: activeAccount?.id })
	const { user: cognitoUser } = useAuthenticator((context) => [context.user])
	const cognitoIdentities =
		cognitoUser?.attributes?.identities && JSON.parse(cognitoUser.attributes?.identities)

	// TODO: Check if user is logged in with any social provider
	const isSocialProvider = Boolean(cognitoIdentities?.find((i) => i.providerType === 'Google'))
	const bypassAuth = isBypassAuth()

	const { isTrial, expireDate } = useIsTrial(activeAccount)

	const USER_MENU = [
		{
			name: 'analytics',
			icon: 'analytics',
			route: '/dashboard/analytics',
			show: [
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Admin,
				RoleType.Member,
			].includes(activeAccount?.role),
		},
		{
			name: 'videobots',
			icon: 'video',
			route: '/dashboard/videobots',
			show: [
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Admin,
				RoleType.Member,
			].includes(activeAccount?.role),
		},
		{
			name: 'widget',
			icon: 'widget',
			route: '/dashboard/widget',
			show:
				[
					RoleType.Super_Admin,
					RoleType.Reseller,
					RoleType.Owner,
					RoleType.Admin,
					RoleType.Member,
				].includes(activeAccount?.role) &&
				featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`),
		},
		{
			name: 'videoLibrary',
			icon: 'video-library',
			route: '/dashboard/video-library',
			show: [
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Admin,
				RoleType.Member,
			].includes(activeAccount?.role),
		},
		{
			name: 'users',
			icon: 'users',
			route: '/dashboard/users',
			show: [
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Admin,
			].includes(activeAccount?.role),
		},
		{
			name: 'settings',
			icon: 'settings',
			route: '/dashboard/settings',
			show: [
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Admin,
			].includes(activeAccount?.role),
		},
		{
			name: 'pendingInvites',
			icon: 'mail',
			route: '/team-select',
			show: pendingInvites.length > 0,
		},

		{
			name: 'accounts',
			icon: 'users',
			route: '/dashboard/accounts',
			show: (isSuperAdmin || isCustomerSuccess || isReseller) && !activeAccount,
		},
		{
			name: 'resellers',
			icon: 'users',
			route: '/dashboard/resellers',
			show: (isSuperAdmin || isCustomerSuccess) && !activeAccount,
		},
	]

	const variants = {
		extended: {
			width: '17rem',
		},
		collapsed: {
			width: '5rem',
		},
	}

	const wrapperProps = useMediaQuery('>lg')
		? {
				as: motion.div,
				transition: { duration: 0.2 },
				initial: isNavigationCollapsed ? 'collapsed' : 'extended',
				animate: isNavigationCollapsed ? 'collapsed' : 'extended',
				variants,
				collapsed: isNavigationCollapsed ? 1 : 0,
			}
		: {}

	const toggleNavigation = () => {
		setHideNavigationBody(true)
		setTimeout(() => {
			toggleNavigationCollapsed()
			setTimeout(() => {
				setHideNavigationBody(false)
			}, 200)
		}, 200)
	}

	const { powerUserMode, setPowerUserMode } = usePowerUserMode()

	const isLargeScreen = useMediaQuery('>lg')

	return (
		<NavigationWrapper {...wrapperProps} inverted={inverted ? 1 : 0}>
			<NavigationInner hide={hideNavigationBody}>
				{/* Team Select */}
				<NavigationHeader>
					<TeamSelect />
				</NavigationHeader>
				{/* Body */}
				<NavigationBody>
					{/* List */}
					<NavigationList>
						{USER_MENU.filter((o) => o.show).map((item) => (
							<NavigationItem
								key={item.name}
								to={item.route}
								icon={item.icon}
								title={t(item.name)}
							/>
						))}
					</NavigationList>
					{isTrial && <TrialCountdown trialEndDate={expireDate} />}
					{/* User */}
					<NavigationUser>
						<Avatar
							src={user?.profilePicture?.url}
							name={user?.firstName}
							color={inverted ? 'rgba(255,255,255,0.1)' : null}
						></Avatar>
						<span>{name}</span>
						<More placement="top-start" inverted={inverted}>
							<Fragment>
								{activeAccount && (
									<MoreButton
										onClick={() => navigate(`/dashboard/users/${user.id}`)}
									>
										{t('account:editProfile')}
									</MoreButton>
								)}
								{!isSocialProvider && !bypassAuth && (
									<MoreButton onClick={() => navigate(`/dashboard/security`)}>
										{t('account:setupMFA')}
									</MoreButton>
								)}
								{!bypassAuth && (
									<MoreButton
										variant="red"
										onClick={() => {
											logout()
											navigate('/login')
										}}
									>
										{t('account:logout')}
									</MoreButton>
								)}
								<hr />
								{isPowerUser && isLargeScreen && (
									<div className="pb-4 pl-2">
										<FieldToggle
											isSelected={powerUserMode}
											onChange={(checked) => setPowerUserMode(checked)}
										>
											Power user
										</FieldToggle>
									</div>
								)}
							</Fragment>
						</More>
					</NavigationUser>
				</NavigationBody>
			</NavigationInner>
			{/* Toggler */}
			<NavigationToggler onClick={() => toggleNavigation()} collapsed={isNavigationCollapsed}>
				<Icon name="chevron-left" />
			</NavigationToggler>
		</NavigationWrapper>
	)
}

export default Navigation
