import { VideobotSettingsForm } from '@/features/Videobot/components/VideobotSettings/VideobotSettingsForm'
import { ViewContent, ViewScroller } from '@/layout/View/View.styles'
import { ViewTransition } from '@/lib/transitions'

import { VideobotSettingsWrapper } from './VideobotSettings.styles'

const VideobotSettings = () => {
	return (
		<VideobotSettingsWrapper {...ViewTransition} showTopBarOnDesktop>
			<ViewScroller hasTabs hasBottomBar>
				<ViewContent>
					<VideobotSettingsForm />
				</ViewContent>
			</ViewScroller>
		</VideobotSettingsWrapper>
	)
}

export default VideobotSettings
