import { AnimatePresence } from 'framer-motion'
import { clamp } from 'lodash-es'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@/components/Button'
import Loader from '@/components/Loader'
import InviteUserModal from '@/features/Users/components/InviteUserModal'
import UsersList from '@/features/Users/components/UsersList'
import Error from '@/layout/Error'
import View from '@/layout/View'
import {
	getReadUsersForAccountByIdQueryKey,
	useInviteUserToAccountById,
	useReadUsersForAccountById,
} from '@/api/videobot'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'

const INITIAL_PARAMS = { page: 1 }

const AllUsersContents = () => {
	const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS)
	const page = Number(searchParams.get('page')) || INITIAL_PARAMS.page
	const size = 10

	const { t } = useTranslation(['errors', 'user'])
	const activeAccount = useCurrentAccount()
	const [showInviteModal, setShowInviteModal] = useState(false)
	const { data, error, isFetching, refetch } = useReadUsersForAccountById(
		activeAccount.id,
		{
			page,
			size,
		},
		{ query: { keepPreviousData: true } },
	)
	const { mutate, isLoading: isInviteLoading } = useInviteUserToAccountById()
	const queryClient = useQueryClient()

	const onPageChange = useCallback(
		(newPage) => {
			if (!data) {
				return
			}
			setSearchParams((prev) => ({
				...prev,
				page: clamp(newPage, 1, data.metadata.pageCount),
			}))
		},
		[setSearchParams, data],
	)

	const inviteUser = (data) => {
		mutate(
			{ accountId: activeAccount.id, data },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(
						getReadUsersForAccountByIdQueryKey(activeAccount.id),
					)
					setShowInviteModal(false)
					toast.success(t('user:invitationHasBeenSent'))
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
				},
			},
		)
	}

	useEffect(() => {
		setSearchParams(INITIAL_PARAMS)
	}, [])

	if (error) {
		return <Error />
	}

	if (data) {
		return (
			<Fragment>
				<View
					header
					title={t('users')}
					actions={
						<Button onClick={() => setShowInviteModal(true)}>
							{t('user:inviteUser')}
						</Button>
					}
					noHeaderBorder
					isLoading={isFetching}
				>
					<UsersList
						users={data.records.filter((o) =>
							o.roles.find((r) => r.accountId === activeAccount.id),
						)}
						onDelete={() => refetch()}
						pageSize={size}
						totalCount={data.metadata.totalCount}
						onPageChange={onPageChange}
						page={data.metadata.page}
						accountId={activeAccount.id}
					/>
				</View>
				<AnimatePresence>
					{showInviteModal && (
						<InviteUserModal
							onSubmit={inviteUser}
							isLoading={isInviteLoading}
							onClose={() => setShowInviteModal(false)}
						/>
					)}
				</AnimatePresence>
			</Fragment>
		)
	}

	return <Loader cover />
}

const AllUsers = () => {
	return (
		<RoleCheck
			roles={[RoleType.Super_Admin, RoleType.Owner, RoleType.Admin, RoleType.Reseller]}
		>
			<AllUsersContents />
		</RoleCheck>
	)
}

export default AllUsers
