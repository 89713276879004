import styled from 'styled-components'

export const CalendarContainer = styled.div`
	border: 1px solid ${(props) => props.theme.color.line};
	width: 250px;
	background-color: ${(props) => props.theme.color.body};
`

export const SelectedDate = styled.div`
	padding: 10px;
	text-align: left;
	color: ${(props) => props.theme.color.dark};
`

export const Divider = styled.div`
	height: 1px;
	background-color: ${(props) => props.theme.color.line};
	margin: 5px 0;
`

export const CalendarNavigation = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => props.theme.color.body};
	padding: 5px 10px;

	button {
		background-color: transparent;
		color: ${(props) => props.theme.color.line};
		border: none;
		font-size: 10px;
	}

	span {
		color: ${(props) => props.theme.color.line};
	}
`

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
`

export const Day = styled.div`
	padding: 5px;
	text-align: center;
	font-weight: normal;
	font-size: 14px;
	color: ${(props) => props.theme.color.line};
`

export const DateBox = styled.div`
	padding: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.2s;
	background-color: ${(props) =>
		props.isHighlighted ? props.theme.color.primary : 'transparent'};
	color: ${(props) =>
		props.isHighlighted ? 'white' : props.isCurrentMonth ? 'black' : props.theme.color.line};
`

export const Button = styled.button`
	display: block;
	padding: 10px 15px;
	background-color: ${(props) => props.theme.color.primary};
	color: white;
	border: none;
	border-radius: 5px;
	margin: 10px;
	margin-left: auto; /* Aligns the button to the right side */
	cursor: pointer;

	&:hover {
		background-color: ${(props) =>
			props.theme.color.dark}; /* Adjusted to use the theme's dark color */
	}
`
