import styled, { css } from 'styled-components'

import {
	Font,
	LinkUnderline,
	LinkUnderlineOnHover,
	Reset,
	TransitionPrimary,
} from '../../lib/mixins'
import { LoaderObject } from '../Loader/Loader.styles'

export const ButtonLoader = styled.div`
	position: absolute;
	left: calc(50% - 13px);
	height: calc(50% - 13px);
	width: 26px;
	height: 26px;
	z-index: 2;

	${LoaderObject} {
		width: 100%;
		height: 100%;
		stroke: currentColor;
	}
`
export const ButtonLabel = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	${TransitionPrimary('opacity')}
	padding-top: 0.05em;
	padding-left: 0.15em;

	svg {
		fill: currentColor;
		display: block;
		margin-right: 0.5em;
	}
`

export const ButtonWrapper = styled.button.attrs((props) => ({
	...(props.type && { type: props.type }),
}))`
	${Reset('button')}
	${Font('1rem', 'medium')}
  position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	${TransitionPrimary()}
	border-radius: ${(props) => props.theme.radius.md};
	border: 1px solid transparent;
	font-weight: 600;
	font-family: ${(props) => props.theme.font.secondary};

	${(props) =>
		props.wide &&
		css`
			min-width: 7rem;
		`};

	${(props) =>
		props.hidden &&
		css`
			visibility: hidden;
			opacity: 0;
			pointer-events: none;
		`};

	${(props) =>
		props.rounded &&
		css`
			border-radius: 2rem;
		`};

	${(props) =>
		props.size === 'default' &&
		css`
			height: ${(props) => props.theme.control.md};
			padding: 0 1rem;
		`};

	${(props) =>
		props.size === 'small' &&
		css`
			height: 2.25rem;
			padding: 0 0.75rem;
			font-size: 0.95rem;
		`};

	${(props) =>
		props.size === 'large' &&
		css`
			height: 3.1rem;
			padding: 0 1.25rem;
			font-size: 1rem;
		`};

	${(props) =>
		props.rounded &&
		css`
			border-radius: 2rem;
		`};

	${(props) =>
		props.mobileBlock &&
		css`
			@media ${(props) => props.theme.mq.max.xs} {
				display: flex;
				width: 100%;
			}
		`};

	${(props) =>
		props.block &&
		css`
			display: flex;
			width: 100%;
		`};

	${(props) =>
		!props.block &&
		css`
			& + & {
				margin-left: 0.5rem;
			}
		`};

	${(props) =>
		props.variant === 'primary' &&
		css`
			color: ${(props) => props.theme.color.inverted};
			background-color: ${(props) => props.theme.color.primary};
			border-color: ${(props) => props.theme.color.primary};

			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						background-color: ${(props) => props.theme.color.global};
						border-color: ${(props) => props.theme.color.global};
						color: ${(props) => props.theme.color.inverted};
					}
				`}

			${(props) =>
				props.disabled &&
				css`
					background-color: ${(props) => props.theme.color.line};
					color: ${(props) => props.theme.color.global};
					border-color: ${(props) => props.theme.color.line};
					&:hover {
						background-color: ${(props) => props.theme.color.line};
						color: ${(props) => props.theme.color.global};
						border-color: ${(props) => props.theme.color.line};
					}
				`}
		`};

	${(props) =>
		props.variant === 'secondary' &&
		css`
			border-color: ${(props) => props.theme.color.line};
			color: ${(props) => props.theme.color.global};

			${(props) =>
				props.inverted &&
				css`
					border-color: ${(props) => props.theme.color.body};
					background-color: transparent;
					color: currentColor;
				`}

			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						background-color: ${(props) => props.theme.color.global};
						border-color: ${(props) => props.theme.color.global};
						color: ${(props) => props.theme.color.inverted};

						${(props) =>
							props.inverted &&
							css`
								border-color: ${(props) => props.theme.color.body};
								background-color: ${(props) => props.theme.color.body};
								color: ${(props) => props.theme.color.primary};
							`}
					}
				`}
		`};

	${(props) =>
		props.variant === 'red' &&
		css`
			color: ${(props) => props.theme.color.red};
			background-color: transparent;
			border-color: ${(props) => props.theme.color.line};
			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						background-color: ${(props) => props.theme.color.red};
						border-color: ${(props) => props.theme.color.red};
						color: ${(props) => props.theme.color.inverted};
					}
				`}
		`};

	${(props) =>
		props.variant === 'red-solid' &&
		css`
			color: ${(props) => props.theme.color.inverted};
			background-color: ${(props) => props.theme.color.red};
			border-color: transparent;
			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						background-color: ${(props) => props.theme.color.global};
						color: ${(props) => props.theme.color.inverted};
					}
				`}
		`};

	${(props) =>
		props.variant === 'link' &&
		css`
			${LinkUnderlineOnHover()}
			font-weight: ${(props) => props.theme.font.primary.regular};
			color: ${(props) => props.theme.color.primary};
			padding: 0;
			height: auto;

			${LoaderObject} {
				stroke: ${(props) => props.theme.color.primary};
			}

			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						color: ${(props) => props.theme.color.primary};
					}
				`}
		`};

	${(props) =>
		props.variant === 'underline' &&
		css`
			${LinkUnderline()}
			font-weight: ${(props) => props.theme.font.primary.medium};
			color: inherit;
			padding: 0;
			height: auto;
			${(props) =>
				!props.disabled &&
				css`
					&:hover {
						color: inherit;
					}
				`}
		`};

	${(props) =>
		props.variant === 'icon' &&
		css`
			width: 2.4rem;
			height: 2.4rem;
			margin-left: -0.25rem;
			border-radius: ${(props) => props.theme.radius.sm};
			background-color: ${(props) => props.theme.color.light};
			${TransitionPrimary('background')}

			&:hover {
				background-color: ${(props) => props.theme.color.line};
			}

			svg {
				width: 0.8rem;
				height: 0.8rem;
				margin: 0;
			}

			${ButtonLabel} {
				padding: 0;
			}
		`};

	${(props) =>
		props.iconOnly &&
		css`
			margin-left: -0.25rem;

			svg {
				margin: 0;
			}

			${ButtonLabel} {
				padding: 0;
			}
		`};

	${(props) =>
		props.variant === 'text-icon' &&
		css`
			border-radius: ${(props) => props.theme.radius.sm};
			background-color: ${(props) => props.theme.color.light};
			${TransitionPrimary('background')}

			&:hover {
				background-color: ${(props) => props.theme.color.line};
			}

			svg {
				width: 0.8rem;
				height: 0.8rem;
			}
		`};

	${(props) =>
		props.isLoading &&
		css`
			pointer-events: none;
			${ButtonLabel} {
				opacity: 0;
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`
