import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useParams } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { defaults, identity, pickBy } from 'lodash-es'

import Loader from '@/components/Loader'
import ExitEditorModal from '@/features/Videobot/components/ExitEditorModal'
import VideobotPreviewPanel from '@/features/Videobot/components/VideobotPreviewPanel'
import { ShareVideobotModal } from '@/features/Videobot/components/ShareVideobot/ShareVideobotModal'
import { useShareModal, ShareModalProvider } from '@/features/Videobot/hooks/useShareModal'
import usePrompt from '@/hooks/usePrompt'
import { useWindowSize } from '@/hooks/useWindowSize'
import Error from '@/layout/Error'
import { breakpoints } from '@/lib/theme'
import { BotStatusType, BotWidgetAlign, LanguageType, RoleType } from '@/api/videobot.schemas'
import { RoleCheck } from '@/modules/session/auth.component'
import {
	getReadAllTagsQueryKey,
	getReadBotByIdQueryKey,
	getReadBotWidgetByIdQueryKey,
	useReadAccountById,
	useReadAllTags,
	useReadBotById,
	useReadBotWidgetById,
	useUpdateBotById,
	useUpdateBotWidgetById,
} from '@/api/videobot'
import { parseCss, unescapeLink, urlToPathname } from '@/lib/utils'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import Alert from '@/components/Alert'
import Button from '@/components/Button'
import View from '@/layout/View'

const SingleVideobotContents = () => {
	const { t } = useTranslation(['videobot'])

	const { id: idParam } = useParams()
	const formRef = useRef()
	const location = useLocation()
	const { search: urlQuery } = location
	const { windowWidth } = useWindowSize()
	const [prevRoute, setPrevRoute] = useState(`/dashboard/videobots${urlQuery}`)
	const [showPreview, setShowPreview] = useState(false)
	const context = useMemo(() => ({ formRef }), [])
	const queryClient = useQueryClient()
	const user = useCurrentUser()
	const account = useCurrentAccount()
	const { isModalOpen, openModal, modalProps } = useShareModal()

	const id = Number(idParam)

	const { data: videobotSettings, isError: isVideobotError } = useReadBotById(id)
	const { data: videobotEmbed, isError: isVideobotEmbedError } = useReadBotWidgetById(id)
	const { data: accountData, isError: isAccountError } = useReadAccountById(
		videobotSettings?.accountId,
	)
	const { data: tags, isError: isTagsError } = useReadAllTags(
		{ account_id: videobotSettings?.accountId },
		{ query: { enabled: Boolean(videobotSettings?.accountId) } },
	)

	const { mutateAsync: updateVideobot } = useUpdateBotById()
	const { mutateAsync: updateVideobotWidget } = useUpdateBotWidgetById()

	const isLoading = !videobotSettings || !videobotEmbed || !accountData || !tags
	const isError = isVideobotError || isVideobotEmbedError || isAccountError || isTagsError

	const formValues = useMemo(() => {
		const urlMapsArray = videobotSettings?.urlMaps
			? Object.entries(videobotSettings?.urlMaps).map(([url, botId]) => ({ url, botId }))
			: []

		const transformedActions = []
		const actions = videobotEmbed?.actions || []

		for (const action of actions) {
			const cloneAction = { ...action }
			if (action.type === 'EXTERNAL_LINK') {
				cloneAction.url = unescapeLink(cloneAction.url)
			}
			transformedActions.push(cloneAction)
		}

		return {
			...videobotSettings,
			...videobotEmbed,
			actions: transformedActions,
			urlMapsList: urlMapsArray,
			widgetSettings: defaults(
				pickBy(
					{
						...videobotSettings?.widgetSettings,
						chatVideoId:
							videobotSettings?.widgetSettings?.chatVideoId ||
							accountData?.chatVideo?.id,
						chatVideo:
							videobotSettings?.widgetSettings?.chatVideo || accountData?.chatVideo,
						borderColor:
							videobotSettings?.widgetSettings?.borderColor ||
							videobotSettings?.theme?.backgroundColor ||
							videobotEmbed?.theme?.backgroundColor ||
							accountData?.buttonBackgroundColor ||
							'#351DDA',
						loopLimit: videobotSettings?.widgetSettings?.loopLimit || 15,
						verticalOffset: videobotSettings?.widgetSettings?.verticalOffset || 0,
						horizontalOffset: videobotSettings?.widgetSettings?.horizontalOffset || 0,
					},
					identity,
				),
				{
					coverOffset: 0,
					height: 100,
					width: 100,
					automaticallyOpen: false,
					openAfter: 4000,
					align: BotWidgetAlign.bottom_right,
					verticalOffset: 0,
					horizontalOffset: 0,
					loopLimit: 15,
					language: LanguageType.en,
					text: 'See how it works!',
					horizontalMode: false,
				},
			),
			iframeSettings: defaults(
				pickBy(
					{
						...videobotSettings?.iframeSettings,
						height: videobotSettings?.iframeSettings?.height,
						width: videobotSettings?.iframeSettings?.width,
					},
					identity,
				),
				{
					height: 650,
					width: 340,
				},
			),
			metaTrackingSettings: {
				...videobotSettings?.metaTrackingSettings,
				enabled: Boolean(
					videobotSettings?.metaTrackingSettings?.enabled &&
						videobotSettings?.metaTrackingSettings?.pixelId,
				),
			},
		}
	}, [videobotSettings, accountData, videobotEmbed])

	const form = useForm({
		defaultValues: formValues,
		values: formValues,
	})

	const watchStatus = form.watch('status')
	const watchName = form.watch('name')

	const adminRoles = [RoleType.Admin, RoleType.Owner, RoleType.Super_Admin]
	const hasEditPermission =
		videobotSettings &&
		(user.isCustomerSuccess ||
			user.isSuperuser ||
			adminRoles.includes(account.role) ||
			videobotSettings.author.id === user.id)

	const isShowDisabled =
		watchStatus !== BotStatusType.Published || form.formState.isSubmitting || !modalProps

	const isPublishDisabled =
		watchStatus !== BotStatusType.Draft || form.formState.isSubmitting || !hasEditPermission

	const isSaveDisabled =
		watchStatus === BotStatusType.Deactivated ||
		form.formState.isSubmitting ||
		!form.formState.isDirty ||
		!hasEditPermission

	const { showPrompt, acceptPrompt, cancelPrompt } = usePrompt({
		message: t('leavePageQuestion'),
		when: !isSaveDisabled,
		condition: (url) => url.match(/.*videobots$/g),
	})

	const handleFormSubmit = form.handleSubmit(async (payload) => {
		// TODO: map these correctly in form fields instead
		const theme = {
			...payload.theme,
			styles: payload.theme?.stylesRaw ? await parseCss(payload.theme.stylesRaw) : null,
		}
		const tagsList = payload.tagsList
			.map((tagObject) => tags.records.find((tag) => tag.name === tagObject.name))
			.filter(Boolean)
		const urlMaps = payload.urlMapsList
			?.filter((urlMap) => Boolean(urlMap.url && urlMap.botId))
			.reduce(
				(acc, item) => ({
					...acc,
					[urlToPathname(item.url)]: `${item.botId}`,
				}),
				{},
			)

		const data = {
			...payload,
			theme,
			tagsList,
			urlMaps,
		}

		try {
			await updateVideobot({ botId: id, data })
			await updateVideobotWidget({ botId: id, data })
			toast.success(t('settingsPage.saveSuccess'))
		} catch {
			toast.error(t('settingsPage.saveFailure'))
			return
		}

		await Promise.all([
			queryClient.invalidateQueries(getReadBotByIdQueryKey(id)),
			queryClient.invalidateQueries(getReadBotWidgetByIdQueryKey(id)),
			queryClient.invalidateQueries(
				getReadAllTagsQueryKey({ account_id: videobotSettings?.accountId }),
			),
		])

		// TODO: Find a way to sync form state with data from API
		// TODO: Maybe find a way to async reset so embed code only displayed after reset done
		form.reset(form.getValues())
	})

	// FIXME: this is a workaround for content editor page won't submit form on each button click
	const handleContentFormSubmit = (event) => {
		event.preventDefault()
	}

	const handleTitleChange = (e) => {
		form.setValue('name', e, { shouldValidate: true, shouldDirty: true })
	}

	const handlePublish = async () => {
		if (watchStatus === BotStatusType.Draft) {
			form.setValue('status', BotStatusType.Published)
		}
		await handleFormSubmit()
	}

	const ActionsBar = (
		<React.Fragment>
			<Button
				onClick={() => setShowPreview(true)}
				disabled={form.formState.isSubmitting}
				variant="secondary"
				iconPrepend="play"
			>
				{t('preview')}
			</Button>
			{location.pathname.includes('/share') && windowWidth <= breakpoints.lg && (
				<Button
					onClick={openModal}
					disabled={isShowDisabled}
					variant="primary"
					iconPrepend="code"
				>
					{location.pathname.match(/share\/link$/g)
						? t('sharePage.showLink')
						: t('sharePage.showCode')}
				</Button>
			)}
			<Button
				onClick={handleFormSubmit}
				disabled={isSaveDisabled}
				isLoading={form.formState.isSubmitting}
				variant="primary"
				iconPrepend="save"
			>
				{t('save')}
			</Button>
			{watchStatus === BotStatusType.Draft && (
				<Button
					onClick={handlePublish}
					disabled={isPublishDisabled}
					variant="primary"
					iconPrepend="publish"
				>
					Publish
				</Button>
			)}
		</React.Fragment>
	)

	useEffect(() => {
		setPrevRoute(location.state?.prev || `/dashboard/videobots${urlQuery}`)
	}, [urlQuery, location.state])

	return (
		<React.Fragment>
			{!isLoading && (
				<View
					title={watchName}
					shortTitle={watchName}
					onTitleChange={handleTitleChange}
					topBarActions={windowWidth > breakpoints.lg && ActionsBar}
					bottomBarActions={windowWidth <= breakpoints.lg && ActionsBar}
					showTopBar
					showTopBarOnDesktop
					hideHeaderActionsOnMobile
					cover
					isRouterView
					backRoute={prevRoute}
					tabs={[
						{
							path: `/dashboard/videobots/${id}/content`,
							name: t('videobot:content'),
						},
						{
							path: `/dashboard/videobots/${id}/settings`,
							name: t('videobot:settings'),
						},
						{
							path: `/dashboard/videobots/${id}/share`,
							name: t('videobot:shareAndEmbed'),
						},
					]}
				>
					<FormProvider {...form}>
						<form
							onSubmit={
								location.pathname.includes('/content')
									? handleContentFormSubmit
									: handleFormSubmit
							}
							className="flex h-full w-full flex-col"
						>
							{!hasEditPermission && (
								/* TODO: Add translation here */
								<Alert variant="red" noMargin>
									You lack the necessary permissions to edit this Videobot.
									Editing privileges are restricted to account owners, admins, or
									this Videobot&#39;s author.
								</Alert>
							)}
							<div className="flex h-full w-full">
								<Outlet context={context} />
							</div>
						</form>
					</FormProvider>
					<AnimatePresence>
						{showPreview && (
							<VideobotPreviewPanel
								title={`${t('preview')}: ${videobotSettings.name}`}
								onClose={() => setShowPreview(false)}
								videobot={form.getValues()}
							/>
						)}
					</AnimatePresence>
				</View>
			)}
			{isModalOpen && modalProps && (
				<ShareVideobotModal {...modalProps} isDirty={form.formState.isDirty} />
			)}
			{showPrompt && (
				<ExitEditorModal
					onSave={async () => {
						await handleFormSubmit()
						acceptPrompt()
					}}
					onExit={acceptPrompt}
					onClose={cancelPrompt}
				/>
			)}
			{isLoading && <Loader cover />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}

const SingleVideobot = () => {
	return (
		<ShareModalProvider>
			<RoleCheck
				roles={[
					RoleType.Admin,
					RoleType.Member,
					RoleType.Owner,
					RoleType.Reseller,
					RoleType.Super_Admin,
				]}
			>
				<SingleVideobotContents />
			</RoleCheck>
		</ShareModalProvider>
	)
}

export default SingleVideobot
