import PropTypes from '@/lib/propTypes'

import Icon from '../Icon'
import { AlertContent, AlertIcon, AlertWrapper } from './Alert.styles'

const Alert = (props) => {
	const { children, variant = 'global', icon, center, noMargin } = props

	return (
		<AlertWrapper variant={variant} center={center} noMargin={noMargin}>
			{icon && (
				<AlertIcon>
					<Icon name={icon} />
				</AlertIcon>
			)}
			<AlertContent>{children}</AlertContent>
		</AlertWrapper>
	)
}

Alert.propTypes = {
	children: PropTypes.node,
	center: PropTypes.bool,
	icon: PropTypes.string,
	variant: PropTypes.oneOf(['global', 'red', 'green', 'transparent']),
}

export default Alert
