import { AnimatePresence } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router'
import useLocalStorageState from 'use-local-storage-state'

import Icon from '@/components/Icon'
import Loader from '@/components/Loader'
import { ViewScroller } from '@/layout/View/View.styles'
import { ViewTransition } from '@/lib/transitions'
import { EditPostMessagePanel } from '@/features/Videobot/components/ContentEditor/edit_post_message.panel'
import { EditCloseVideobotPanel } from '@/features/Videobot/components/ContentEditor/edit_close_videobot.panel'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'

import {
	ContentEditorBodyInner,
	ContentEditorSwitcher,
	ContentEditorSwitcherButton,
	ContentEditorWrapper,
} from './ContentEditor.styles'
import ContentEditorActions from './ContentEditorActions'
import ContentEditorSlides from './ContentEditorSlides'
import EditExternaLink from './EditExternalLinkPanel'
import EditFormPanel from './EditFormPanel'
import EditSlidePanel from './EditSlidePanel'

const ContentSwitcher = ({ activeMode, onChange }) => {
	const OPTS = [
		{
			name: 'GRID',
			icon: 'grid',
		},
		{
			name: 'LIST',
			icon: 'list',
		},
	]
	return (
		<ContentEditorSwitcher>
			{OPTS.map((o) => (
				<ContentEditorSwitcherButton
					key={o.name}
					active={activeMode === o.name}
					onClick={() => onChange(o.name)}
					role="button"
				>
					<Icon name={o.icon} />
				</ContentEditorSwitcherButton>
			))}
		</ContentEditorSwitcher>
	)
}

const ContentEditor = () => {
	const [activeDisplayMode, setActiveDisplayMode] = useLocalStorageState('contentEditorMode', {
		defaultValue: 'GRID',
	})
	const { formState } = useVideobotEditor()
	const location = useLocation()
	const locationArr = location.pathname?.split('/') ?? []

	return (
		<ContentEditorWrapper {...ViewTransition} mode={activeDisplayMode}>
			<ViewScroller hasBottomBar hasTabs>
				<ContentEditorBodyInner>
					{/* Switcher */}
					<ContentSwitcher
						activeMode={activeDisplayMode}
						onChange={(mode) => setActiveDisplayMode(mode)}
					/>
					{/* Slides */}
					<ContentEditorSlides mode={activeDisplayMode} />
					{/* Actions */}
					<ContentEditorActions mode={activeDisplayMode} />
				</ContentEditorBodyInner>
			</ViewScroller>
			{/* Outlet */}
			<AnimatePresence mode="wait">
				<Routes location={location} key={locationArr[5]}>
					<Route path="/slide/:slideId" element={<EditSlidePanel />}></Route>
					<Route path="/form/:formId" element={<EditFormPanel />}></Route>
					<Route path="/link/:linkId" element={<EditExternaLink />}></Route>
					<Route path="/action/:actionId" element={<EditPostMessagePanel />}></Route>
					<Route
						path="/close-action/:actionId"
						element={<EditCloseVideobotPanel />}
					></Route>
				</Routes>
			</AnimatePresence>
			{formState.isSubmitting && <Loader />}
		</ContentEditorWrapper>
	)
}

export default ContentEditor
