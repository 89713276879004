import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'

import { CFAnalyticsView } from '@/pages/cf_analytics/cf_analytics.view'
import { LegacyToolkitView } from '@/pages/legacy/legacy_toolkit.view'
import { VideobotPreview } from '@/pages/Videobot/VideobotPreview'
import { FeatureFlagsView } from '@/pages/admin/feature_flags.view'
import { useAuth } from '@/modules/session/auth.store'
import TeamSelect from '@/features/Auth/components/TeamSelect'
import { WidgetListView } from '@/features/widget/widget_list.view'
import { EditWidgetView } from '@/features/widget/edit_widget.view'
import { WidgetBase } from '@/features/widget/widget_base.view'
import { OauthCheckView } from '@/modules/session/oauth_check.view'
import { NewPasswordView } from '@/pages/Auth/new_password.view'
import { SecurityView } from '@/modules/security/security.view'
import { InvalidUserView } from '@/modules/security/invalid_user.view'
import { ResellerView } from '@/pages/reseller/reseller.page'

import { DashboardDefaultRoute, AuthDashboard } from './Dashboard'
import { ShareVideobotIframe } from './features/Videobot/components/ShareVideobot/ShareVideobotIframe'
import { ShareVideobotDisplayAds } from './features/Videobot/components/ShareVideobot/ShareVideobotDisplayAds'
import { ShareVideobotLink } from './features/Videobot/components/ShareVideobot/ShareVideobotLink'
import { ShareVideobotWidget } from './features/Videobot/components/ShareVideobot/ShareVideobotWidget'
import AccountSettings from './pages/Account/AccountSettings'
import { AllAccountsView } from './pages/Account/AllAccounts'
import CreateAccount from './pages/Account/CreateAccount'
import { SingleAccount } from './pages/Account/SingleAccount'
import { AnalyticsPage } from './features/analytics/analytics.view'
import { AnalyticsReportPage } from './features/analytics/analytics_report.view'
import ForgotPassword from './pages/Auth/ForgotPassword'
import JoinTeam from './pages/Auth/JoinTeam'
import { Login } from './pages/Auth/Login'
// import { SignupFreeTrial } from './pages/Auth/SignupFreeTrial'
import { Error404 } from './pages/Errors/Error404'
import { Error500 } from './pages/Errors/Error500'
import MediaLibrary from './pages/MediaLibrary/MediaLibrary'
import AllUsers from './pages/Users/AllUsers'
import SingleUser from './pages/Users/SingleUser'
import AllVideobots from './pages/Videobot/AllVideobots'
import SingleVideobot from './pages/Videobot/SingleVideobot'
import VideobotConfiguration from './pages/Videobot/VideobotConfiguration'
import VideobotContent from './pages/Videobot/VideobotContent'
import VideobotShare from './pages/Videobot/VideobotShare'
import { VideobotWidgetPreview } from './pages/Videobot/VideobotWidgetPreview'
import AccountSettingsProfile from './pages/Account/AccountSettingsProfile'
import AccountSettingsAccount from './pages/Account/AccountSettingsAccount'
import AccountSettingsIntegrations from './pages/Account/AccountSettingsIntegrations'

const RootView = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { isAuthenticated } = useAuth()

	useEffect(() => {
		navigate(isAuthenticated ? '/dashboard' : '/login', { replace: true })
	}, [location, navigate, isAuthenticated])

	return null
}

const App = () => {
	return (
		<AnimatePresence mode="wait">
			<Routes>
				{/* Root */}
				<Route path="/" element={<RootView />}></Route>

				{/* Auth */}
				<Route path="login" element={<Login />}></Route>
				<Route path="invalid-user" element={<InvalidUserView />}></Route>
				{/* <Route path="signup" element={<Signup />}></Route> */}
				{/* <Route path="free-trial" element={<SignupFreeTrial />}></Route> */}
				<Route path="join-team" element={<JoinTeam />}></Route>
				<Route path="reset-password" element={<ForgotPassword />}></Route>
				<Route path="new-password" element={<NewPasswordView />}></Route>
				<Route path="team-select" element={<TeamSelect />}></Route>
				<Route path="oauth-check" element={<OauthCheckView />}></Route>

				{/* Dashboard */}
				<Route path="dashboard" element={<AuthDashboard />}>
					{/* Default */}
					<Route path="" element={<DashboardDefaultRoute />}></Route>

					{/* Users */}
					<Route path="users" element={<AllUsers />}></Route>
					<Route path="users/:id" element={<SingleUser />}></Route>

					{/* Security */}
					<Route path="security" element={<SecurityView />}></Route>

					{/* Account */}
					<Route path="settings" element={<AccountSettings />}>
						<Route path=":action" element={<AccountSettings />} />
						<Route path="" element={<Navigate replace to="profile" />}></Route>
						<Route path="profile" element={<AccountSettingsProfile />} />
						<Route path="account" element={<AccountSettingsAccount />} />
						<Route path="integrations" element={<AccountSettingsIntegrations />} />
					</Route>

					<Route path="accounts" element={<AllAccountsView />}></Route>
					<Route path="accounts/create" element={<CreateAccount />}></Route>
					<Route path="accounts/:id" element={<SingleAccount />}></Route>

					<Route path="resellers" element={<ResellerView />}></Route>

					{/* Media Library */}
					<Route path="video-library" element={<MediaLibrary />}></Route>

					{/* Videobots */}
					<Route path="videobots" element={<AllVideobots />}></Route>

					{/* Analytics */}
					<Route path="analytics" element={<AnalyticsPage />}></Route>

					{/* Superadmin */}
					<Route path="admin">
						<Route path="feature-flags" element={<FeatureFlagsView />} />
					</Route>

					{/* Single Videobot */}
					<Route path="videobots/:id" element={<SingleVideobot />}>
						<Route path="" element={<Navigate replace to="content" />}></Route>
						<Route path="content/*" element={<VideobotContent />}></Route>
						<Route path="settings" element={<VideobotConfiguration />}></Route>
						<Route path="share" element={<VideobotShare />}>
							<Route path="" element={<ShareVideobotWidget />} />
							<Route path="iframe" element={<ShareVideobotIframe />} />
							<Route path="link" element={<ShareVideobotLink />} />
							<Route path="display-ads" element={<ShareVideobotDisplayAds />} />
						</Route>
					</Route>

					<Route path="widget" element={<WidgetBase />}>
						<Route path="" element={<WidgetListView />}></Route>
						<Route path=":id" element={<EditWidgetView />}></Route>
					</Route>

					{/* CF Analytics */}
					<Route path="cf-analytics" element={<CFAnalyticsView />} />

					{/* CF Analytics */}
					<Route path="legacy" element={<LegacyToolkitView />} />
				</Route>

				{/* Previews */}
				<Route path="widget-preview/:id" element={<VideobotWidgetPreview />} />
				<Route path="preview/:id" element={<VideobotPreview />} />

				{/* Analytics Report */}
				<Route path="analytics-report/:channel" element={<AnalyticsReportPage />}></Route>

				{/* Errors */}
				<Route path="500" element={<Error500 />}></Route>
				<Route path="*" element={<Error404 />}></Route>
			</Routes>
		</AnimatePresence>
	)
}

export default App
