import { AnimatePresence, motion } from 'framer-motion'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router'

import PropTypes from '@/lib/propTypes'

import Icon from '../Icon'
import Loader from '../Loader'
import { ButtonLabel, ButtonLoader, ButtonWrapper } from './Button.styles'

const Button = forwardRef((props, _ref) => {
	const {
		children,
		variant = 'primary',
		size = 'default',
		onClick,
		isLoading,
		mobileBlock,
		block,
		to,
		disabled,
		hidden,
		inverted,
		wide,
		rounded,
		iconPrepend,
		externalTo,
		type,
		iconOnly,
	} = props

	const navigate = useNavigate()

	const handleClick = (e) => {
		if (externalTo) {
			e.preventDefault()
			window.location.href = externalTo
		}
		if (to) navigate(to)
		if (onClick) onClick(e)
	}

	return (
		<ButtonWrapper
			variant={variant}
			size={size}
			onClick={handleClick}
			isLoading={isLoading}
			mobileBlock={mobileBlock}
			block={block}
			disabled={disabled}
			inverted={inverted}
			rounded={rounded}
			hidden={hidden}
			wide={wide}
			type={type}
			iconOnly={iconOnly}
		>
			<ButtonLabel>
				{iconPrepend && <Icon name={iconPrepend} />}
				{children}
			</ButtonLabel>
			<AnimatePresence>
				{isLoading && (
					<ButtonLoader
						as={motion.div}
						initial={{ scale: 1.1, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						exit={{ scale: 0.9, opacity: 0 }}
					>
						<Loader size={26} />
					</ButtonLoader>
				)}
			</AnimatePresence>
		</ButtonWrapper>
	)
})

Button.displayName = 'Button'

Button.propTypes = {
	children: PropTypes.node,
	variant: PropTypes.oneOf([
		'primary',
		'secondary',
		'red-solid',
		'red',
		'link',
		'icon',
		'text-icon',
	]),
	size: PropTypes.oneOf(['large', 'default', 'small']),
	onClick: PropTypes.func,
	isLoading: PropTypes.bool,
	mobileBlock: PropTypes.bool,
	block: PropTypes.bool,
	to: PropTypes.string,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	inverted: PropTypes.bool,
	wide: PropTypes.bool,
	rounded: PropTypes.bool,
	iconPrepend: PropTypes.string,
	externalTo: PropTypes.string,
	type: PropTypes.string,
}

export default Button
