import { useState, useEffect } from 'react'

import { Calendar } from '../Calendar'
import {
	DatePickerWrapper,
	DateInput,
	CalendarIconWrapper,
	InputContainer,
	CalendarContainer,
} from './DatePicker.styles'
import Icon from '../../components/Icon'

export const DatePicker = (props) => {
	const { onChange, expiredAt, placeholder = 'dd/mm/yy' } = props

	const [isOpen, setIsOpen] = useState(false)
	const [selectedDate, setSelectedDate] = useState(null)

	useEffect(() => {
		const initialDate = expiredAt ? new Date(expiredAt) : null
		setSelectedDate(initialDate)
	}, [expiredAt])

	const formatDate = (date) => {
		return date
			? date.toLocaleDateString('default', {
					day: '2-digit',
					month: '2-digit',
					year: '2-digit',
				})
			: ''
	}

	const handleDateChange = (date) => {
		setSelectedDate(date)
		setIsOpen(false)
		if (onChange) onChange(date)
	}

	return (
		<DatePickerWrapper>
			<InputContainer>
				<DateInput
					readOnly
					hasValue={!!selectedDate}
					value={formatDate(selectedDate) || placeholder}
					onClick={() => setIsOpen(!isOpen)}
					isOpen={isOpen}
				/>
				<CalendarIconWrapper>
					<Icon
						name="calendar"
						size="small"
						color="transparent"
						variant="square"
						inverted
					/>
				</CalendarIconWrapper>
			</InputContainer>

			{isOpen && (
				<CalendarContainer>
					<Calendar expiredAt={selectedDate} onDateSelect={handleDateChange} />
				</CalendarContainer>
			)}
		</DatePickerWrapper>
	)
}

DatePicker.displayName = 'DatePicker'
