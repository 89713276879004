import React, { useEffect, useState } from 'react'

import {
	TrialCountdownWrapper,
	CountdownLabel,
	CountdownTime,
	TrialCountdownHeading,
	IconWrapper,
} from './TrialCountdown.styles'
import Icon from '../../components/Icon'

export const TrialCountdown = ({ trialEndDate }) => {
	const calculateTimeLeft = () => {
		const difference = +new Date(`${trialEndDate}`) - +new Date()
		let timeLeft = {}

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24))
					.toString()
					.padStart(2, '0'),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
					.toString()
					.padStart(2, '0'),
				minutes: Math.floor((difference / 1000 / 60) % 60)
					.toString()
					.padStart(2, '0'),
			}
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)
		// Clear timeout if the component is unmounted
		return () => clearTimeout(timer)
	})

	return (
		timeLeft && (
			<TrialCountdownWrapper>
				<TrialCountdownHeading>
					<IconWrapper>
						<Icon
							name="bell"
							size="small"
							color="primary"
							variant="round"
							inverted={true}
						/>
					</IconWrapper>
					<CountdownLabel>Free trial expires in:</CountdownLabel>
				</TrialCountdownHeading>
				<CountdownTime>
					{timeLeft.days || timeLeft.hours || timeLeft.minutes ? (
						<React.Fragment>
							{timeLeft.days ? `${timeLeft.days}d : ` : '00d : '}
							{timeLeft.hours ? `${timeLeft.hours}h : ` : '00h : '}
							{timeLeft.minutes ? `${timeLeft.minutes}min` : '00min'}
						</React.Fragment>
					) : (
						'00d : 00h : 00min'
					)}
				</CountdownTime>
			</TrialCountdownWrapper>
		)
	)
}
