import { useState, useEffect } from 'react'

import { readPlan } from '@/api/videobot'

export const useIsTrial = (account) => {
	const [isTrial, setIsTrial] = useState(false)
	const [expireDate, setExpireDate] = useState(null)

	useEffect(() => {
		if (!account || account.id === -1) {
			return
		}

		readPlan(account.id)
			.then((response) => {
				const currentPlan = response.currentPlan

				if (
					currentPlan &&
					currentPlan.planType === 'trial' &&
					currentPlan.status === 'active'
				) {
					setIsTrial(true)
					setExpireDate(currentPlan.expiredAt)
				} else {
					setIsTrial(false)
					setExpireDate(null)
				}
			})
			.catch((error) => {
				console.error('Error fetching current plan:', error)
			})
	}, [account])

	return { isTrial, expireDate }
}
