import { useMemo } from 'react'
import { Outlet, useOutletContext } from 'react-router'

import { ShareVideobotTabs } from '@/features/Videobot/components/ShareVideobot/ShareVideobotTabs'
import { ViewTransition } from '@/lib/transitions'

import { ShareVideobotContentWrapper, ShareVideobotWrapper } from './ShareVideobot.styles'

const ShareVideobot = () => {
	const { formRef } = useOutletContext()
	const context = useMemo(() => ({ formRef }), [formRef])

	return (
		<ShareVideobotWrapper {...ViewTransition}>
			<ShareVideobotTabs />
			<ShareVideobotContentWrapper>
				<Outlet context={context} />
			</ShareVideobotContentWrapper>
		</ShareVideobotWrapper>
	)
}

export default ShareVideobot
