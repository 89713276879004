import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
	useEffect,
	useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'
import { DatePicker } from '@/components/DatePicker'
import FormRow from '@/components/FormRow'
import FormSection from '@/components/FormSection/FormSection'
import { Col, Row } from '@/components/Grid'
import { useIsTrial } from '@/features/Account/hooks/useAccount'
import PropTypes from '@/lib/propTypes'
import { useReadUsersForAccountById, useUsersResellers } from '@/api/videobot'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import { RoleType } from '@/api/videobot.schemas'

const PLANS = [
	{
		id: 1,
		name: 'Silver',
	},
	{
		id: 2,
		name: 'Gold',
	},
	{
		id: 3,
		name: 'Platinum',
	},
	{
		id: 4,
		name: 'Titanum',
	},
]

const AccountProfileForm = ({ account, onSubmit, variant = 'UPDATE' }, ref) => {
	const { t } = useTranslation(['account'])
	const formRef = useRef(null)
	const activeAccount = useCurrentAccount()
	const currentUser = useCurrentUser()
	const { data: usersList } = useReadUsersForAccountById(
		account?.id,
		{ page: 1, size: 1000 },
		{
			enabled:
				variant === 'UPDATE' &&
				(activeAccount
					? activeAccount.role === RoleType.Super_Admin ||
						activeAccount.role === RoleType.Owner
					: currentUser.isSuperuser || currentUser.isCustomerSuccess),
		},
	)
	const { data: partnersList } = useUsersResellers({
		query: {
			enabled:
				variant === 'UPDATE' && activeAccount
					? activeAccount.role === RoleType.Super_Admin
					: currentUser.isSuperuser || currentUser.isCustomerSuccess,
		},
	})

	const {
		register,
		watch,
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		shouldUnregister: false,
	})

	const STATUS_TYPES = [
		{
			name: 'Active',
			label: t('active'),
		},
		{
			name: 'Free Trial',
			label: t('freeTrial'),
		},
		{
			name: 'Suspended',
			label: t('suspended'),
		},
	]

	const selectedStatus = watch('status')

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		},
	}))

	const { isTrial, expireDate } = useIsTrial(account)

	const determineDefaultStatus = useCallback(() => {
		if (isTrial) {
			return 'Free Trial'
		}
		if (account?.status === 'Active') {
			return 'Active'
		}
		if (account?.status === 'Suspended') {
			return 'Suspended'
		}
	}, [isTrial, account])

	const [defaultStatus, setDefaultStatus] = useState()

	useEffect(() => {
		const status = determineDefaultStatus()
		setDefaultStatus(status)
	}, [isTrial, account, determineDefaultStatus])

	useEffect(() => {
		register('expiredAt')
	}, [register])

	const handleExpiryDateChange = (newDate) => {
		setValue('expiredAt', newDate)
	}

	if (
		!currentUser.isSuperuser &&
		!currentUser.isCustomerSuccess &&
		!currentUser.isReseller &&
		!activeAccount
	) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<form onSubmit={handleSubmit((data) => onSubmit(data))} ref={formRef}>
			{/* Details */}
			<FormSection
				title={t('account:section.accountDetails.label')}
				description={t('account:section.accountDetails.description')}
			>
				{/* Name */}
				<FormRow title={t('account:group.name.label')}>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.firstName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.firstName.placeholder')}
									name="firstName"
									type="text"
									error={errors.firstName}
									defaultValue={account?.firstName}
									required
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.lastName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.lastName.placeholder')}
									name="lastName"
									type="text"
									error={errors.lastName}
									defaultValue={account?.lastName}
									required
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Email & Phone */}
				<FormRow
					title={t('account:group.emailAndPhone.label')}
					description={t('account:group.emailAndPhone.description')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.billingEmail.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.billingEmail.placeholder')}
									name="billingEmail"
									type="email"
									error={errors.billingEmail}
									defaultValue={account?.billingEmail}
									required
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.phoneNumber.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.phoneNumber.placeholder')}
									name="phoneNumber"
									type="text"
									error={errors.phoneNumber}
									defaultValue={account?.phoneNumber}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Business Details */}
				<FormRow
					title={t('account:group.businessDetails.label')}
					description={t('account:group.businessDetails.description')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.businessName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessName.placeholder')}
									name="businessName"
									type="text"
									error={errors.businessName}
									defaultValue={account?.businessName}
									required
								/>
							</FormField>
							<FormField label={t('account:fields.taxId.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.taxId.placeholder')}
									name="taxId"
									type="text"
									error={errors.taxId}
									defaultValue={account?.taxId}
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.businessId.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessId.placeholder')}
									name="businessId"
									type="text"
									defaultValue={account?.businessId}
									error={errors.businessId}
									required
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Business Address */}
				<FormRow
					title={t('account:group.businessAddress.label')}
					description={t('account:group.businessAddress.description')}
				>
					<Row>
						<Col xxs={12}>
							<FormField label={t('account:fields.businessAddress.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessAddress.placeholder')}
									name="address"
									type="text"
									defaultValue={account?.address}
								/>
							</FormField>
						</Col>
					</Row>
					<Row>
						<Col md={4} sm={6} xxs={12}>
							<FormField label={t('account:fields.zip.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.zip.placeholder')}
									name="zipCode"
									country
									type="text"
									error={errors.zipCode}
									defaultValue={account?.zipCode}
								/>
							</FormField>
						</Col>
						<Col md={4} sm={6} xxs={12}>
							<FormField label={t('account:fields.country.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.country.placeholder')}
									name="country"
									type="text"
									error={errors.country}
									defaultValue={account?.country}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Create User Only */}
				{variant === 'CREATE' && (
					<FormRow
						title={t('account:group.createOwnerAccount.label')}
						description={t('account:group.createOwnerAccount.description')}
					>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('account:fields.email.label')} required>
									<FieldText
										register={register}
										placeholder={t('account:fields.email.placeholder')}
										name="ownerEmail"
										type="email"
										error={errors.ownerEmail}
										required
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>
				)}
			</FormSection>

			{/* Update Only */}
			{variant === 'UPDATE' && (
				<React.Fragment>
					{/* Status (Superadmin & Partner & Customer Success) */}
					{(currentUser.isSuperuser ||
						currentUser.isReseller ||
						currentUser.isCustomerSuccess) && (
						<FormRow
							title={t('account:group.status.label')}
							description={t('account:group.status.description')}
						>
							<Row>
								<Col sm={6} xxs={12}>
									<FormField label={t('account:fields.status.label')}>
										<FieldSelect
											register={register}
											placeholder={t('account:fields.status.placeholder')}
											name="status"
											type="text"
											defaultValue={defaultStatus}
											required
											options={STATUS_TYPES.map((item) => ({
												value: item.name,
												label: item.label,
												item,
											}))}
											setValue={setValue}
											error={errors.status}
										/>
									</FormField>
									{selectedStatus === 'Free Trial' && (
										<FormField label={t('account:fields.expiryDate.label')}>
											<DatePicker
												expiredAt={expireDate}
												onChange={handleExpiryDateChange}
											/>
										</FormField>
									)}
								</Col>
							</Row>
						</FormRow>
					)}
					{/* Ownership (Superadmin & Owner & Customer Success) */}
					{(currentUser.isSuperuser ||
						currentUser.isCustomerSuccess ||
						activeAccount.role === RoleType.Owner) &&
						usersList && (
							<FormRow
								title={t('account:group.accountOwner.label')}
								description={t('account:group.accountOwner.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField label={t('account:fields.accountOwner.label')}>
											<FieldSelect
												register={register}
												placeholder={t(
													'account:fields.accountOwner.placeholder',
												)}
												name="newOwnerId"
												type="text"
												defaultValue={usersList.records
													?.find((o) =>
														o.roles.find((r) => r.role === 'Owner'),
													)
													?.id.toString()}
												options={usersList.records?.map((item) => ({
													value: item.id.toString(),
													label: item.email,
													item,
												}))}
												setValue={setValue}
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>
						)}
					{/* Partner (Superadmin & Customer Success & Owner) */}
					{currentUser.isSuperuser &&
						currentUser.isCustomerSuccess &&
						partnersList &&
						usersList && (
							<FormRow
								title={t('account:group.accountPartner.label')}
								description={t('account:group.accountPartner.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField label={t('account:fields.accountPartner.label')}>
											<FieldSelect
												register={register}
												placeholder={t(
													'account:fields.accountPartner.placeholder',
												)}
												name="newPartnerId"
												type="text"
												defaultValue={
													usersList.records?.find((o) =>
														o.roles.find((r) => r.role === 'Reseller'),
													)?.id
												}
												options={partnersList.map((item) => ({
													value: item.id,
													label: item.email,
													item,
												}))}
												setValue={setValue}
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>
						)}
					{/* Account options & limits */}
					{(currentUser.isSuperuser || currentUser.isCustomerSuccess) && (
						<FormSection
							title={t('account:section.options.label')}
							description={t('account:section.options.description')}
						>
							<FormRow
								title={t('account:group.limits.label')}
								description={t('account:group.limits.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('account:fields.maxVideoDuration.label')}
											tooltip={t(
												'account:fields.maxVideoDuration.description',
											)}
										>
											<FieldText
												register={register}
												placeholder="300"
												name="options.maxVideoDuration"
												type="number"
												error={errors.options?.maxVideoDuration}
												size="large"
												defaultValue={account.options?.maxVideoDuration}
												rules={{ valueAsNumber: true }}
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>
							<FormRow
								title={t('account:group.analytics.label')}
								description={t('account:group.analytics.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField
											label={t('account:fields.disableAnalytics.label')}
											tooltip={t(
												'account:fields.disableAnalytics.description',
											)}
										>
											<ControlledFieldToggle
												control={control}
												name="options.disableAnalytics"
												defaultSelected={
													!!account.options?.disableAnalytics
												}
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>
						</FormSection>
					)}
					{/* Payment */}
					{false && (
						<FormSection
							title={t('account:section.plansAndPayment.label')}
							description={t('account:section.plansAndPayment.description')}
						>
							{/* Active Plan */}
							<FormRow
								title={t('account:group.currentAccountsPlan.label')}
								description={t('account:group.currentAccountsPlan.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<FormField label={t('account:fields.activePlan.label')}>
											<FieldSelect
												register={register}
												placeholder={t(
													'account:fields.activePlan.placeholder',
												)}
												name="plan"
												type="text"
												options={PLANS.map((item) => ({
													value: item.id,
													label: item.name,
													item,
												}))}
												setValue={setValue}
												size="large"
											/>
										</FormField>
									</Col>
								</Row>
							</FormRow>
							{/* Credit Card */}
							<FormRow
								title={t('account:group.creditCard.label')}
								description={t('account:group.creditCard.description')}
							>
								<Row>
									<Col sm={6} xxs={12}>
										<Button variant="secondary">
											{t('account:group.creditCard.action')}
										</Button>
									</Col>
								</Row>
							</FormRow>
						</FormSection>
					)}
				</React.Fragment>
			)}
		</form>
	)
}

const propTypes = {
	account: PropTypes.object,
	onSubmit: PropTypes.func,
	variant: PropTypes.oneOf(['CREATE', 'UPDATE']),
}

const ForwardedAccountProfileForm = forwardRef(AccountProfileForm)
ForwardedAccountProfileForm.propTypes = propTypes

export default ForwardedAccountProfileForm
