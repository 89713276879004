import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation, useNavigate } from 'react-router'
import useKeypress from 'react-use-keypress'

import { useOnClickOutside } from '@/lib/hooks'
import PropTypes from '@/lib/propTypes'

import Icon from '../../components/Icon'
import {
	TabsIndicator,
	ViewBack,
	ViewTabs,
	ViewTabsButton,
	ViewTopBarActions,
	ViewTopBarBack,
	ViewTopBarBody,
	ViewTopBarInput,
	ViewTopBarTitle,
	ViewTopBarWrapper,
} from './View.styles'

const ViewTopBar = (props) => {
	const { shortTitle, topBarActions, showOnDesktop, tabs, backRoute, onTitleChange } = props

	const navigate = useNavigate()
	const location = useLocation()
	const activeTabRef = useRef(null)
	const [activeTabStyle, setActiveTabStyle] = useState({})
	const [editableMode, setEditableMode] = useState(false)
	const inputRef = useRef(null)

	const handleBack = () => {
		if (backRoute) {
			navigate(backRoute)
		} else {
			navigate(-1)
		}
	}

	const handleChange = (e) => {
		onTitleChange(e.target.value)
		setEditableMode(false)
	}

	useOnClickOutside(inputRef, () => inputRef.current?.blur())

	useKeypress(['Escape', 'Enter'], () => {
		inputRef.current?.blur()
	})

	useEffect(() => {
		if (editableMode) {
			inputRef.current?.focus()
		} else {
			inputRef.current?.blur()
		}
	}, [editableMode])

	useEffect(() => {
		function updateIndicator() {
			const tabBounds = activeTabRef.current?.getBoundingClientRect()
			if (tabBounds) {
				setActiveTabStyle({
					transform: `translateX(${tabBounds.left}px)`,
					width: `${tabBounds.width}px`,
				})
			}
		}

		updateIndicator()

		window.addEventListener('resize', updateIndicator)
		return () => window.removeEventListener('resize', updateIndicator)
	}, [location.pathname])

	const defaultTransiton = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
		transition: { duration: 0.15 },
	}

	return createPortal(
		<ViewTopBarWrapper as={motion.div} showOnDesktop={showOnDesktop} {...defaultTransiton}>
			<ViewTopBarBody>
				<ViewTopBarBack>
					<ViewBack onClick={handleBack}>
						<Icon name="chevron-left" />
					</ViewBack>
				</ViewTopBarBack>
				<AnimatePresence mode="wait">
					{editableMode ? (
						<ViewTopBarInput
							as={motion.input}
							ref={inputRef}
							type="text"
							defaultValue={shortTitle}
							onBlur={handleChange}
							{...defaultTransiton}
						/>
					) : (
						<ViewTopBarTitle as={motion.h1} {...defaultTransiton}>
							<span>{shortTitle}</span>
							{onTitleChange && (
								<Icon
									name="edit"
									variant="square"
									size="small"
									color="line"
									isButton
									onClick={() => setEditableMode(!editableMode)}
								></Icon>
							)}
						</ViewTopBarTitle>
					)}
				</AnimatePresence>
				<ViewTopBarActions>{topBarActions}</ViewTopBarActions>
			</ViewTopBarBody>
			{tabs && (
				<ViewTabs>
					{tabs.map((tab, index) => (
						<ViewTabsButton
							ref={location.pathname.includes(tab.path) ? activeTabRef : null}
							key={index}
							onClick={() => navigate(tab.path)}
							active={location.pathname.includes(tab.path)}
						>
							{tab.name}
						</ViewTabsButton>
					))}
					<TabsIndicator style={activeTabStyle} />
				</ViewTabs>
			)}
		</ViewTopBarWrapper>,
		document.querySelector('#top-bar'),
	)
}

ViewTopBar.propTypes = {
	shortTitle: PropTypes.string,
	topBarActions: PropTypes.node,
	showOnDesktop: PropTypes.bool,
	tabs: PropTypes.array,
	backRoute: PropTypes.string,
}

export default ViewTopBar
