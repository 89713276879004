import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router'

import Breadcrumbs from '@/components/Breadcrumbs'
import Loader from '@/components/Loader'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import PropTypes from '@/lib/propTypes'

import Icon from '../../components/Icon'
import {
	ViewBack,
	ViewBottomBar,
	ViewContent,
	ViewHeader,
	ViewHeaderActions,
	ViewHeaderBack,
	ViewHeaderBody,
	ViewRouterView,
	ViewScroller,
	ViewWrapper,
} from './View.styles'
import ViewTopBar from './ViewTopBar'
import ViewHeaderTabs from './ViewHeaderTabs'

const ViewBody = (props) => {
	const {
		children,
		header,
		title,
		shortTitle,
		caption,
		cover,
		isRouterView,
		noHeaderBorder,
		actions,
		topBarActions,
		hideHeaderOnMobile,
		hideHeaderActionsOnMobile,
		showTopBar,
		showTopBarOnDesktop,
		breadcrumbs,
		isLoading,
		hide,
		tabs,
		headerTabs,
		backRoute,
		showBack,
		onTitleChange,
		bottomBarActions,
	} = props

	const navigate = useNavigate()
	const isMobile = useMediaQuery('<=lg')

	const handleBack = () => {
		if (backRoute) {
			navigate(backRoute)
		} else {
			navigate(-1)
		}
	}

	return (
		<ViewWrapper
			cover={cover ? 1 : 0}
			as={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.15 }}
		>
			{/* Top Bar (mobile) */}
			{showTopBar && (
				<ViewTopBar
					shortTitle={shortTitle}
					onTitleChange={onTitleChange}
					topBarActions={topBarActions}
					tabs={tabs}
					backRoute={backRoute}
					showOnDesktop={showTopBarOnDesktop}
				/>
			)}
			{isRouterView && (
				<ViewRouterView showTopBarOnDesktop={showTopBarOnDesktop} hasTabs={!!tabs}>
					{headerTabs && (isMobile ? !hideHeaderOnMobile : true) && (
						<ViewHeaderTabs
							hide={hide}
							title={title}
							shortTitle={shortTitle}
							actions={actions}
							tabs={tabs}
						/>
					)}
					{children}
				</ViewRouterView>
			)}

			{/* Tabs */}
			{headerTabs && (isMobile ? !hideHeaderOnMobile : true) && (
				<ViewHeaderTabs
					hide={hide}
					title={title}
					shortTitle={shortTitle}
					actions={actions}
					tabs={tabs}
				/>
			)}

			{!isRouterView && (
				<ViewScroller
					showTopBarOnDesktop={showTopBarOnDesktop}
					hasTabs={!!tabs}
					hasBottomBar={!!bottomBarActions}
				>
					{/* Header */}
					{header && (isMobile ? !hideHeaderOnMobile : true) && (
						<ViewHeader noBorder={noHeaderBorder} hide={hide}>
							{showBack && (
								<ViewHeaderBack>
									<ViewBack onClick={handleBack}>
										<Icon name="chevron-left" />
									</ViewBack>
								</ViewHeaderBack>
							)}
							<ViewHeaderBody>
								{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
								{title && <h1>{title}</h1>}
								{caption && <p>{caption}</p>}
							</ViewHeaderBody>

							{actions && (isMobile ? !hideHeaderActionsOnMobile : true) && (
								<ViewHeaderActions>{actions}</ViewHeaderActions>
							)}
						</ViewHeader>
					)}
					{/* Content */}
					<ViewContent>{children}</ViewContent>
					{/* Loader */}

					{isLoading && <Loader cover />}
				</ViewScroller>
			)}
			{bottomBarActions && <ViewBottomBar>{bottomBarActions}</ViewBottomBar>}
		</ViewWrapper>
	)
}

ViewBody.propTypes = {
	children: PropTypes.node,
	bottomBarActions: PropTypes.node,
	header: PropTypes.bool,
	title: PropTypes.string,
	shortTitle: PropTypes.string,
	caption: PropTypes.string,
	cover: PropTypes.bool,
	noHeaderBorder: PropTypes.bool,
	actions: PropTypes.node,
	topBarActions: PropTypes.node,
	hideHeaderOnMobile: PropTypes.bool,
	hideHeaderActionsOnMobile: PropTypes.bool,
	showTopBar: PropTypes.bool,
	showTopBarOnDesktop: PropTypes.bool,
	breadcrumbs: PropTypes.array,
	tabs: PropTypes.array,
	headerTabs: PropTypes.bool,
	isLoading: PropTypes.bool,
	isRouterView: PropTypes.bool,
	hide: PropTypes.bool,
	backRoute: PropTypes.string,
	showBack: PropTypes.bool,
	onTitleChange: PropTypes.func,
}

const View = (props) => {
	const { cover } = props

	if (cover) return createPortal(<ViewBody {...props} />, document.querySelector('#view'))
	else return <ViewBody {...props} />
}

View.propTypes = {
	cover: PropTypes.bool,
}

export default View
