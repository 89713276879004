export const breakpoints = {
	xxs: 0,
	xs: 575,
	sm: 767,
	md: 991,
	lg: 1199,
	xl: 1439,
	xxl: 1829,
}

const theme = {
	font: {
		primary: 'Open Sans, sans-serif',
		secondary: 'Montserrat, sans-serif',
	},
	color: {
		body: '#ffffff',
		primary: '#351DDA',
		global: '#000329',
		inverted: '#ffffff',
		muted: 'rgba(0,3,41,0.55)',
		mutedLight: 'rgba(0,3,41,0.4)',
		mutedInverted: 'rgba(255,255,255,0.6)',
		green: '#0AB500',
		greenLight: '#CEF0CC',
		purple: '#CCBBFF',
		purpleLight: '#EFEAFF',
		purpleDark: '#9747FF',
		purpleTransparent: 'rgba(204,187,255,0.3)',
		light: '#EFF3F8',
		dark: '#000329',
		medium: '#333553',
		line: '#DCE2EC',
		lineInverted: 'rgba(255,255,255,0.15)',
		red: '#C91900',
		redLight: '#FBE8E5',
		darkOverlay: 'rgba(0,3,41,0.4)',
	},
	radius: {
		sm: '0.3rem',
		md: '0.4rem',
		lg: '0.6rem',
		xl: '0.8rem',
	},
	control: {
		sm: '2.2rem',
		md: '2.75rem',
		lg: '3.2rem',
	},
	grid: {
		outerMargin: '16px',
		gutterWidth: '16px',
	},
	spacing: {
		desktop: {
			md: '3rem',
			sm: '1.5rem',
			xs: '0.8rem',
		},
		mobile: {
			md: '2rem',
			sm: '1.25rem',
		},
	},
	size: {
		mobileNavBar: '64px',
		desktopNav: {
			collapsed: '5rem',
			extended: '17rem',
		},
		topBar: {
			mobile: '56px',
			desktop: '72px',
		},
		tabsBar: {
			mobile: '40px',
			desktop: '36px',
		},
		thumbnail: {
			mobile: '3.75rem',
			desktop: '3.25rem',
		},
	},
	shadow: {
		sm: '0 2px 12px 0 rgba(0,0,0,0.125)',
		md: '0 2px 24px 0 rgba(0,0,0,0.125)',
		lg: '0 2px 90px 0 rgba(0,0,0,0.02)',
	},
}

export default theme
