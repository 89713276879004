import { useForm } from 'react-hook-form'
import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import CheckPicker from 'rsuite/CheckPicker'
import { useAtom } from 'jotai'
import { debounce } from 'lodash-es'

import Modal from '@/components/Modal'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import { useReadAccounts, useGetAllUsers, useUpdateFeature } from '@/api/videobot'
import { featureAccountsFilter, featureUsersFilter } from '@/pages/admin/admin.store'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

export const FeatureFlagEditModal = ({ flag, onClose, onUpdateFlag }) => {
	const { t } = useTranslation(['feature'])
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		values: flag,
	})

	const [accountIds, setAccountIds] = useAtom(featureAccountsFilter) || []
	const [userIds, setUserIds] = useAtom(featureUsersFilter) || []

	useEffect(() => {
		setAccountIds(flag.account_ids || [])
		setUserIds(flag.user_ids || [])
	}, [flag, setAccountIds, setUserIds])

	const [businessName, setBusinessName] = useState('')

	const debouncedSearch = useMemo(
		() =>
			debounce((value) => {
				setBusinessName(value)
			}, 300),
		[],
	)

	const handleBusinessNameChange = (value) => {
		debouncedSearch(value)
	}

	const { data: allAccountsData } = useReadAccounts({ size: 100, business_name: businessName })
	const { data: users } = useGetAllUsers()

	const accountSelections =
		allAccountsData?.records.map((account) => ({
			label: account.businessName,
			value: account.id,
		})) || []

	const userSelections =
		users?.map((user) => ({
			label: user.email,
			value: user.id,
		})) || []

	const handleClose = () => {
		setAccountIds([])
		setUserIds([])
		onClose()
	}
	const onSuccess = () => {
		toast.success(t('Flag updated successfully!'))
		onUpdateFlag()
		handleClose()
	}

	const onError = () => {
		toast.error(t('Error updating flag. Please try again.'))
	}

	const mutation = useUpdateFeature({
		mutation: {
			onSuccess,
			onError,
		},
	})

	const updateFlag = async (payload) => {
		await mutation.mutateAsync({ data: payload })
	}

	const onSubmit = async (data) => {
		const selectedUserIds = userIds || []
		const selectedAccountIds = accountIds || []
		const payload = {
			name: flag.name,
			flag_type: flag.flag_type,
			user_ids: selectedUserIds,
			account_ids: selectedAccountIds,
			is_everyone: data.is_everyone,
			is_superadmin: data.is_superadmin,
		}
		await updateFlag(payload)
	}

	return (
		flag && (
			<Modal header={<h3>{t('editFlag', { name: flag.name })}</h3>} onClose={handleClose}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormField label={t('editAccountIds')}>
						<div style={{ width: '100%' }}>
							<CheckPicker
								data={accountSelections}
								value={accountIds}
								onChange={(value) => {
									setAccountIds(value)
								}}
								onSearch={handleBusinessNameChange}
								style={{ width: '100%' }}
								placeholder="Edit accounts"
							/>
						</div>
					</FormField>

					<FormField label={t('editUserIds')}>
						<div style={{ width: '100%' }}>
							<CheckPicker
								data={userSelections}
								value={userIds}
								onChange={(value) => {
									setUserIds(value)
								}}
								style={{ width: '100%' }}
								placeholder="Edit users"
							/>
						</div>
					</FormField>
					<FormField label={t('is_everyone')}>
						<ControlledFieldToggle control={control} name="is_everyone" />
					</FormField>

					<FormField label={t('is_superadmin')}>
						<ControlledFieldToggle control={control} name="is_superadmin" />
					</FormField>
					<Button disabled={!isValid} block>
						{t('common:Save')}
					</Button>
				</form>
			</Modal>
		)
	)
}
