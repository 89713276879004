import styled from 'styled-components'

import { InputField, Reset } from '../../lib/mixins'
import media from '../../lib/media'

export const DatePickerWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 35%;
`

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
`

export const CalendarIconWrapper = styled.div`
	cursor: pointer;
	user-select: none;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	tabindex: '0'; // Making it focusable
`

export const DateInput = styled.input`
	${Reset('all')}
	${InputField()}
    display: block;
	width: 100%;
	padding-right: 40px;

	border: 1px solid ${(props) => props.theme.color.line};
	border-radius: 4px;
	padding: 8px 12px;

	&:focus {
		outline: none;
		border-color: ${(props) => props.theme.color.primary};
	}

	${media('<=md')} {
		padding: 6px 10px;
	}
`

export const CalendarContainer = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 1000;
`
