import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { v4 } from 'uuid'
import { useAtom } from 'jotai'

import PropTypes from '@/lib/propTypes'
import { currentPopupAtom } from '@/common/layout'

import Icon from '../Icon'
import {
	MoreDropdown,
	MoreDropdownWrapper,
	MoreToggler,
	MoreTogglerWrapper,
	MoreWrapper,
} from './More.styles'

const More = (props) => {
	const { children, variant, placement = 'bottom-start', customToggler, offset, inverted } = props
	const wrapperElement = useRef(null)
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [currentPopup, setCurrentPopup] = useAtom(currentPopupAtom)
	const uuidv4 = useRef(v4())

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement,
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: offset || [0, 5],
				},
			},
		],
	})

	const handleTriggerClick = (e) => {
		if (uuidv4.current !== currentPopup) {
			setCurrentPopup(uuidv4.current)
		} else {
			setCurrentPopup(null)
		}

		e.stopPropagation()
	}

	useEffect(() => {
		if (currentPopup === uuidv4.current) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [currentPopup])

	const handleDocumentClick = (_e) => {
		setCurrentPopup(null)
	}
	const handleDropdownClick = (e) => {
		setCurrentPopup(null)
		e.stopPropagation()
	}

	useEffect(() => {
		document.addEventListener('click', handleDocumentClick)

		return () => {
			document.removeEventListener('click', handleDocumentClick)
		}
	}, [])

	return (
		<MoreWrapper type="button" ref={wrapperElement} variant={variant} inverted={inverted}>
			<MoreTogglerWrapper onClick={handleTriggerClick} ref={setReferenceElement}>
				{customToggler || (
					<MoreToggler open={isOpen}>
						<Icon name="dots"></Icon>
					</MoreToggler>
				)}
			</MoreTogglerWrapper>
			{createPortal(
				<AnimatePresence mode="wait">
					{isOpen && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.15 }}
						>
							<MoreDropdownWrapper
								style={styles.popper}
								{...attributes.popper}
								ref={setPopperElement}
							>
								<MoreDropdown onClick={handleDropdownClick}>
									{children}
								</MoreDropdown>
							</MoreDropdownWrapper>
						</motion.div>
					)}
				</AnimatePresence>,
				document.querySelector('#popups'),
			)}
		</MoreWrapper>
	)
}

More.propTypes = {
	children: PropTypes.node,
	variant: PropTypes.string,
	placement: PropTypes.string,
	customToggler: PropTypes.node,
	offset: PropTypes.array,
	inverted: PropTypes.bool,
}

export default More
