import { useState, useEffect } from 'react'

import {
	CalendarContainer,
	CalendarNavigation,
	Grid,
	Day,
	DateBox,
	SelectedDate,
	Divider,
	Button,
} from './Calendar.styles'

export const Calendar = ({ expiredAt, onDateSelect }) => {
	const [currentDate, setCurrentDate] = useState(expiredAt || new Date())
	const [highlightedDate, setHighlightedDate] = useState(expiredAt || new Date())

	useEffect(() => {
		setCurrentDate(expiredAt || new Date())
		setHighlightedDate(expiredAt || new Date())
	}, [expiredAt])

	const navigate = (months) => {
		const newDate = new Date(currentDate)
		newDate.setMonth(currentDate.getMonth() + months)
		setCurrentDate(newDate)
		setHighlightedDate(null) // Clear the highlighted date when navigating months
	}

	const generateDays = (date) => {
		const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
		const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

		const startDay = firstDay.getDay()
		const endDay = lastDay.getDay()

		const days = []

		for (let i = 0; i < startDay; i++) {
			const prevDate = new Date(firstDay)
			prevDate.setDate(-i)
			days.unshift({ date: prevDate, isCurrentMonth: false })
		}

		for (let i = 1; i <= lastDay.getDate(); i++) {
			days.push({
				date: new Date(date.getFullYear(), date.getMonth(), i),
				isCurrentMonth: true,
			})
		}

		for (let i = 1; i < 7 - endDay; i++) {
			const nextDate = new Date(lastDay)
			nextDate.setDate(nextDate.getDate() + i)
			days.push({ date: nextDate, isCurrentMonth: false })
		}

		return days
	}

	return (
		<CalendarContainer>
			<SelectedDate>
				{`${(highlightedDate || currentDate).getDate()}.${
					(highlightedDate || currentDate).getMonth() + 1
				}.${(highlightedDate || currentDate).getFullYear()}`}
			</SelectedDate>
			<Divider />
			<CalendarNavigation>
				<button onClick={() => navigate(-1)} type="button">
					&lt;
				</button>
				<span>
					{currentDate.toLocaleDateString('default', { month: 'short' })},{' '}
					{currentDate.getFullYear()}
				</span>
				<button onClick={() => navigate(1)} type="button">
					&gt;
				</button>
			</CalendarNavigation>
			<Grid>
				{['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
					<Day key={day}>{day}</Day>
				))}
			</Grid>
			<Grid>
				{generateDays(currentDate).map((day) => (
					<DateBox
						key={day.date}
						isCurrentMonth={day.isCurrentMonth}
						isHighlighted={
							highlightedDate &&
							highlightedDate.getDate() === day.date.getDate() &&
							highlightedDate.getMonth() === day.date.getMonth() &&
							highlightedDate.getFullYear() === day.date.getFullYear()
						}
						onClick={() => day.isCurrentMonth && setHighlightedDate(day.date)}
					>
						{day.date.getDate()}
					</DateBox>
				))}
			</Grid>
			<Divider />
			<Button
				onClick={() => {
					if (highlightedDate) {
						setCurrentDate(highlightedDate)
						onDateSelect(highlightedDate)
						setHighlightedDate(null)
					}
				}}
			>
				OK
			</Button>
		</CalendarContainer>
	)
}
