import styled from 'styled-components'

import media from '@/lib/media'

export const ShareVideobotWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const ShareVideobotContentWrapper = styled.div`
	display: flex;
	height: calc(100% - 56px);

	${media('<=lg')} {
		height: calc(100% - ${(props) => props.theme.size.topBar.desktop} - 2rem);
	}
`

export const ShareVideobotConfigWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: #fff;
	padding-bottom: calc(
		${(props) => props.theme.size.mobileNavBar} + ${(props) => props.theme.spacing.desktop.sm}
	);

	& form {
		padding-top: 12px;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		overflow: auto;
	}

	${media('>lg')} {
		width: 416px;
		padding-bottom: 0;
	}

	${media('<=lg')} {
		flex: 1 1 auto;
	}
`

export const ShareVideobotActions = styled.section`
	display: flex;
	flex: 0 0 auto;

	width: 100%;
	padding: 16px 32px;

	border-top: 1px solid ${(props) => props.theme.color.line};

	${media('<=lg')} {
		display: none;
	}

	> * {
		flex: 1;
	}
`

export const ShareVideobotPreviewWrapper = styled.div`
	display: flex;

	flex: 1;
	position: relative;
	max-height: 100%;

	iframe {
		display: block;
		border: 0;
	}
`

export const ShareVideobotIframePreviewWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	background-color: ${(props) => props.theme.color.light};

	${media('>lg')} {
		max-width: calc(100% - 416px);

		iframe {
			max-height: calc(90vh - ${(props) => props.theme.size.topBar.desktop} - 56px);
		}
	}

	${media('<=lg')} {
		display: none;
	}

	flex: 1;
	position: relative;
`

export const ShareVideobotDisplayAdPreviewWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;

	${media('>lg')} {
		max-width: calc(100% - 416px);
	}

	${media('<=lg')} {
		display: none;
	}

	position: relative;
`

export const ShareVideobotSection = styled.section`
	display: flex;
	flex-direction: column;
	/* margin-bottom: 32px; */
	padding: 12px 32px;

	& label {
		font-size: 0.8rem;
	}

	& h4 {
		font-size: 1rem;
		font-family: ${(props) => props.theme.font.secondary};
		font-weight: 600;

		color: ${(props) => props.theme.color.dark};
	}
`
