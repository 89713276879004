import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const AddFormModal = ({ onClose, show, isLoading, onSubmit }, _ref) => {
	const { t } = useTranslation(['videobot'])

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})

	const handleFormSubmit = (data) => {
		if (onSubmit)
			onSubmit({
				...data,
				type: 'REGULAR_FORM',
			})
	}

	return (
		<Modal header={<h3>{t('videobot:createNewContactForm')}</h3>} show={show} onClose={onClose}>
			<form autoComplete="off" onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
				<FormField label={t('videobot:formFields.title.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:formFields.title.placeholder')}
						name="name"
						type="name"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>
				<Button disabled={!isValid} block isLoading={isLoading}>
					{t('videobot:createContactForm')}
				</Button>
			</form>
		</Modal>
	)
}

AddFormModal.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default AddFormModal
