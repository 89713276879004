/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Videobot
 * OpenAPI spec version: 0.1.0
 */
export type Healthcheck200 = {};

export type UpdateFeature200 = {};

export type CreateFlag200 = {};

export type GetAllFeatureFlagsParams = {
flag_name?: string | null;
account_id?: number | null;
flag_type?: FlagType | null;
};

export type GetFeatureFlagsForCurrentUserParams = {
account_id?: number | null;
language?: LanguageType | null;
};

export type GetLegacyInfo200 = {};

export type GetCfBotAnalyticsParams = {
from_date: string;
to_date: string;
icon_identifier?: string | null;
};

export type ReadAccountDailyTotalAnalyticsParams = {
channel: EventChannelType;
from_date: string;
to_date: string;
bot_ids?: string;
user_ids?: string;
slider_ids?: string;
};

export type ReadAnalyticReportParams = {
account_id: number;
channel: EventChannelType;
from_date?: string | null;
to_date?: string | null;
bot_ids?: string;
user_ids?: string;
slider_ids?: string;
};

export type ReadDetailBotAnalyticParams = {
channel: EventChannelType;
from_date?: string | null;
to_date?: string | null;
};

export type ReadPaginatedWidgetAnalyticsParams = {
from_date?: string | null;
to_date?: string | null;
page?: number;
size?: number;
slider_ids?: string;
};

export type ReadAccountTotalAnalyticsParams = {
channel: EventChannelType;
from_date?: string | null;
to_date?: string | null;
bot_ids?: string;
user_ids?: string;
slider_ids?: string;
};

export type ReadPaginateBotAnalyticsParams = {
account_id: number;
channel: EventChannelType;
from_date?: string | null;
to_date?: string | null;
page?: number;
size?: number;
bot_ids?: string;
user_ids?: string;
slider_ids?: string;
};

export type ReadAllWidgetsParams = {
account_id: number;
page?: number;
size?: number;
};

export type ReadAllTagsParams = {
account_id: number;
query?: string;
page?: number;
size?: number;
};

export type BotSubmitFormLegacy200 = {};

export type BotSubmitForm200 = {};

export type ReadBotWidgetEmbedByPublicId200 = BotWidgetEmbedRead | null;

export type ReadBotEmbedByPublicId200 = BotEmbedRead | null;

export type ReadAllBotEmbedsParams = {
account_id: number;
page?: number;
size?: number;
order_by?: BotOrderBy;
order_direction?: OrderDirectionType;
tag_ids?: string | null;
};

export type ReadAllBotsParams = {
account_id: number;
page?: number;
size?: number;
order_by?: BotOrderBy;
order_direction?: OrderDirectionType;
bot_name?: string | null;
tag_ids?: string | null;
};

export type MediaWebhook200 = {};

export type DeleteMediaCaptionById200 = {};

export type DeleteMediaCaptionByIdParams = {
account_id: number;
};

export type UploadMediaCaptionByIdParams = {
language: CaptionLanguageType;
account_id: number;
};

export type UpdateMediaByIdParams = {
account_id: number;
};

export type CreateMediaTus200 = {};

export type GetAllMediaParams = {
account_id: number;
page?: number;
size?: number;
filter?: MediaFilterType;
media_type?: MediaType | null;
name?: string | null;
order_by?: MediaOrderBy;
order_direction?: OrderDirectionType;
};

export type ReadUsersForAccountByIdParams = {
page?: number;
size?: number;
};

export type ReadAccountsDetailParams = {
page?: number;
size?: number;
order_by?: AccountOrderBy;
order_direction?: OrderDirectionType;
business_name?: string | null;
};

export type ReadAccountsParams = {
page?: number;
size?: number;
filter?: AccountsFilterType;
order_by?: AccountOrderBy;
order_direction?: OrderDirectionType;
business_name?: string | null;
};

export interface WidgetVisible {
  desktop: number;
  mobile: number;
  tablet: number;
}

export type WidgetUpdateType = WidgetType | null;

export type WidgetUpdateThumbnailsVisible = WidgetVisible | null;

export type WidgetUpdateStatus = BotStatusType | null;

export type WidgetUpdateSlidesVisible = WidgetVisible | null;

export type WidgetUpdateSelectedTagIds = number[] | null;

export type WidgetUpdateSelectedBotIds = number[] | null;

export type WidgetUpdatePrivacy = PrivacyType | null;

export type WidgetUpdateName = string | null;

export type WidgetUpdateContentType = WidgetContentType | null;

export type WidgetUpdateContentSortBy = WidgetContentSortBy | null;

export type WidgetUpdateCardsVisible = WidgetVisible | null;

export type WidgetUpdateBreakpoints = WidgetBreakpoints | null;

export type WidgetUpdateAppearanceSettings = WidgetAppearanceSettings | null;

export interface WidgetUpdate {
  appearanceSettings?: WidgetUpdateAppearanceSettings;
  breakpoints?: WidgetUpdateBreakpoints;
  cardsVisible?: WidgetUpdateCardsVisible;
  contentSortBy?: WidgetUpdateContentSortBy;
  contentType?: WidgetUpdateContentType;
  name?: WidgetUpdateName;
  privacy?: WidgetUpdatePrivacy;
  selectedBotIds?: WidgetUpdateSelectedBotIds;
  selectedTagIds?: WidgetUpdateSelectedTagIds;
  slidesVisible?: WidgetUpdateSlidesVisible;
  status?: WidgetUpdateStatus;
  thumbnailsVisible?: WidgetUpdateThumbnailsVisible;
  type?: WidgetUpdateType;
}

/**
 * Widget type
 */
export type WidgetType = typeof WidgetType[keyof typeof WidgetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetType = {
  Slider: 'Slider',
  Thumbnail: 'Thumbnail',
  Card: 'Card',
} as const;

export type WidgetReadPublicId = string | null;

export interface WidgetEmbedRead {
  appearanceSettings: WidgetAppearanceSettings;
  bots?: BotEmbedRead[];
  breakpoints: WidgetBreakpoints;
  cardsVisible: WidgetVisible;
  contentSortBy: WidgetContentSortBy;
  id: number;
  name: string;
  slidesVisible: WidgetVisible;
  thumbnailsVisible: WidgetVisible;
  type: WidgetType;
}

export type WidgetDescriptionPosition = typeof WidgetDescriptionPosition[keyof typeof WidgetDescriptionPosition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetDescriptionPosition = {
  Top: 'Top',
  Bottom: 'Bottom',
} as const;

export interface WidgetCreate {
  accountId: number;
  contentSortBy?: string;
  contentType?: string;
  name: string;
  privacy?: string;
  status?: string;
  type: WidgetType;
}

/**
 * Which Videobots should be played in the widget.
 */
export type WidgetContentType = typeof WidgetContentType[keyof typeof WidgetContentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetContentType = {
  All: 'All',
  Selected: 'Selected',
  ByTags: 'ByTags',
} as const;

/**
 * Sort Videobots in the widget.
 */
export type WidgetContentSortBy = typeof WidgetContentSortBy[keyof typeof WidgetContentSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetContentSortBy = {
  Latest: 'Latest',
  Random: 'Random',
  Custom: 'Custom',
} as const;

export interface WidgetBreakpoints {
  desktop: number;
  mobile: number;
  tablet: number;
}

export type WidgetAuthorReadLastName = string | null;

export type WidgetAuthorReadFirstName = string | null;

export interface WidgetAuthorRead {
  email: string;
  firstName?: WidgetAuthorReadFirstName;
  id: number;
  lastName?: WidgetAuthorReadLastName;
}

export interface WidgetRead {
  accountId: number;
  appearanceSettings: WidgetAppearanceSettings;
  author: WidgetAuthorRead;
  breakpoints: WidgetBreakpoints;
  cardsVisible: WidgetVisible;
  contentSortBy: WidgetContentSortBy;
  contentType: WidgetContentType;
  createdAt: string;
  id: number;
  isDeactivated: boolean;
  isDeleted: boolean;
  name: string;
  privacy: PrivacyType;
  publicId?: WidgetReadPublicId;
  selectedBotIds: number[];
  selectedTagIds: number[];
  slidesVisible: WidgetVisible;
  status: BotStatusType;
  thumbnailsVisible: WidgetVisible;
  type: WidgetType;
  updatedAt: string;
}

export type WidgetAppearanceSettingsThumbnailSize = number | null;

export type WidgetAppearanceSettingsStylesRaw = string | null;

export type WidgetAppearanceSettingsStylesAnyOf = {[key: string]: {[key: string]: string}};

export type WidgetAppearanceSettingsStyles = WidgetAppearanceSettingsStylesAnyOf | null;

export type WidgetAppearanceSettingsHorizontalMode = boolean | null;

export type WidgetAppearanceSettingsDescriptionEnabled = boolean | null;

export type WidgetAppearanceSettingsAutoPreview = boolean | null;

export type WidgetAlignment = typeof WidgetAlignment[keyof typeof WidgetAlignment];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetAlignment = {
  Left: 'Left',
  Right: 'Right',
  Center: 'Center',
} as const;

export interface WidgetAppearanceSettings {
  align: WidgetAlignment;
  arrows: boolean;
  autoPreview?: WidgetAppearanceSettingsAutoPreview;
  bullets: boolean;
  descriptionEnabled?: WidgetAppearanceSettingsDescriptionEnabled;
  descriptionPosition?: WidgetDescriptionPosition;
  horizontalMode?: WidgetAppearanceSettingsHorizontalMode;
  infinite: boolean;
  radius: number;
  styles?: WidgetAppearanceSettingsStyles;
  stylesRaw?: WidgetAppearanceSettingsStylesRaw;
  thumbnailSize?: WidgetAppearanceSettingsThumbnailSize;
  title: boolean;
}

export type VideobotSchedulerSettingsStartTime = string | null;

export type VideobotSchedulerSettingsEndTime = string | null;

export type VideobotSchedulerSettingsEnabled = boolean | null;

export interface VideobotSchedulerSettings {
  enabled?: VideobotSchedulerSettingsEnabled;
  endTime?: VideobotSchedulerSettingsEndTime;
  startTime?: VideobotSchedulerSettingsStartTime;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserWithRolesReadProfilePictureId = number | null;

export type UserWithRolesReadProfilePicture = MediaReadOutput | null;

export type UserWithRolesReadPhoneNumber = string | null;

export type UserWithRolesReadLastName = string | null;

export type UserWithRolesReadFirstName = string | null;

export interface UserWithRolesRead {
  email: string;
  firstName?: UserWithRolesReadFirstName;
  id: number;
  isActive?: boolean;
  isCustomerSuccess: boolean;
  isReseller: boolean;
  isSuperuser?: boolean;
  isVerified?: boolean;
  language: LanguageType;
  lastName?: UserWithRolesReadLastName;
  phoneNumber?: UserWithRolesReadPhoneNumber;
  profilePicture?: UserWithRolesReadProfilePicture;
  profilePictureId?: UserWithRolesReadProfilePictureId;
  roles: RoleDetailRead[];
}

export type UserUpdateProfilePictureId = number | null;

export type UserUpdatePhoneNumber = string | null;

export type UserUpdatePassword = string | null;

export type UserUpdateLastName = string | null;

export type UserUpdateLanguage = LanguageType | null;

export type UserUpdateIsVerified = boolean | null;

export type UserUpdateIsSuperuser = boolean | null;

export type UserUpdateIsReseller = boolean | null;

export type UserUpdateIsActive = boolean | null;

export type UserUpdateFirstName = string | null;

export type UserUpdateEmail = string | null;

export interface UserUpdate {
  email?: UserUpdateEmail;
  firstName?: UserUpdateFirstName;
  isActive?: UserUpdateIsActive;
  isReseller?: UserUpdateIsReseller;
  isSuperuser?: UserUpdateIsSuperuser;
  isVerified?: UserUpdateIsVerified;
  language?: UserUpdateLanguage;
  lastName?: UserUpdateLastName;
  password?: UserUpdatePassword;
  phoneNumber?: UserUpdatePhoneNumber;
  profilePictureId?: UserUpdateProfilePictureId;
}

export type UserReadProfilePictureId = number | null;

export type UserReadProfilePicture = MediaReadOutput | null;

export type UserReadPhoneNumber = string | null;

export type UserReadLastName = string | null;

export type UserReadFirstName = string | null;

export type UserInAccountReadProfilePictureId = number | null;

export type UserInAccountReadProfilePicture = MediaReadOutput | null;

export type UserInAccountReadPhoneNumber = string | null;

export type UserInAccountReadLastName = string | null;

export type UserInAccountReadFirstName = string | null;

export interface UserInAccountRead {
  email: string;
  firstName?: UserInAccountReadFirstName;
  id: number;
  isActive?: boolean;
  isCustomerSuccess: boolean;
  isReseller: boolean;
  isSuperuser?: boolean;
  isVerified?: boolean;
  language: LanguageType;
  lastName?: UserInAccountReadLastName;
  phoneNumber?: UserInAccountReadPhoneNumber;
  profilePicture?: UserInAccountReadProfilePicture;
  profilePictureId?: UserInAccountReadProfilePictureId;
  roles: RoleRead[];
}

export type UserFinalizeProfilePictureId = number | null;

export type UserFinalizePhoneNumber = string | null;

export type UserFinalizePassword = string | null;

export type UserFinalizeLastName = string | null;

export type UserFinalizeLanguage = LanguageType | null;

export type UserFinalizeIsVerified = boolean | null;

export type UserFinalizeIsSuperuser = boolean | null;

export type UserFinalizeIsReseller = boolean | null;

export type UserFinalizeIsActive = boolean | null;

export type UserFinalizeFirstName = string | null;

export type UserFinalizeEmail = string | null;

export type UserFinalizeAccountId = number | null;

export interface UserFinalize {
  accountId?: UserFinalizeAccountId;
  email?: UserFinalizeEmail;
  firstName?: UserFinalizeFirstName;
  isActive?: UserFinalizeIsActive;
  isReseller?: UserFinalizeIsReseller;
  isSuperuser?: UserFinalizeIsSuperuser;
  isVerified?: UserFinalizeIsVerified;
  language?: UserFinalizeLanguage;
  lastName?: UserFinalizeLastName;
  password?: UserFinalizePassword;
  phoneNumber?: UserFinalizePhoneNumber;
  profilePictureId?: UserFinalizeProfilePictureId;
}

export type UserAdminUpdateProfilePictureId = number | null;

export type UserAdminUpdatePhoneNumber = string | null;

export type UserAdminUpdatePassword = string | null;

export type UserAdminUpdateLastName = string | null;

export type UserAdminUpdateLanguage = LanguageType | null;

export type UserAdminUpdateIsVerified = boolean | null;

export type UserAdminUpdateIsSuperuser = boolean | null;

export type UserAdminUpdateIsReseller = boolean | null;

export type UserAdminUpdateIsActive = boolean | null;

export type UserAdminUpdateFirstName = string | null;

export type UserAdminUpdateEmail = string | null;

export interface UserAdminUpdate {
  email?: UserAdminUpdateEmail;
  firstName?: UserAdminUpdateFirstName;
  isActive?: UserAdminUpdateIsActive;
  isReseller?: UserAdminUpdateIsReseller;
  isSuperuser?: UserAdminUpdateIsSuperuser;
  isVerified?: UserAdminUpdateIsVerified;
  language?: UserAdminUpdateLanguage;
  lastName?: UserAdminUpdateLastName;
  password?: UserAdminUpdatePassword;
  phoneNumber?: UserAdminUpdatePhoneNumber;
  profilePictureId?: UserAdminUpdateProfilePictureId;
}

export type UrlMapsSettingsEnabled = boolean | null;

export type UrlMapsSettingsDefaultBotDisabled = boolean | null;

export interface UrlMapsSettings {
  defaultBotDisabled?: UrlMapsSettingsDefaultBotDisabled;
  enabled?: UrlMapsSettingsEnabled;
}

export interface TagSchema {
  accountId: number;
  id: number;
  name: string;
}

export interface TagCreate {
  accountId: number;
  name: string;
}

export type SocialIconType = typeof SocialIconType[keyof typeof SocialIconType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialIconType = {
  Facebook: 'Facebook',
  Linkedin: 'Linkedin',
  Instagram: 'Instagram',
  WhatsApp: 'WhatsApp',
  Telegram: 'Telegram',
  Reddit: 'Reddit',
  Calendly: 'Calendly',
  Viber: 'Viber',
  Snapchat: 'Snapchat',
  Tiktok: 'Tiktok',
  X: 'X',
  Website: 'Website',
  Email: 'Email',
  Pinterest: 'Pinterest',
  Youtube: 'Youtube',
  Vimeo: 'Vimeo',
} as const;

export interface SocialLink {
  icon: SocialIconType;
  target: LinkType;
  url: string;
}

/**
 * Option type for slide.
 */
export type SlideOptionType = typeof SlideOptionType[keyof typeof SlideOptionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideOptionType = {
  SLIDE: 'SLIDE',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  REGULAR_FORM: 'REGULAR_FORM',
  POST_MESSAGE: 'POST_MESSAGE',
  CLOSE_VIDEOBOT: 'CLOSE_VIDEOBOT',
} as const;

export type SlideCategoryType = typeof SlideCategoryType[keyof typeof SlideCategoryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideCategoryType = {
  INTRO: 'INTRO',
  SLIDE: 'SLIDE',
} as const;

/**
 * User role in an account.
 */
export type RoleType = typeof RoleType[keyof typeof RoleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
  Owner: 'Owner',
  Member: 'Member',
  Reseller: 'Reseller',
  Admin: 'Admin',
  Super_Admin: 'Super Admin',
} as const;

/**
 * User status in an account.
 */
export type RoleStatusType = typeof RoleStatusType[keyof typeof RoleStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleStatusType = {
  Active: 'Active',
  Invited: 'Invited',
  Declined: 'Declined',
} as const;

export interface RoleUpdate {
  status: RoleStatusType;
}

export interface RoleRead {
  accountId: number;
  id: number;
  role: RoleType;
  status: RoleStatusType;
  userId: number;
}

export interface RoleDetailRead {
  account: AccountSimpleRead;
  accountId: number;
  id: number;
  role: RoleType;
  status: RoleStatusType;
  userId: number;
}

export type RoleAccountUpdateStatus = RoleStatusType | null;

export type RoleAccountUpdateRole = 'Member' | 'Admin' | null;

export interface RoleAccountUpdate {
  role?: RoleAccountUpdateRole;
  status?: RoleAccountUpdateStatus;
}

export interface ResellerInviteCreate {
  email: string;
}

export type ProfileDetailsUpdateIntroductionId = number | null;

export type ProfileDetailsUpdateDescription = string | null;

export type ProfileDetailsUpdateCoverId = number | null;

export type ProfileDetailsUpdateCompanyName = string | null;

export type ProfileDetailsUpdateActionsItem = ContactFormInput | ExternalLink;

export interface ProfileDetailsUpdate {
  actions?: ProfileDetailsUpdateActionsItem[];
  companyName?: ProfileDetailsUpdateCompanyName;
  coverId?: ProfileDetailsUpdateCoverId;
  description?: ProfileDetailsUpdateDescription;
  introductionId?: ProfileDetailsUpdateIntroductionId;
  socialLinks?: SocialLink[];
}

export type ProfileDetailsReadIntroduction = MediaReadOutput | null;

export type ProfileDetailsReadDescription = string | null;

export type ProfileDetailsReadCover = MediaReadOutput | null;

export type ProfileDetailsReadCompanyName = string | null;

export type ProfileDetailsReadActionsItem = ContactFormOutput | ExternalLink;

export interface ProfileDetailsRead {
  actions?: ProfileDetailsReadActionsItem[];
  companyName?: ProfileDetailsReadCompanyName;
  cover?: ProfileDetailsReadCover;
  description?: ProfileDetailsReadDescription;
  introduction?: ProfileDetailsReadIntroduction;
  socialLinks?: SocialLink[];
}

/**
 * Videobot privacy setting.
 */
export type PrivacyType = typeof PrivacyType[keyof typeof PrivacyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrivacyType = {
  Public: 'Public',
  Private: 'Private',
} as const;

export type PlacementType = typeof PlacementType[keyof typeof PlacementType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlacementType = {
  doubleMpu: 'doubleMpu',
  mpu: 'mpu',
  interstitial: 'interstitial',
  billboard: 'billboard',
} as const;

export interface PageMetadata {
  page: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
}

export interface PaginationWidgetRead {
  metadata: PageMetadata;
  records: WidgetRead[];
}

export interface PaginationUserInAccountRead {
  metadata: PageMetadata;
  records: UserInAccountRead[];
}

export interface PaginationTagSchema {
  metadata: PageMetadata;
  records: TagSchema[];
}

export interface PaginationBotListSingleRead {
  metadata: PageMetadata;
  records: BotListSingleRead[];
}

export interface PaginationBotEmbedRead {
  metadata: PageMetadata;
  records: BotEmbedRead[];
}

export interface PaginationAnalyticsWidgetRead {
  metadata: PageMetadata;
  records: AnalyticsWidgetRead[];
}

export interface PaginationAnalyticsRead {
  metadata: PageMetadata;
  records: AnalyticsRead[];
}

export interface PaginationAccountSimpleRead {
  metadata: PageMetadata;
  records: AccountSimpleRead[];
}

export interface PaginationAccountListRead {
  metadata: PageMetadata;
  records: AccountListRead[];
}

export type OrderDirectionType = typeof OrderDirectionType[keyof typeof OrderDirectionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderDirectionType = {
  asc: 'asc',
  desc: 'desc',
} as const;

export interface MediaUploadUrlRead {
  identifier: string;
  uploadUrl: string;
}

export type MediaUpdateName = string | null;

export interface MediaUpdate {
  name?: MediaUpdateName;
}

/**
 * Media type, either video or image or something else.
 */
export type MediaType = typeof MediaType[keyof typeof MediaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaType = {
  LogoImage: 'LogoImage',
  LogoVideo: 'LogoVideo',
  SlideVideo: 'SlideVideo',
  CoverImage: 'CoverImage',
} as const;

/**
 * Media status, ready or not.
 */
export type MediaStatusType = typeof MediaStatusType[keyof typeof MediaStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaStatusType = {
  Draft: 'Draft',
  Ready: 'Ready',
  Error: 'Error',
} as const;

export type MediaReadOutputThumbnailUrl = string | null;

export type MediaReadOutputPreviewUrl = string | null;

export type MediaReadOutputDuration = number | null;

export interface MediaReadOutput {
  accountId: number;
  captions: CaptionBase[];
  createdAt: string;
  duration?: MediaReadOutputDuration;
  id: number;
  identifier: string;
  isArchived: boolean;
  name: string;
  previewUrl?: MediaReadOutputPreviewUrl;
  status: MediaStatusType;
  thumbnailUrl?: MediaReadOutputThumbnailUrl;
  type: MediaType;
  url: string;
  userId: number;
}

export interface PaginationMediaRead {
  metadata: PageMetadata;
  records: MediaReadOutput[];
}

export type MediaReadInputThumbnailUrl = string | null;

export type MediaReadInputPreviewUrl = string | null;

export type MediaReadInputDuration = number | null;

export interface MediaReadInput {
  accountId: number;
  captions: CaptionBase[];
  createdAt: string;
  duration?: MediaReadInputDuration;
  id: number;
  identifier: string;
  isArchived: boolean;
  name: string;
  previewUrl?: MediaReadInputPreviewUrl;
  status: MediaStatusType;
  thumbnailUrl?: MediaReadInputThumbnailUrl;
  type: MediaType;
  url: string;
  userId: number;
}

export type MediaOrderBy = typeof MediaOrderBy[keyof typeof MediaOrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaOrderBy = {
  id: 'id',
  name: 'name',
  status: 'status',
} as const;

/**
 * Filter media library.
 */
export type MediaFilterType = typeof MediaFilterType[keyof typeof MediaFilterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaFilterType = {
  MyMedia: 'MyMedia',
  All: 'All',
} as const;

export type MediaCreateReadThumbnailUrl = string | null;

export type MediaCreateReadPreviewUrl = string | null;

export type MediaCreateReadDuration = number | null;

export interface MediaCreateRead {
  accountId: number;
  captions: CaptionBase[];
  createdAt: string;
  duration?: MediaCreateReadDuration;
  id: number;
  identifier: string;
  isArchived: boolean;
  name: string;
  previewUrl?: MediaCreateReadPreviewUrl;
  status: MediaStatusType;
  thumbnailUrl?: MediaCreateReadThumbnailUrl;
  type: MediaType;
  uploadUrl: MediaUploadUrlRead;
  url: string;
  userId: number;
}

export type MediaCreateDuration = number | null;

export interface MediaCreate {
  accountId: number;
  duration?: MediaCreateDuration;
  name: string;
  type: MediaType;
}

/**
 * Videobot's publishing status.
 */
export type MainBotStatusType = typeof MainBotStatusType[keyof typeof MainBotStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MainBotStatusType = {
  Published: 'Published',
  Draft: 'Draft',
  Deactivated: 'Deactivated',
} as const;

export type LinkType = typeof LinkType[keyof typeof LinkType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinkType = {
  new_tab: 'new_tab',
  same_tab: 'same_tab',
  embed: 'embed',
} as const;

export interface LinearAuth {
  apiKey: string;
}

/**
 * Supported languages.
 */
export type LanguageType = typeof LanguageType[keyof typeof LanguageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LanguageType = {
  en: 'en',
  fi: 'fi',
  nl: 'nl',
  fr: 'fr',
  de: 'de',
} as const;

export interface UserRead {
  email: string;
  firstName?: UserReadFirstName;
  id: number;
  isActive?: boolean;
  isCustomerSuccess: boolean;
  isReseller: boolean;
  isSuperuser?: boolean;
  isVerified?: boolean;
  language: LanguageType;
  lastName?: UserReadLastName;
  phoneNumber?: UserReadPhoneNumber;
  profilePicture?: UserReadProfilePicture;
  profilePictureId?: UserReadProfilePictureId;
}

export type IntegrationType = typeof IntegrationType[keyof typeof IntegrationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntegrationType = {
  hubspot: 'hubspot',
  linear: 'linear',
} as const;

export type IntegrationReadDetails = { [key: string]: any };

export interface IntegrationRead {
  details: IntegrationReadDetails;
  id: number;
  integrationType: IntegrationType;
}

export interface HubspotAuth {
  code: string;
}

export type HideModeType = typeof HideModeType[keyof typeof HideModeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HideModeType = {
  mobile: 'mobile',
  both: 'both',
  none: 'none',
} as const;

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export type GlobalConfigIntegrationEndpoint = {[key: string]: string};

export interface GlobalConfig {
  analyticsDisplayFields?: AnalyticsFieldType[];
  integrationEndpoint: GlobalConfigIntegrationEndpoint;
}

export type GAConsentMode = typeof GAConsentMode[keyof typeof GAConsentMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GAConsentMode = {
  granted: 'granted',
  denied: 'denied',
} as const;

/**
 * Bot form field type.
 */
export type FormFieldType = typeof FormFieldType[keyof typeof FormFieldType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormFieldType = {
  text: 'text',
  email: 'email',
  postal_code_fi: 'postal_code_fi',
  textarea: 'textarea',
  checkbox: 'checkbox',
  general_checkbox: 'general_checkbox',
} as const;

export interface FormField {
  label: string;
  name: string;
  required: boolean;
  type: FormFieldType;
}

export type FlagUpdateUserIds = number[] | null;

export type FlagUpdateIsSuperadmin = boolean | null;

export type FlagUpdateIsEveryone = boolean | null;

export type FlagUpdateAccountIds = number[] | null;

export type FlagType = typeof FlagType[keyof typeof FlagType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlagType = {
  embed: 'embed',
  dashboard: 'dashboard',
} as const;

export interface FlagUpdate {
  account_ids?: FlagUpdateAccountIds;
  flag_type: FlagType;
  is_everyone?: FlagUpdateIsEveryone;
  is_superadmin?: FlagUpdateIsSuperadmin;
  name: FeatureType;
  user_ids?: FlagUpdateUserIds;
}

export type FlagSchemaUserIds = number[] | null;

export type FlagSchemaLanguages = string[] | null;

export type FlagSchemaIsSuperadmin = boolean | null;

export type FlagSchemaIsEveryone = boolean | null;

export type FlagSchemaDescription = string | null;

export type FlagSchemaAccountIds = number[] | null;

export type FeatureType = typeof FeatureType[keyof typeof FeatureType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureType = {
  integration: 'integration',
  integration_hubspot: 'integration_hubspot',
  integration_linear: 'integration_linear',
  integration_pipedrive: 'integration_pipedrive',
  integration_salesforce: 'integration_salesforce',
  google_analytics_cookies: 'google_analytics_cookies',
  videobot_as_ads: 'videobot_as_ads',
  ruokaboksi: 'ruokaboksi',
  new_widgets: 'new_widgets',
  widget_sync: 'widget_sync',
  no_cookies: 'no_cookies',
  url_map: 'url_map',
  company_profile: 'company_profile',
  videobot_scheduler: 'videobot_scheduler',
  button_outline: 'button_outline',
  likes: 'likes',
  close_button: 'close_button',
  dvi_watermark: 'dvi_watermark',
  alkimi_watermark: 'alkimi_watermark',
  hide_widget_on_scroll: 'hide_widget_on_scroll',
} as const;

export interface FlagSchema {
  account_ids?: FlagSchemaAccountIds;
  description?: FlagSchemaDescription;
  flag_type: FlagType;
  is_everyone?: FlagSchemaIsEveryone;
  is_superadmin?: FlagSchemaIsSuperadmin;
  languages?: FlagSchemaLanguages;
  name: FeatureType;
  user_ids?: FlagSchemaUserIds;
}

export type ExternalLinkType = typeof ExternalLinkType[keyof typeof ExternalLinkType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalLinkType = {
  url: 'url',
  tel: 'tel',
  mailto: 'mailto',
  deep_link: 'deep_link',
  relative_path: 'relative_path',
} as const;

export type ExternalLinkExternalLinkType = ExternalLinkType | null;

export type ExternalLinkDetails = { [key: string]: any };

export interface ExternalLink {
  details?: ExternalLinkDetails;
  externalLinkType?: ExternalLinkExternalLinkType;
  name: string;
  style: ButtonStyle;
  target: LinkType;
  type: 'external_link';
  url: string;
}

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  widget_viewed: 'widget_viewed',
  iframe_viewed: 'iframe_viewed',
  opened: 'opened',
  session: 'session',
  watermark_clicked: 'watermark_clicked',
  played: 'played',
  watched: 'watched',
  cta: 'cta',
  contacted: 'contacted',
  engaged: 'engaged',
  profile_viewed: 'profile_viewed',
  liked: 'liked',
  action_clicked: 'action_clicked',
  consent_accepted: 'consent_accepted',
  consent_declined: 'consent_declined',
  clicktag_clicked: 'clicktag_clicked',
  slider_session: 'slider_session',
  slider_played: 'slider_played',
} as const;

export type EventChannelType = typeof EventChannelType[keyof typeof EventChannelType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventChannelType = {
  direct: 'direct',
  widget: 'widget',
  iframe: 'iframe',
  slider: 'slider',
} as const;

export type ContactFormOutputEmailBcc = string | null;

export type ContactFormOutputDetails = { [key: string]: any };

export type ContactFormInputEmailBcc = string | null;

export type ContactFormInputDetails = { [key: string]: any };

export interface ContactFormInput {
  buttonStyle: ButtonStyle;
  buttonText: string;
  description: string;
  details?: ContactFormInputDetails;
  emailBcc?: ContactFormInputEmailBcc;
  emailTo: string;
  fields: FormField[];
  name: string;
  subject: string;
  title: string;
  type: 'contact_form';
}

export type CaptionLanguageType = typeof CaptionLanguageType[keyof typeof CaptionLanguageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaptionLanguageType = {
  aa: 'aa',
  ab: 'ab',
  ae: 'ae',
  af: 'af',
  ak: 'ak',
  am: 'am',
  an: 'an',
  ar: 'ar',
  as: 'as',
  av: 'av',
  ay: 'ay',
  az: 'az',
  ba: 'ba',
  be: 'be',
  bg: 'bg',
  bh: 'bh',
  bi: 'bi',
  bm: 'bm',
  bn: 'bn',
  bo: 'bo',
  br: 'br',
  bs: 'bs',
  ca: 'ca',
  ce: 'ce',
  ch: 'ch',
  co: 'co',
  cr: 'cr',
  cs: 'cs',
  cu: 'cu',
  cv: 'cv',
  cy: 'cy',
  da: 'da',
  de: 'de',
  dv: 'dv',
  dz: 'dz',
  ee: 'ee',
  el: 'el',
  en: 'en',
  eo: 'eo',
  es: 'es',
  et: 'et',
  eu: 'eu',
  fa: 'fa',
  ff: 'ff',
  fi: 'fi',
  fj: 'fj',
  fo: 'fo',
  fr: 'fr',
  fy: 'fy',
  ga: 'ga',
  gd: 'gd',
  gl: 'gl',
  gn: 'gn',
  gu: 'gu',
  gv: 'gv',
  ha: 'ha',
  he: 'he',
  hi: 'hi',
  ho: 'ho',
  hr: 'hr',
  ht: 'ht',
  hu: 'hu',
  hy: 'hy',
  hz: 'hz',
  ia: 'ia',
  id: 'id',
  ie: 'ie',
  ig: 'ig',
  ii: 'ii',
  ik: 'ik',
  io: 'io',
  is: 'is',
  it: 'it',
  iu: 'iu',
  ja: 'ja',
  jv: 'jv',
  ka: 'ka',
  kg: 'kg',
  ki: 'ki',
  kj: 'kj',
  kk: 'kk',
  kl: 'kl',
  km: 'km',
  kn: 'kn',
  ko: 'ko',
  kr: 'kr',
  ks: 'ks',
  ku: 'ku',
  kv: 'kv',
  kw: 'kw',
  ky: 'ky',
  la: 'la',
  lb: 'lb',
  lg: 'lg',
  li: 'li',
  ln: 'ln',
  lo: 'lo',
  lt: 'lt',
  lu: 'lu',
  lv: 'lv',
  mg: 'mg',
  mh: 'mh',
  mi: 'mi',
  mk: 'mk',
  ml: 'ml',
  mn: 'mn',
  mr: 'mr',
  ms: 'ms',
  mt: 'mt',
  my: 'my',
  na: 'na',
  nb: 'nb',
  nd: 'nd',
  ne: 'ne',
  ng: 'ng',
  nl: 'nl',
  nn: 'nn',
  no: 'no',
  nr: 'nr',
  nv: 'nv',
  ny: 'ny',
  oc: 'oc',
  oj: 'oj',
  om: 'om',
  or: 'or',
  os: 'os',
  pa: 'pa',
  pi: 'pi',
  pl: 'pl',
  ps: 'ps',
  pt: 'pt',
  qu: 'qu',
  rm: 'rm',
  rn: 'rn',
  ro: 'ro',
  ru: 'ru',
  rw: 'rw',
  sa: 'sa',
  sc: 'sc',
  sd: 'sd',
  se: 'se',
  sg: 'sg',
  si: 'si',
  sk: 'sk',
  sl: 'sl',
  sm: 'sm',
  sn: 'sn',
  so: 'so',
  sq: 'sq',
  sr: 'sr',
  ss: 'ss',
  st: 'st',
  su: 'su',
  sv: 'sv',
  sw: 'sw',
  ta: 'ta',
  te: 'te',
  tg: 'tg',
  th: 'th',
  ti: 'ti',
  tk: 'tk',
  tl: 'tl',
  tn: 'tn',
  to: 'to',
  tr: 'tr',
  ts: 'ts',
  tt: 'tt',
  tw: 'tw',
  ty: 'ty',
  ug: 'ug',
  uk: 'uk',
  ur: 'ur',
  uz: 'uz',
  ve: 've',
  vi: 'vi',
  vo: 'vo',
  wa: 'wa',
  wo: 'wo',
  xh: 'xh',
  yi: 'yi',
  yo: 'yo',
  za: 'za',
  zh: 'zh',
  zu: 'zu',
} as const;

export interface CaptionBase {
  accountId: number;
  createdAt: string;
  id: number;
  languageCode: CaptionLanguageType;
  videoId: number;
}

export interface CFVideoAnalyticRead {
  identifier: string;
  name: string;
  views: number;
  watched: number;
}

export interface CFBotAnalyticRead {
  botId: string;
  videos: CFVideoAnalyticRead[];
}

export type ButtonStyle = typeof ButtonStyle[keyof typeof ButtonStyle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonStyle = {
  standard: 'standard',
  highlight: 'highlight',
} as const;

export interface ContactFormOutput {
  buttonStyle: ButtonStyle;
  buttonText: string;
  description: string;
  details?: ContactFormOutputDetails;
  emailBcc?: ContactFormOutputEmailBcc;
  emailTo: string;
  fields: FormField[];
  name: string;
  subject: string;
  title: string;
  type: 'contact_form';
}

export type BotWidgetUpdateResponseTheme = BotThemeOutput | null;

export type BotWidgetUpdateResponseHasTextTracks = boolean | null;

export type BotWidgetUpdateResponseActionsItem = BotFormUpdateOutput | BotExternalLinkUpdateOutput | BotPostMessageUpdateOutput | BotCloseVideobotUpdateOutput;

export interface BotWidgetUpdateResponse {
  actions: BotWidgetUpdateResponseActionsItem[];
  hasTextTracks?: BotWidgetUpdateResponseHasTextTracks;
  id: number;
  slides: BotSlideUpdateOutput[];
  theme?: BotWidgetUpdateResponseTheme;
}

export type BotWidgetUpdateTheme = BotThemeInput | null;

export type BotWidgetUpdateHasTextTracks = boolean | null;

export type BotWidgetUpdateActionsItem = BotFormUpdateInput | BotExternalLinkUpdateInput | BotPostMessageUpdateInput | BotCloseVideobotUpdateInput;

export interface BotWidgetUpdate {
  actions: BotWidgetUpdateActionsItem[];
  hasTextTracks?: BotWidgetUpdateHasTextTracks;
  slides: BotSlideUpdateInput[];
  theme?: BotWidgetUpdateTheme;
}

export type BotWidgetSettingsReadWidth = number | null;

export type BotWidgetSettingsReadVideobotScheduler = VideobotSchedulerSettings | null;

export type BotWidgetSettingsReadVerticalOffset = number | null;

export type BotWidgetSettingsReadThumbnail = BotThumbnailSettings | null;

export type BotWidgetSettingsReadTextDurationMs = number | null;

export type BotWidgetSettingsReadText = string | null;

export type BotWidgetSettingsReadOpenAfter = number | null;

export type BotWidgetSettingsReadLoopLimit = number | null;

export type BotWidgetSettingsReadLanguage = LanguageType | null;

export type BotWidgetSettingsReadHorizontalOffset = number | null;

export type BotWidgetSettingsReadHorizontalModeMobile = boolean | null;

export type BotWidgetSettingsReadHorizontalMode = boolean | null;

export type BotWidgetSettingsReadHideOnScroll = HideModeType | null;

export type BotWidgetSettingsReadHideCtaMobile = boolean | null;

export type BotWidgetSettingsReadHeight = number | null;

export type BotWidgetSettingsReadCtaTextColor = string | null;

export type BotWidgetSettingsReadCtaBackgroundColor = string | null;

export type BotWidgetSettingsReadCoverOffset = number | null;

export type BotWidgetSettingsReadChatVideoId = number | null;

export type BotWidgetSettingsReadChatVideo = MediaReadOutput | null;

export type BotWidgetSettingsReadBorderColor = string | null;

export type BotWidgetSettingsReadAutoplayMuted = boolean | null;

export type BotWidgetSettingsReadAutomaticallyOpen = boolean | null;

export type BotWidgetSettingsReadAlign = BotWidgetAlign | null;

export interface BotWidgetSettingsRead {
  align?: BotWidgetSettingsReadAlign;
  automaticallyOpen?: BotWidgetSettingsReadAutomaticallyOpen;
  autoplayMuted?: BotWidgetSettingsReadAutoplayMuted;
  borderColor?: BotWidgetSettingsReadBorderColor;
  chatVideo?: BotWidgetSettingsReadChatVideo;
  chatVideoId?: BotWidgetSettingsReadChatVideoId;
  closeButtonEnabled?: boolean;
  coverOffset?: BotWidgetSettingsReadCoverOffset;
  ctaBackgroundColor?: BotWidgetSettingsReadCtaBackgroundColor;
  ctaTextColor?: BotWidgetSettingsReadCtaTextColor;
  height?: BotWidgetSettingsReadHeight;
  hideCtaMobile?: BotWidgetSettingsReadHideCtaMobile;
  hideOnScroll?: BotWidgetSettingsReadHideOnScroll;
  horizontalMode?: BotWidgetSettingsReadHorizontalMode;
  horizontalModeMobile?: BotWidgetSettingsReadHorizontalModeMobile;
  horizontalOffset?: BotWidgetSettingsReadHorizontalOffset;
  language?: BotWidgetSettingsReadLanguage;
  loopLimit?: BotWidgetSettingsReadLoopLimit;
  openAfter?: BotWidgetSettingsReadOpenAfter;
  text?: BotWidgetSettingsReadText;
  textDurationMs?: BotWidgetSettingsReadTextDurationMs;
  thumbnail?: BotWidgetSettingsReadThumbnail;
  verticalOffset?: BotWidgetSettingsReadVerticalOffset;
  videobotScheduler?: BotWidgetSettingsReadVideobotScheduler;
  width?: BotWidgetSettingsReadWidth;
}

export type BotWidgetSettingsWidth = number | null;

export type BotWidgetSettingsVideobotScheduler = VideobotSchedulerSettings | null;

export type BotWidgetSettingsVerticalOffset = number | null;

export type BotWidgetSettingsThumbnail = BotThumbnailSettings | null;

export type BotWidgetSettingsTextDurationMs = number | null;

export type BotWidgetSettingsText = string | null;

export type BotWidgetSettingsOpenAfter = number | null;

export type BotWidgetSettingsLoopLimit = number | null;

export type BotWidgetSettingsLanguage = LanguageType | null;

export type BotWidgetSettingsHorizontalOffset = number | null;

export type BotWidgetSettingsHorizontalModeMobile = boolean | null;

export type BotWidgetSettingsHorizontalMode = boolean | null;

export type BotWidgetSettingsHideOnScroll = HideModeType | null;

export type BotWidgetSettingsHideCtaMobile = boolean | null;

export type BotWidgetSettingsHeight = number | null;

export type BotWidgetSettingsCtaTextColor = string | null;

export type BotWidgetSettingsCtaBackgroundColor = string | null;

export type BotWidgetSettingsCoverOffset = number | null;

export type BotWidgetSettingsChatVideoId = number | null;

export type BotWidgetSettingsBorderColor = string | null;

export type BotWidgetSettingsAutoplayMuted = boolean | null;

export type BotWidgetSettingsAutomaticallyOpen = boolean | null;

export type BotWidgetSettingsAlign = BotWidgetAlign | null;

export interface BotWidgetSettings {
  align?: BotWidgetSettingsAlign;
  automaticallyOpen?: BotWidgetSettingsAutomaticallyOpen;
  autoplayMuted?: BotWidgetSettingsAutoplayMuted;
  borderColor?: BotWidgetSettingsBorderColor;
  chatVideoId?: BotWidgetSettingsChatVideoId;
  closeButtonEnabled?: boolean;
  coverOffset?: BotWidgetSettingsCoverOffset;
  ctaBackgroundColor?: BotWidgetSettingsCtaBackgroundColor;
  ctaTextColor?: BotWidgetSettingsCtaTextColor;
  height?: BotWidgetSettingsHeight;
  hideCtaMobile?: BotWidgetSettingsHideCtaMobile;
  hideOnScroll?: BotWidgetSettingsHideOnScroll;
  horizontalMode?: BotWidgetSettingsHorizontalMode;
  horizontalModeMobile?: BotWidgetSettingsHorizontalModeMobile;
  horizontalOffset?: BotWidgetSettingsHorizontalOffset;
  language?: BotWidgetSettingsLanguage;
  loopLimit?: BotWidgetSettingsLoopLimit;
  openAfter?: BotWidgetSettingsOpenAfter;
  text?: BotWidgetSettingsText;
  textDurationMs?: BotWidgetSettingsTextDurationMs;
  thumbnail?: BotWidgetSettingsThumbnail;
  verticalOffset?: BotWidgetSettingsVerticalOffset;
  videobotScheduler?: BotWidgetSettingsVideobotScheduler;
  width?: BotWidgetSettingsWidth;
}

export type BotWidgetEmbedReadWidth = number | null;

export type BotWidgetEmbedReadVideobotScheduler = VideobotSchedulerSettings | null;

export type BotWidgetEmbedReadVerticalOffset = number | null;

export type BotWidgetEmbedReadThumbnail = BotThumbnailSettings | null;

export type BotWidgetEmbedReadTextDurationMs = number | null;

export type BotWidgetEmbedReadText = string | null;

export type BotWidgetEmbedReadOpenAfter = number | null;

export type BotWidgetEmbedReadLoopLimit = number | null;

export type BotWidgetEmbedReadLanguage = LanguageType | null;

export type BotWidgetEmbedReadHorizontalOffset = number | null;

export type BotWidgetEmbedReadHorizontalModeMobile = boolean | null;

export type BotWidgetEmbedReadHorizontalMode = boolean | null;

export type BotWidgetEmbedReadHideOnScroll = HideModeType | null;

export type BotWidgetEmbedReadHideCtaMobile = boolean | null;

export type BotWidgetEmbedReadHidden = boolean | null;

export type BotWidgetEmbedReadHeight = number | null;

export type BotWidgetEmbedReadFeatures = string[] | null;

export type BotWidgetEmbedReadCtaTextColor = string | null;

export type BotWidgetEmbedReadCtaBackgroundColor = string | null;

export type BotWidgetEmbedReadCoverOffset = number | null;

export type BotWidgetEmbedReadChatVideoId = number | null;

export type BotWidgetEmbedReadChatVideo = MediaReadOutput | null;

export type BotWidgetEmbedReadBorderColor = string | null;

export type BotWidgetEmbedReadAutoplayMuted = boolean | null;

export type BotWidgetEmbedReadAutomaticallyOpen = boolean | null;

export type BotWidgetEmbedReadAlign = BotWidgetAlign | null;

export interface BotWidgetEmbedRead {
  align?: BotWidgetEmbedReadAlign;
  automaticallyOpen?: BotWidgetEmbedReadAutomaticallyOpen;
  autoplayMuted?: BotWidgetEmbedReadAutoplayMuted;
  borderColor?: BotWidgetEmbedReadBorderColor;
  chatVideo?: BotWidgetEmbedReadChatVideo;
  chatVideoId?: BotWidgetEmbedReadChatVideoId;
  closeButtonEnabled?: boolean;
  coverOffset?: BotWidgetEmbedReadCoverOffset;
  ctaBackgroundColor?: BotWidgetEmbedReadCtaBackgroundColor;
  ctaTextColor?: BotWidgetEmbedReadCtaTextColor;
  features?: BotWidgetEmbedReadFeatures;
  height?: BotWidgetEmbedReadHeight;
  hidden?: BotWidgetEmbedReadHidden;
  hideCtaMobile?: BotWidgetEmbedReadHideCtaMobile;
  hideOnScroll?: BotWidgetEmbedReadHideOnScroll;
  horizontalMode?: BotWidgetEmbedReadHorizontalMode;
  horizontalModeMobile?: BotWidgetEmbedReadHorizontalModeMobile;
  horizontalOffset?: BotWidgetEmbedReadHorizontalOffset;
  id: string;
  language?: BotWidgetEmbedReadLanguage;
  loopLimit?: BotWidgetEmbedReadLoopLimit;
  openAfter?: BotWidgetEmbedReadOpenAfter;
  text?: BotWidgetEmbedReadText;
  textDurationMs?: BotWidgetEmbedReadTextDurationMs;
  thumbnail?: BotWidgetEmbedReadThumbnail;
  verticalOffset?: BotWidgetEmbedReadVerticalOffset;
  videobotScheduler?: BotWidgetEmbedReadVideobotScheduler;
  width?: BotWidgetEmbedReadWidth;
}

export type BotWidgetDashboardReadWidgetCover = MediaReadOutput | null;

export type BotWidgetDashboardReadThumbnailDescriptionColor = string | null;

export type BotWidgetDashboardReadThumbnailDescription = string | null;

export type BotWidgetDashboardReadThumbnailCover = MediaReadOutput | null;

export type BotWidgetDashboardReadTheme = BotThemeOutput | null;

export type BotWidgetDashboardReadLogo = MediaReadOutput | null;

export type BotWidgetDashboardReadIsLegacy = boolean | null;

export type BotWidgetDashboardReadHasTextTracks = boolean | null;

export type BotWidgetDashboardReadCompanyName = string | null;

export type BotWidgetDashboardReadActionsItem = BotExternalLinkEmbed | BotFormDashboard | BotPostMessageDashboard | BotCloseVideobotDashboard;

export interface BotWidgetDashboardRead {
  actions: BotWidgetDashboardReadActionsItem[];
  companyName?: BotWidgetDashboardReadCompanyName;
  hasTextTracks?: BotWidgetDashboardReadHasTextTracks;
  id: number;
  isLegacy?: BotWidgetDashboardReadIsLegacy;
  logo?: BotWidgetDashboardReadLogo;
  slides: BotSlideWidget[];
  theme?: BotWidgetDashboardReadTheme;
  thumbnailCover?: BotWidgetDashboardReadThumbnailCover;
  thumbnailDescription?: BotWidgetDashboardReadThumbnailDescription;
  thumbnailDescriptionColor?: BotWidgetDashboardReadThumbnailDescriptionColor;
  widgetCover?: BotWidgetDashboardReadWidgetCover;
}

export type BotWidgetAlign = typeof BotWidgetAlign[keyof typeof BotWidgetAlign];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BotWidgetAlign = {
  bottom_right: 'bottom_right',
  bottom_left: 'bottom_left',
  top_right: 'top_right',
  top_left: 'top_left',
} as const;

export type BotUpdateWidgetSettings = BotWidgetSettings | null;

export type BotUpdateWidgetCoverId = number | null;

export type BotUpdateUrlMapsSettings = UrlMapsSettings | null;

export type BotUpdateUrlMapsAnyOf = {[key: string]: string};

export type BotUpdateUrlMaps = BotUpdateUrlMapsAnyOf | null;

export type BotUpdateThumbnailDescriptionColor = string | null;

export type BotUpdateThumbnailDescription = string | null;

export type BotUpdateThumbnailCoverId = number | null;

export type BotUpdateTheme = BotThemeInput | null;

export type BotUpdateTagsList = TagSchema[] | null;

export type BotUpdateStatus = MainBotStatusType | null;

export type BotUpdateSeoMeta = BotSeoMeta | null;

export type BotUpdatePrivacy = PrivacyType | null;

export type BotUpdateName = string | null;

export type BotUpdateMetaTrackingSettings = BotMetaTrackingSettings | null;

export type BotUpdateLogoId = number | null;

export type BotUpdateLinkSettings = BotLinkSettings | null;

export type BotUpdateLanguage = CaptionLanguageType | null;

export type BotUpdateIsHorizontalCoverEnabled = boolean | null;

export type BotUpdateIsGoogleTrackingEnabled = boolean | null;

export type BotUpdateIsCompanyProfileEnabled = boolean | null;

export type BotUpdateIsCaptionEnabled = boolean | null;

export type BotUpdateIsAnalyticEnabled = boolean | null;

export type BotUpdateIframeSettings = BotIframeSettings | null;

export type BotUpdateGoogleTrackingSettings = BotGoogleTrackingSettings | null;

export type BotUpdateCompanyName = string | null;

export type BotUpdateAnalyticsIframeMessage = boolean | null;

export type BotUpdateAdSettings = BotAdSettings | null;

export interface BotUpdate {
  adSettings?: BotUpdateAdSettings;
  analyticsIframeMessage?: BotUpdateAnalyticsIframeMessage;
  companyName?: BotUpdateCompanyName;
  googleTrackingSettings?: BotUpdateGoogleTrackingSettings;
  iframeSettings?: BotUpdateIframeSettings;
  isAnalyticEnabled?: BotUpdateIsAnalyticEnabled;
  isCaptionEnabled?: BotUpdateIsCaptionEnabled;
  isCompanyProfileEnabled?: BotUpdateIsCompanyProfileEnabled;
  isGoogleTrackingEnabled?: BotUpdateIsGoogleTrackingEnabled;
  isHorizontalCoverEnabled?: BotUpdateIsHorizontalCoverEnabled;
  language?: BotUpdateLanguage;
  linkSettings?: BotUpdateLinkSettings;
  logoId?: BotUpdateLogoId;
  metaTrackingSettings?: BotUpdateMetaTrackingSettings;
  name?: BotUpdateName;
  privacy?: BotUpdatePrivacy;
  seoMeta?: BotUpdateSeoMeta;
  status?: BotUpdateStatus;
  tagsList?: BotUpdateTagsList;
  theme?: BotUpdateTheme;
  thumbnailCoverId?: BotUpdateThumbnailCoverId;
  thumbnailDescription?: BotUpdateThumbnailDescription;
  thumbnailDescriptionColor?: BotUpdateThumbnailDescriptionColor;
  urlMaps?: BotUpdateUrlMaps;
  urlMapsSettings?: BotUpdateUrlMapsSettings;
  widgetCoverId?: BotUpdateWidgetCoverId;
  widgetSettings?: BotUpdateWidgetSettings;
}

export type BotThumbnailSettingsTime = number | null;

export type BotThumbnailSettingsSlide = number | null;

export interface BotThumbnailSettings {
  slide?: BotThumbnailSettingsSlide;
  time?: BotThumbnailSettingsTime;
}

export type BotThemeOutputTextColor = string | null;

export type BotThemeOutputStylesRaw = string | null;

export type BotThemeOutputStylesAnyOf = {[key: string]: {[key: string]: string}};

export type BotThemeOutputStyles = BotThemeOutputStylesAnyOf | null;

export type BotThemeOutputProfileTextColor = string | null;

export type BotThemeOutputProfileLogo = MediaReadOutput | null;

export type BotThemeOutputProfileBackgroundColor = string | null;

export type BotThemeOutputIsGradientEnabled = boolean | null;

export type BotThemeOutputIsArrowButtonEnabled = boolean | null;

export type BotThemeOutputDelayButtons = number | null;

export type BotThemeOutputBackgroundColor = string | null;

export type BotThemeOutputBackButtonIntro = boolean | null;

export interface BotThemeOutput {
  backButtonIntro?: BotThemeOutputBackButtonIntro;
  backgroundColor?: BotThemeOutputBackgroundColor;
  delayButtons?: BotThemeOutputDelayButtons;
  isArrowButtonEnabled?: BotThemeOutputIsArrowButtonEnabled;
  isGradientEnabled?: BotThemeOutputIsGradientEnabled;
  profileBackgroundColor?: BotThemeOutputProfileBackgroundColor;
  profileLogo?: BotThemeOutputProfileLogo;
  profileTextColor?: BotThemeOutputProfileTextColor;
  styles?: BotThemeOutputStyles;
  stylesRaw?: BotThemeOutputStylesRaw;
  textColor?: BotThemeOutputTextColor;
}

export type BotThemeInputTextColor = string | null;

export type BotThemeInputStylesRaw = string | null;

export type BotThemeInputStylesAnyOf = {[key: string]: {[key: string]: string}};

export type BotThemeInputStyles = BotThemeInputStylesAnyOf | null;

export type BotThemeInputProfileTextColor = string | null;

export type BotThemeInputProfileLogo = MediaReadInput | null;

export type BotThemeInputProfileBackgroundColor = string | null;

export type BotThemeInputIsGradientEnabled = boolean | null;

export type BotThemeInputIsArrowButtonEnabled = boolean | null;

export type BotThemeInputDelayButtons = number | null;

export type BotThemeInputBackgroundColor = string | null;

export type BotThemeInputBackButtonIntro = boolean | null;

export interface BotThemeInput {
  backButtonIntro?: BotThemeInputBackButtonIntro;
  backgroundColor?: BotThemeInputBackgroundColor;
  delayButtons?: BotThemeInputDelayButtons;
  isArrowButtonEnabled?: BotThemeInputIsArrowButtonEnabled;
  isGradientEnabled?: BotThemeInputIsGradientEnabled;
  profileBackgroundColor?: BotThemeInputProfileBackgroundColor;
  profileLogo?: BotThemeInputProfileLogo;
  profileTextColor?: BotThemeInputProfileTextColor;
  styles?: BotThemeInputStyles;
  stylesRaw?: BotThemeInputStylesRaw;
  textColor?: BotThemeInputTextColor;
}

export type BotSubmitFormLegacySubject = string | null;

export type BotSubmitFormLegacySendBccTo = string | null;

export type BotSubmitFormLegacySelectedOptionsItem = {};

export type BotSubmitFormLegacyFieldsItem = {};

export interface BotSubmitFormLegacy {
  fields: BotSubmitFormLegacyFieldsItem[];
  selectedOptions: BotSubmitFormLegacySelectedOptionsItem[];
  sendBccTo?: BotSubmitFormLegacySendBccTo;
  sendTo: string;
  subject?: BotSubmitFormLegacySubject;
  testMode?: boolean;
}

export type BotSubmitFormSelectedOptionsItem = {};

export type BotSubmitFormFieldsItem = {};

export interface BotSubmitForm {
  fields: BotSubmitFormFieldsItem[];
  formId: number;
  selectedOptions: BotSubmitFormSelectedOptionsItem[];
  testMode?: boolean;
}

/**
 * Videobot's publishing status.
 */
export type BotStatusType = typeof BotStatusType[keyof typeof BotStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BotStatusType = {
  Published: 'Published',
  Draft: 'Draft',
  Deactivated: 'Deactivated',
} as const;

export type BotSlideWidgetVideoUrl = string | null;

export type BotSlideWidgetVideoId = number | null;

export type BotSlideWidgetTriggersAnyOf = {[key: string]: BotSlideTrigger};

export type BotSlideWidgetTriggers = BotSlideWidgetTriggersAnyOf | null;

export type BotSlideWidgetTextTracksUrl = string | null;

export type BotSlideWidgetPreviewUrl = string | null;

export type BotSlideWidgetPosterUrl = string | null;

export type BotSlideWidgetLanguage = CaptionLanguageType | null;

export type BotSlideWidgetFilename = string | null;

export type BotSlideWidgetDuration = number | null;

export interface BotSlideWidget {
  category: SlideCategoryType;
  duration?: BotSlideWidgetDuration;
  filename?: BotSlideWidgetFilename;
  id: number;
  language?: BotSlideWidgetLanguage;
  name: string;
  options: BotSlideOption[];
  posterUrl?: BotSlideWidgetPosterUrl;
  previewUrl?: BotSlideWidgetPreviewUrl;
  textTracksUrl?: BotSlideWidgetTextTracksUrl;
  triggers?: BotSlideWidgetTriggers;
  videoId?: BotSlideWidgetVideoId;
  videoUrl?: BotSlideWidgetVideoUrl;
}

export type BotSlideUpdateOutputVideoId = number | null;

export type BotSlideUpdateOutputTriggersAnyOf = {[key: string]: BotSlideTrigger};

export type BotSlideUpdateOutputTriggers = BotSlideUpdateOutputTriggersAnyOf | null;

export type BotSlideUpdateOutputPosterId = number | null;

export type BotSlideUpdateOutputLanguage = CaptionLanguageType | null;

export interface BotSlideUpdateOutput {
  category: SlideCategoryType;
  id: number;
  language?: BotSlideUpdateOutputLanguage;
  name: string;
  options: BotSlideOption[];
  posterId?: BotSlideUpdateOutputPosterId;
  triggers?: BotSlideUpdateOutputTriggers;
  videoId?: BotSlideUpdateOutputVideoId;
}

export type BotSlideUpdateInputVideoId = number | null;

export type BotSlideUpdateInputTriggersAnyOf = {[key: string]: BotSlideTrigger};

export type BotSlideUpdateInputTriggers = BotSlideUpdateInputTriggersAnyOf | null;

export type BotSlideUpdateInputPosterId = number | null;

export type BotSlideUpdateInputLanguage = CaptionLanguageType | null;

export interface BotSlideUpdateInput {
  category: SlideCategoryType;
  language?: BotSlideUpdateInputLanguage;
  local_id: number;
  name: string;
  options: BotSlideOption[];
  posterId?: BotSlideUpdateInputPosterId;
  triggers?: BotSlideUpdateInputTriggers;
  videoId?: BotSlideUpdateInputVideoId;
}

export interface BotSlideTrigger {
  target: string;
  targetid: number;
}

export type BotSlideOptionVideoEndListener = boolean | null;

export type BotSlideOptionVideoEndAutoplay = boolean | null;

export type BotSlideOptionTrackConversion = boolean | null;

export type BotSlideOptionTargetId = number | null;

export type BotSlideOptionFeatured = boolean | null;

export interface BotSlideOption {
  featured?: BotSlideOptionFeatured;
  id: number;
  name: string;
  targetId?: BotSlideOptionTargetId;
  trackConversion?: BotSlideOptionTrackConversion;
  type: SlideOptionType;
  videoEndAutoplay?: BotSlideOptionVideoEndAutoplay;
  videoEndListener?: BotSlideOptionVideoEndListener;
}

export type BotSeoMetaTitle = string | null;

export type BotSeoMetaDescription = string | null;

export interface BotSeoMeta {
  description?: BotSeoMetaDescription;
  title?: BotSeoMetaTitle;
}

export type BotReadWidgetSettings = BotWidgetSettingsRead | null;

export type BotReadWidgetCover = MediaReadOutput | null;

export type BotReadUrlMapsSettings = UrlMapsSettings | null;

export type BotReadUrlMapsAnyOf = {[key: string]: string};

export type BotReadUrlMaps = BotReadUrlMapsAnyOf | null;

export type BotReadThumbnailDescriptionColor = string | null;

export type BotReadThumbnailDescription = string | null;

export type BotReadThumbnailCover = MediaReadOutput | null;

export type BotReadTheme = BotThemeOutput | null;

export type BotReadTagsList = TagSchema[] | null;

export type BotReadSeoMeta = BotSeoMeta | null;

export type BotReadPublicId = string | null;

export type BotReadPosterUrl = string | null;

export type BotReadName = string | null;

export type BotReadMetaTrackingSettings = BotMetaTrackingSettings | null;

export type BotReadLogo = MediaReadOutput | null;

export type BotReadLinkSettings = BotLinkSettings | null;

export type BotReadIsHorizontalCoverEnabled = boolean | null;

export type BotReadIsGoogleTrackingEnabled = boolean | null;

export type BotReadIsCompanyProfileEnabled = boolean | null;

export type BotReadIsCaptionEnabled = boolean | null;

export type BotReadIsAnalyticEnabled = boolean | null;

export type BotReadIframeSettings = BotIframeSettings | null;

export type BotReadGoogleTrackingSettings = BotGoogleTrackingSettings | null;

export type BotReadCompanyName = string | null;

export type BotReadAnalyticsIframeMessage = boolean | null;

export type BotReadAdSettings = BotAdSettings | null;

export interface BotRead {
  accountId: number;
  adSettings?: BotReadAdSettings;
  analyticsIframeMessage?: BotReadAnalyticsIframeMessage;
  author: BotAuthorRead;
  companyName?: BotReadCompanyName;
  createdAt: string;
  googleTrackingSettings?: BotReadGoogleTrackingSettings;
  id: number;
  iframeSettings?: BotReadIframeSettings;
  isAnalyticEnabled?: BotReadIsAnalyticEnabled;
  isCaptionEnabled?: BotReadIsCaptionEnabled;
  isCompanyProfileEnabled?: BotReadIsCompanyProfileEnabled;
  isDeleted: boolean;
  isGoogleTrackingEnabled?: BotReadIsGoogleTrackingEnabled;
  isHorizontalCoverEnabled?: BotReadIsHorizontalCoverEnabled;
  language: CaptionLanguageType;
  linkSettings?: BotReadLinkSettings;
  logo?: BotReadLogo;
  metaTrackingSettings?: BotReadMetaTrackingSettings;
  name?: BotReadName;
  posterUrl?: BotReadPosterUrl;
  privacy: PrivacyType;
  publicId?: BotReadPublicId;
  seoMeta?: BotReadSeoMeta;
  status: BotStatusType;
  tagsList?: BotReadTagsList;
  theme?: BotReadTheme;
  thumbnailCover?: BotReadThumbnailCover;
  thumbnailDescription?: BotReadThumbnailDescription;
  thumbnailDescriptionColor?: BotReadThumbnailDescriptionColor;
  urlMaps?: BotReadUrlMaps;
  urlMapsSettings?: BotReadUrlMapsSettings;
  widgetCover?: BotReadWidgetCover;
  widgetSettings?: BotReadWidgetSettings;
}

export type BotPostMessageUpdateOutputValue = string | null;

export interface BotPostMessageUpdateOutput {
  id: number;
  name: string;
  type: 'POST_MESSAGE';
  value?: BotPostMessageUpdateOutputValue;
}

export type BotPostMessageUpdateInputValue = string | null;

export interface BotPostMessageUpdateInput {
  local_id: number;
  name: string;
  type: 'POST_MESSAGE';
  value?: BotPostMessageUpdateInputValue;
}

export type BotPostMessageEmbedValue = string | null;

export interface BotPostMessageEmbed {
  id: number;
  name: string;
  type: 'POST_MESSAGE';
  value?: BotPostMessageEmbedValue;
}

export type BotPostMessageDashboardValue = string | null;

export interface BotPostMessageDashboard {
  id: number;
  name: string;
  type: 'POST_MESSAGE';
  value?: BotPostMessageDashboardValue;
}

export type BotOrderBy = typeof BotOrderBy[keyof typeof BotOrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BotOrderBy = {
  id: 'id',
  name: 'name',
  author_id: 'author_id',
  privacy: 'privacy',
} as const;

export type BotMetaTrackingSettingsPixelId = string | null;

export interface BotMetaTrackingSettings {
  enabled?: boolean;
  pixelId?: BotMetaTrackingSettingsPixelId;
}

export type BotListSingleReadWidgetSettings = BotWidgetSettingsRead | null;

export type BotListSingleReadWidgetCover = MediaReadOutput | null;

export type BotListSingleReadUrlMapsSettings = UrlMapsSettings | null;

export type BotListSingleReadUrlMapsAnyOf = {[key: string]: string};

export type BotListSingleReadUrlMaps = BotListSingleReadUrlMapsAnyOf | null;

export type BotListSingleReadThumbnailDescriptionColor = string | null;

export type BotListSingleReadThumbnailDescription = string | null;

export type BotListSingleReadThumbnailCover = MediaReadOutput | null;

export type BotListSingleReadTheme = BotThemeOutput | null;

export type BotListSingleReadTagsList = TagSchema[] | null;

export type BotListSingleReadSeoMeta = BotSeoMeta | null;

export type BotListSingleReadPublicId = string | null;

export type BotListSingleReadPosterUrl = string | null;

export type BotListSingleReadName = string | null;

export type BotListSingleReadMetaTrackingSettings = BotMetaTrackingSettings | null;

export type BotListSingleReadLogo = MediaReadOutput | null;

export type BotListSingleReadLinkSettings = BotLinkSettings | null;

export type BotListSingleReadIsHorizontalCoverEnabled = boolean | null;

export type BotListSingleReadIsGoogleTrackingEnabled = boolean | null;

export type BotListSingleReadIsCompanyProfileEnabled = boolean | null;

export type BotListSingleReadIsCaptionEnabled = boolean | null;

export type BotListSingleReadIsAnalyticEnabled = boolean | null;

export type BotListSingleReadIframeSettings = BotIframeSettings | null;

export type BotListSingleReadGoogleTrackingSettings = BotGoogleTrackingSettings | null;

export type BotListSingleReadCompanyName = string | null;

export type BotListSingleReadAnalyticsIframeMessage = boolean | null;

export type BotListSingleReadAdSettings = BotAdSettings | null;

export interface BotListSingleRead {
  accountId: number;
  adSettings?: BotListSingleReadAdSettings;
  analyticsIframeMessage?: BotListSingleReadAnalyticsIframeMessage;
  author: BotAuthorRead;
  companyName?: BotListSingleReadCompanyName;
  createdAt: string;
  googleTrackingSettings?: BotListSingleReadGoogleTrackingSettings;
  id: number;
  iframeSettings?: BotListSingleReadIframeSettings;
  isAnalyticEnabled?: BotListSingleReadIsAnalyticEnabled;
  isCaptionEnabled?: BotListSingleReadIsCaptionEnabled;
  isCompanyProfileEnabled?: BotListSingleReadIsCompanyProfileEnabled;
  isDeleted: boolean;
  isGoogleTrackingEnabled?: BotListSingleReadIsGoogleTrackingEnabled;
  isHorizontalCoverEnabled?: BotListSingleReadIsHorizontalCoverEnabled;
  language: CaptionLanguageType;
  linkSettings?: BotListSingleReadLinkSettings;
  logo?: BotListSingleReadLogo;
  metaTrackingSettings?: BotListSingleReadMetaTrackingSettings;
  name?: BotListSingleReadName;
  posterUrl?: BotListSingleReadPosterUrl;
  privacy: PrivacyType;
  publicId?: BotListSingleReadPublicId;
  seoMeta?: BotListSingleReadSeoMeta;
  slideCount: number;
  status: BotStatusType;
  tagsList?: BotListSingleReadTagsList;
  theme?: BotListSingleReadTheme;
  thumbnailCover?: BotListSingleReadThumbnailCover;
  thumbnailDescription?: BotListSingleReadThumbnailDescription;
  thumbnailDescriptionColor?: BotListSingleReadThumbnailDescriptionColor;
  urlMaps?: BotListSingleReadUrlMaps;
  urlMapsSettings?: BotListSingleReadUrlMapsSettings;
  widgetCover?: BotListSingleReadWidgetCover;
  widgetSettings?: BotListSingleReadWidgetSettings;
}

export type BotLinkSettingsThumbnail = BotThumbnailSettings | null;

export type BotLinkSettingsSlideId = number | null;

export type BotLinkSettingsLanguage = LanguageType | null;

export type BotLinkSettingsAutoplayMuted = boolean | null;

export interface BotLinkSettings {
  autoplayMuted?: BotLinkSettingsAutoplayMuted;
  language?: BotLinkSettingsLanguage;
  slideId?: BotLinkSettingsSlideId;
  thumbnail?: BotLinkSettingsThumbnail;
}

export type BotImportReadTheme = BotThemeOutput | null;

export type BotImportReadHasTextTracks = boolean | null;

export type BotImportReadActionsItem = BotFormUpdateOutput | BotExternalLinkUpdateOutput | BotPostMessageUpdateOutput | BotCloseVideobotUpdateOutput;

export interface BotImportRead {
  accountId: number;
  actions: BotImportReadActionsItem[];
  hasTextTracks?: BotImportReadHasTextTracks;
  id: number;
  slides: BotSlideUpdateOutput[];
  theme?: BotImportReadTheme;
}

export type BotImportParamsWidgetSettings = BotWidgetSettings | null;

export type BotImportParamsChatVideoUrl = string | null;

export type BotImportParamsAccountName = string | null;

export type BotImportParamsAccountId = number | null;

export interface BotImportParams {
  accountId?: BotImportParamsAccountId;
  accountName?: BotImportParamsAccountName;
  chatVideoUrl?: BotImportParamsChatVideoUrl;
  legacyBotId: string;
  widgetSettings?: BotImportParamsWidgetSettings;
}

export type BotIframeSettingsWidth = number | null;

export type BotIframeSettingsThumbnail = BotThumbnailSettings | null;

export type BotIframeSettingsLanguage = LanguageType | null;

export type BotIframeSettingsHeight = number | null;

export type BotIframeSettingsAutoplayOnScroll = boolean | null;

export interface BotIframeSettings {
  autoplayOnScroll?: BotIframeSettingsAutoplayOnScroll;
  height?: BotIframeSettingsHeight;
  language?: BotIframeSettingsLanguage;
  thumbnail?: BotIframeSettingsThumbnail;
  width?: BotIframeSettingsWidth;
}

export type BotGoogleTrackingSettingsSecurityStorage = GAConsentMode | null;

export type BotGoogleTrackingSettingsPersonalizationStorage = GAConsentMode | null;

export type BotGoogleTrackingSettingsGtmId = string | null;

export type BotGoogleTrackingSettingsGa4Id = string | null;

export type BotGoogleTrackingSettingsFunctionalityStorage = GAConsentMode | null;

export type BotGoogleTrackingSettingsAnalyticsStorage = GAConsentMode | null;

export type BotGoogleTrackingSettingsAdStorage = GAConsentMode | null;

export interface BotGoogleTrackingSettings {
  adStorage?: BotGoogleTrackingSettingsAdStorage;
  analyticsStorage?: BotGoogleTrackingSettingsAnalyticsStorage;
  functionalityStorage?: BotGoogleTrackingSettingsFunctionalityStorage;
  ga4Id?: BotGoogleTrackingSettingsGa4Id;
  gtmId?: BotGoogleTrackingSettingsGtmId;
  personalizationStorage?: BotGoogleTrackingSettingsPersonalizationStorage;
  securityStorage?: BotGoogleTrackingSettingsSecurityStorage;
}

export type BotFormUpdateOutputTitle = string | null;

export type BotFormUpdateOutputThumbnailId = number | null;

export type BotFormUpdateOutputSubject = string | null;

export type BotFormUpdateOutputSalesforce = BotFormSalesforce | null;

export type BotFormUpdateOutputPipedrive = BotFormPipedrive | null;

export type BotFormUpdateOutputLinear = BotFormLinear | null;

export type BotFormUpdateOutputHubspot = BotFormHubspot | null;

export type BotFormUpdateOutputEmailBcc = string | null;

export type BotFormUpdateOutputEmail = string | null;

export type BotFormUpdateOutputDescription = string | null;

export type BotFormUpdateOutputCustomPrivacyPolicy = BotFormCustomPrivacyPolicy | null;

export interface BotFormUpdateOutput {
  customPrivacyPolicy?: BotFormUpdateOutputCustomPrivacyPolicy;
  description?: BotFormUpdateOutputDescription;
  email?: BotFormUpdateOutputEmail;
  emailBcc?: BotFormUpdateOutputEmailBcc;
  fields: BotFormField[];
  hubspot?: BotFormUpdateOutputHubspot;
  id: number;
  linear?: BotFormUpdateOutputLinear;
  name: string;
  pipedrive?: BotFormUpdateOutputPipedrive;
  salesforce?: BotFormUpdateOutputSalesforce;
  subject?: BotFormUpdateOutputSubject;
  thumbnailId?: BotFormUpdateOutputThumbnailId;
  title?: BotFormUpdateOutputTitle;
  type: 'REGULAR_FORM';
}

export type BotFormUpdateInputTitle = string | null;

export type BotFormUpdateInputThumbnailId = number | null;

export type BotFormUpdateInputSubject = string | null;

export type BotFormUpdateInputSalesforce = BotFormSalesforce | null;

export type BotFormUpdateInputPipedrive = BotFormPipedrive | null;

export type BotFormUpdateInputLinear = BotFormLinear | null;

export type BotFormUpdateInputHubspot = BotFormHubspot | null;

export type BotFormUpdateInputEmailBcc = string | null;

export type BotFormUpdateInputEmail = string | null;

export type BotFormUpdateInputDescription = string | null;

export type BotFormUpdateInputCustomPrivacyPolicy = BotFormCustomPrivacyPolicy | null;

export interface BotFormUpdateInput {
  customPrivacyPolicy?: BotFormUpdateInputCustomPrivacyPolicy;
  description?: BotFormUpdateInputDescription;
  email?: BotFormUpdateInputEmail;
  emailBcc?: BotFormUpdateInputEmailBcc;
  fields: BotFormField[];
  hubspot?: BotFormUpdateInputHubspot;
  linear?: BotFormUpdateInputLinear;
  local_id: number;
  name: string;
  pipedrive?: BotFormUpdateInputPipedrive;
  salesforce?: BotFormUpdateInputSalesforce;
  subject?: BotFormUpdateInputSubject;
  thumbnailId?: BotFormUpdateInputThumbnailId;
  title?: BotFormUpdateInputTitle;
  type: 'REGULAR_FORM';
}

export type BotFormSalesforceLeadSource = string | null;

export interface BotFormSalesforce {
  enabled?: boolean;
  leadSource?: BotFormSalesforceLeadSource;
  status: string;
}

export type BotFormPipedrivePersonId = number | null;

export interface BotFormPipedrive {
  enabled?: boolean;
  personId?: BotFormPipedrivePersonId;
  titleValue: string;
}

export interface BotFormLinear {
  enabled?: boolean;
}

export type BotFormHubspotCampaignId = string | null;

export interface BotFormHubspot {
  campaignId?: BotFormHubspotCampaignId;
  enabled?: boolean;
}

export interface BotFormField {
  id: number;
  label: string;
  name: string;
  required: boolean;
  type: FormFieldType;
}

export type BotFormEmbedTitle = string | null;

export type BotFormEmbedThumbnailUrl = string | null;

export type BotFormEmbedSubject = string | null;

export type BotFormEmbedEmailBcc = string | null;

export type BotFormEmbedEmail = string | null;

export type BotFormEmbedDescription = string | null;

export type BotFormEmbedCustomPrivacyPolicy = BotFormCustomPrivacyPolicy | null;

export interface BotFormEmbed {
  customPrivacyPolicy?: BotFormEmbedCustomPrivacyPolicy;
  description?: BotFormEmbedDescription;
  email?: BotFormEmbedEmail;
  emailBcc?: BotFormEmbedEmailBcc;
  fields: BotFormField[];
  id: number;
  name: string;
  subject?: BotFormEmbedSubject;
  thumbnailUrl?: BotFormEmbedThumbnailUrl;
  title?: BotFormEmbedTitle;
  type: 'REGULAR_FORM';
}

export type BotFormDashboardTitle = string | null;

export type BotFormDashboardThumbnailUrl = string | null;

export type BotFormDashboardSubject = string | null;

export type BotFormDashboardSalesforce = BotFormSalesforce | null;

export type BotFormDashboardPipedrive = BotFormPipedrive | null;

export type BotFormDashboardLinear = BotFormLinear | null;

export type BotFormDashboardHubspot = BotFormHubspot | null;

export type BotFormDashboardEmailBcc = string | null;

export type BotFormDashboardEmail = string | null;

export type BotFormDashboardDescription = string | null;

export interface BotFormDashboard {
  customPrivacyPolicy?: BotFormDashboardCustomPrivacyPolicy;
  description?: BotFormDashboardDescription;
  email?: BotFormDashboardEmail;
  emailBcc?: BotFormDashboardEmailBcc;
  fields: BotFormField[];
  hubspot?: BotFormDashboardHubspot;
  id: number;
  linear?: BotFormDashboardLinear;
  name: string;
  pipedrive?: BotFormDashboardPipedrive;
  salesforce?: BotFormDashboardSalesforce;
  subject?: BotFormDashboardSubject;
  thumbnailUrl?: BotFormDashboardThumbnailUrl;
  title?: BotFormDashboardTitle;
  type: 'REGULAR_FORM';
}

export type BotFormCustomPrivacyPolicyUrl = string | null;

export interface BotFormCustomPrivacyPolicy {
  enabled?: boolean;
  url?: BotFormCustomPrivacyPolicyUrl;
}

export type BotFormDashboardCustomPrivacyPolicy = BotFormCustomPrivacyPolicy | null;

export type BotExternalLinkUpdateOutputUrl = string | string | string | string | string;

export type BotExternalLinkUpdateOutputSameTab = boolean | null;

export type BotExternalLinkUpdateOutputNewTab = boolean | null;

export type BotExternalLinkUpdateOutputLinkType = LinkType | null;

export type BotExternalLinkUpdateOutputExternalLinkType = ExternalLinkType | null;

export type BotExternalLinkUpdateOutputEmbed = boolean | null;

export interface BotExternalLinkUpdateOutput {
  embed?: BotExternalLinkUpdateOutputEmbed;
  externalLinkType?: BotExternalLinkUpdateOutputExternalLinkType;
  id: number;
  linkType?: BotExternalLinkUpdateOutputLinkType;
  name: string;
  newTab?: BotExternalLinkUpdateOutputNewTab;
  sameTab?: BotExternalLinkUpdateOutputSameTab;
  type: 'EXTERNAL_LINK';
  url: BotExternalLinkUpdateOutputUrl;
}

export type BotExternalLinkUpdateInputUrl = string | string | string | string | string;

export type BotExternalLinkUpdateInputSameTab = boolean | null;

export type BotExternalLinkUpdateInputNewTab = boolean | null;

export type BotExternalLinkUpdateInputLinkType = LinkType | null;

export type BotExternalLinkUpdateInputExternalLinkType = ExternalLinkType | null;

export type BotExternalLinkUpdateInputEmbed = boolean | null;

export interface BotExternalLinkUpdateInput {
  embed?: BotExternalLinkUpdateInputEmbed;
  externalLinkType?: BotExternalLinkUpdateInputExternalLinkType;
  linkType?: BotExternalLinkUpdateInputLinkType;
  local_id: number;
  name: string;
  newTab?: BotExternalLinkUpdateInputNewTab;
  sameTab?: BotExternalLinkUpdateInputSameTab;
  type: 'EXTERNAL_LINK';
  url: BotExternalLinkUpdateInputUrl;
}

export type BotExternalLinkEmbedSameTab = boolean | null;

export type BotExternalLinkEmbedNewTab = boolean | null;

export type BotExternalLinkEmbedLinkType = LinkType | null;

export type BotExternalLinkEmbedExternalLinkType = ExternalLinkType | null;

export type BotExternalLinkEmbedEmbed = boolean | null;

export interface BotExternalLinkEmbed {
  embed?: BotExternalLinkEmbedEmbed;
  externalLinkType?: BotExternalLinkEmbedExternalLinkType;
  id: number;
  linkType?: BotExternalLinkEmbedLinkType;
  name: string;
  newTab?: BotExternalLinkEmbedNewTab;
  sameTab?: BotExternalLinkEmbedSameTab;
  type: 'EXTERNAL_LINK';
  url: string;
}

export type BotEmbedReadWidgetCover = MediaReadOutput | null;

export type BotEmbedReadThumbnailDescriptionColor = string | null;

export type BotEmbedReadThumbnailDescription = string | null;

export type BotEmbedReadThumbnailCover = MediaReadOutput | null;

export type BotEmbedReadTheme = BotThemeOutput | null;

export type BotEmbedReadSeoMeta = BotSeoMeta | null;

export type BotEmbedReadName = string | null;

export type BotEmbedReadMetaTrackingSettings = BotMetaTrackingSettings | null;

export type BotEmbedReadMetaPixelId = string | null;

export type BotEmbedReadLogo = MediaReadOutput | null;

export type BotEmbedReadLinkSettings = BotLinkSettings | null;

export type BotEmbedReadIsLegacy = boolean | null;

export type BotEmbedReadIsGoogleTrackingEnabled = boolean | null;

export type BotEmbedReadIsCompanyProfileEnabled = boolean | null;

export type BotEmbedReadIsAnalyticEnabled = boolean | null;

export type BotEmbedReadIframeSettings = BotIframeSettings | null;

export type BotEmbedReadHasTextTracks = boolean | null;

export type BotEmbedReadGtmId = string | null;

export type BotEmbedReadGoogleTrackingSettings = BotGoogleTrackingSettings | null;

export type BotEmbedReadGa4Id = string | null;

export type BotEmbedReadFeatures = string[] | null;

export interface BotEmbedRead {
  accountOptions?: BotEmbedReadAccountOptions;
  actions: BotEmbedReadActionsItem[];
  adSettings?: BotEmbedReadAdSettings;
  analyticsIframeMessage?: BotEmbedReadAnalyticsIframeMessage;
  companyName?: BotEmbedReadCompanyName;
  companyProfile: BotEmbedReadCompanyProfile;
  extraFeatures?: BotEmbedReadExtraFeatures;
  features?: BotEmbedReadFeatures;
  ga4Id?: BotEmbedReadGa4Id;
  googleTrackingSettings?: BotEmbedReadGoogleTrackingSettings;
  gtmId?: BotEmbedReadGtmId;
  hasTextTracks?: BotEmbedReadHasTextTracks;
  id: string;
  iframeSettings?: BotEmbedReadIframeSettings;
  isAnalyticEnabled?: BotEmbedReadIsAnalyticEnabled;
  isCompanyProfileEnabled?: BotEmbedReadIsCompanyProfileEnabled;
  isGoogleTrackingEnabled?: BotEmbedReadIsGoogleTrackingEnabled;
  isLegacy?: BotEmbedReadIsLegacy;
  linkSettings?: BotEmbedReadLinkSettings;
  logo?: BotEmbedReadLogo;
  metaPixelId?: BotEmbedReadMetaPixelId;
  metaTrackingSettings?: BotEmbedReadMetaTrackingSettings;
  name?: BotEmbedReadName;
  seoMeta?: BotEmbedReadSeoMeta;
  slides: BotSlideWidget[];
  theme?: BotEmbedReadTheme;
  thumbnailCover?: BotEmbedReadThumbnailCover;
  thumbnailDescription?: BotEmbedReadThumbnailDescription;
  thumbnailDescriptionColor?: BotEmbedReadThumbnailDescriptionColor;
  widgetCover?: BotEmbedReadWidgetCover;
}

export type BotEmbedReadExtraFeaturesAnyOf = {[key: string]: boolean};

export type BotEmbedReadExtraFeatures = BotEmbedReadExtraFeaturesAnyOf | null;

export type BotEmbedReadCompanyProfile = ProfileDetailsRead | null;

export type BotEmbedReadCompanyName = string | null;

export type BotEmbedReadAnalyticsIframeMessage = boolean | null;

export type BotEmbedReadAdSettings = BotAdSettings | null;

export type BotEmbedReadActionsItem = BotExternalLinkEmbed | BotFormEmbed | BotPostMessageEmbed | BotCloseVideobotEmbed;

export type BotEmbedReadAccountOptions = AccountOptions | null;

export type BotCreateCompanyName = string | null;

export interface BotCreate {
  accountId: number;
  companyName?: BotCreateCompanyName;
  language?: string;
  name: string;
  privacy?: string;
  status?: string;
}

export type BotCloseVideobotUpdateOutputValue = string | null;

export interface BotCloseVideobotUpdateOutput {
  id: number;
  name: string;
  type: 'CLOSE_VIDEOBOT';
  value?: BotCloseVideobotUpdateOutputValue;
}

export type BotCloseVideobotUpdateInputValue = string | null;

export interface BotCloseVideobotUpdateInput {
  local_id: number;
  name: string;
  type: 'CLOSE_VIDEOBOT';
  value?: BotCloseVideobotUpdateInputValue;
}

export type BotCloseVideobotEmbedValue = string | null;

export interface BotCloseVideobotEmbed {
  id: number;
  name: string;
  type: 'CLOSE_VIDEOBOT';
  value?: BotCloseVideobotEmbedValue;
}

export type BotCloseVideobotDashboardValue = string | null;

export interface BotCloseVideobotDashboard {
  id: number;
  name: string;
  type: 'CLOSE_VIDEOBOT';
  value?: BotCloseVideobotDashboardValue;
}

export type BotAuthorReadLastName = string | null;

export type BotAuthorReadFirstName = string | null;

export interface BotAuthorRead {
  email: string;
  firstName?: BotAuthorReadFirstName;
  id: number;
  lastName?: BotAuthorReadLastName;
}

export type BotAdSettingsThumbnail = BotThumbnailSettings | null;

export type BotAdSettingsPlacement = PlacementType | null;

export type BotAdSettingsLanguage = LanguageType | null;

export type BotAdSettingsImpressionTag = string | null;

export type BotAdSettingsHorizontalDesktopLayout = boolean | null;

export type BotAdSettingsClickTag = string | null;

export type BotAdSettingsAutoplayOnScroll = boolean | null;

export interface BotAdSettings {
  autoplayOnScroll?: BotAdSettingsAutoplayOnScroll;
  clickTag?: BotAdSettingsClickTag;
  horizontalDesktopLayout?: BotAdSettingsHorizontalDesktopLayout;
  impressionTag?: BotAdSettingsImpressionTag;
  language?: BotAdSettingsLanguage;
  placement?: BotAdSettingsPlacement;
  thumbnail?: BotAdSettingsThumbnail;
}

export interface BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut {
  file: Blob;
}

export type AppearanceUpdateStylesRaw = string | null;

export type AppearanceUpdateStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AppearanceUpdateStyles = AppearanceUpdateStylesAnyOf | null;

export type AppearanceUpdateLogoId = number | null;

export type AppearanceUpdateChatVideoId = number | null;

export type AppearanceUpdateButtonTextColor = string | null;

export type AppearanceUpdateButtonBackgroundColor = string | null;

export interface AppearanceUpdate {
  buttonBackgroundColor?: AppearanceUpdateButtonBackgroundColor;
  buttonTextColor?: AppearanceUpdateButtonTextColor;
  chatVideoId?: AppearanceUpdateChatVideoId;
  logoId?: AppearanceUpdateLogoId;
  styles?: AppearanceUpdateStyles;
  stylesRaw?: AppearanceUpdateStylesRaw;
}

export type AppearanceReadStylesRaw = string | null;

export type AppearanceReadStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AppearanceReadStyles = AppearanceReadStylesAnyOf | null;

export type AppearanceReadLogo = MediaReadOutput | null;

export type AppearanceReadChatVideo = MediaReadOutput | null;

export type AppearanceReadButtonTextColor = string | null;

export type AppearanceReadButtonBackgroundColor = string | null;

export interface AppearanceRead {
  buttonBackgroundColor?: AppearanceReadButtonBackgroundColor;
  buttonTextColor?: AppearanceReadButtonTextColor;
  chatVideo?: AppearanceReadChatVideo;
  logo?: AppearanceReadLogo;
  styles?: AppearanceReadStyles;
  stylesRaw?: AppearanceReadStylesRaw;
}

export interface AnalyticsRead {
  actionClicked: number;
  bot: BotRead;
  clicktagClicked?: number;
  consentAccepted?: number;
  consentDeclined?: number;
  contacted: number;
  cta: number;
  directIntroViewed: number;
  engaged?: number;
  id: number;
  iframeIntroViewed: number;
  iframeViewed: number;
  introActions: number;
  liked?: number;
  mostViewed: number;
  opened: number;
  played: number;
  profileViewed?: number;
  session: number;
  sliderIntroViewed?: number;
  sliderPlayed?: number;
  sliderSession?: number;
  watched: number;
  watermarkClicked: number;
  widgetIntroViewed: number;
  widgetViewed: number;
}

export interface AnalyticsWidgetRead {
  actionClicked: number;
  botAnalytics?: AnalyticsRead[];
  clicktagClicked?: number;
  consentAccepted?: number;
  consentDeclined?: number;
  contacted: number;
  cta: number;
  directIntroViewed: number;
  engaged?: number;
  id: number;
  iframeIntroViewed: number;
  iframeViewed: number;
  introActions: number;
  liked?: number;
  mostViewed: number;
  opened: number;
  played: number;
  profileViewed?: number;
  session: number;
  slider: WidgetRead;
  sliderIntroViewed?: number;
  sliderPlayed?: number;
  sliderSession?: number;
  watched: number;
  watermarkClicked: number;
  widgetIntroViewed: number;
  widgetViewed: number;
}

export type AnalyticsFieldType = typeof AnalyticsFieldType[keyof typeof AnalyticsFieldType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalyticsFieldType = {
  email: 'email',
  cta: 'cta',
  watermark_clicked: 'watermark_clicked',
} as const;

export interface AnalyticsAccountTotalRead {
  accountId: number;
  actionClicked: number;
  clicktagClicked?: number;
  consentAccepted?: number;
  consentDeclined?: number;
  contacted: number;
  cta: number;
  directIntroViewed: number;
  engaged?: number;
  iframeIntroViewed: number;
  iframeViewed: number;
  introActions: number;
  liked?: number;
  mostViewed: number;
  opened: number;
  played: number;
  session: number;
  sliderIntroViewed?: number;
  sliderPlayed?: number;
  sliderSession?: number;
  watched: number;
  watermarkClicked: number;
  widgetIntroViewed: number;
  widgetViewed: number;
}

export interface AnalyticsDailyTotalRead {
  analytics: AnalyticsAccountTotalRead;
  day: string;
}

export interface AnalyticSourceRead {
  opened: number;
  source: string;
}

export interface AnalyticSlideRead {
  id: number;
  name: string;
  played: number;
}

export interface AnalyticReportBotRead {
  actionClicked: number;
  actions: AnalyticActionRead[];
  bot: BotRead;
  clicktagClicked?: number;
  consentAccepted?: number;
  consentDeclined?: number;
  contacted: number;
  cta: number;
  directIntroViewed: number;
  engaged?: number;
  id: number;
  iframeIntroViewed: number;
  iframeViewed: number;
  introActions: number;
  liked?: number;
  mostViewed: number;
  opened: number;
  played: number;
  profileViewed?: number;
  session: number;
  sliderIntroViewed?: number;
  sliderPlayed?: number;
  sliderSession?: number;
  slides: AnalyticSlideRead[];
  sources: AnalyticSourceRead[];
  watched: number;
  watermarkClicked: number;
  widgetIntroViewed: number;
  widgetViewed: number;
}

export type AnalyticEventReadWidgetId = number | null;

export type AnalyticEventReadSource = string | null;

export type AnalyticEventReadSlideId = number | null;

export type AnalyticEventReadChannel = EventChannelType | null;

export type AnalyticEventReadActionName = string | null;

export interface AnalyticEventRead {
  actionName?: AnalyticEventReadActionName;
  botId: string;
  channel?: AnalyticEventReadChannel;
  slideId?: AnalyticEventReadSlideId;
  source?: AnalyticEventReadSource;
  type: EventType;
  value?: number;
  widgetId?: AnalyticEventReadWidgetId;
}

export type AnalyticEventCreateWidgetId = number | null;

export type AnalyticEventCreateSource = string | null;

export type AnalyticEventCreateSlideId = number | null;

export type AnalyticEventCreateChannel = EventChannelType | null;

export type AnalyticEventCreateActionName = string | null;

export interface AnalyticEventCreate {
  actionName?: AnalyticEventCreateActionName;
  botId: string;
  channel?: AnalyticEventCreateChannel;
  slideId?: AnalyticEventCreateSlideId;
  source?: AnalyticEventCreateSource;
  type: EventType;
  value?: number;
  widgetId?: AnalyticEventCreateWidgetId;
}

export interface AnalyticActionRead {
  actionClicked: number;
  actionName: string;
  slideName: string;
}

export interface AnalyticDetailBotRead {
  actions: AnalyticActionRead[];
  id: number;
  slides: AnalyticSlideRead[];
  sources: AnalyticSourceRead[];
}

/**
 * Filter for getting accounts.
 */
export type AccountsFilterType = typeof AccountsFilterType[keyof typeof AccountsFilterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountsFilterType = {
  All: 'All',
  Owned: 'Owned',
  Joined: 'Joined',
} as const;

export type AccountUpdateZipCode = string | null;

export type AccountUpdateTaxId = string | null;

export type AccountUpdateStylesRaw = string | null;

export interface AccountUpdate {
  address?: AccountUpdateAddress;
  analyticsDefaultChannel?: AccountUpdateAnalyticsDefaultChannel;
  analyticsDisplayFields?: AccountUpdateAnalyticsDisplayFields;
  billingEmail?: AccountUpdateBillingEmail;
  businessId?: AccountUpdateBusinessId;
  businessName?: AccountUpdateBusinessName;
  buttonBackgroundColor?: AccountUpdateButtonBackgroundColor;
  buttonTextColor?: AccountUpdateButtonTextColor;
  chatVideoId?: AccountUpdateChatVideoId;
  country?: AccountUpdateCountry;
  expiredAt?: AccountUpdateExpiredAt;
  firstName?: AccountUpdateFirstName;
  lastName?: AccountUpdateLastName;
  logoId?: AccountUpdateLogoId;
  newOwnerId?: AccountUpdateNewOwnerId;
  newResellerId?: AccountUpdateNewResellerId;
  options?: AccountUpdateOptions;
  phoneNumber?: AccountUpdatePhoneNumber;
  plans?: AccountUpdatePlans;
  status?: AccountUpdateStatus;
  styles?: AccountUpdateStyles;
  stylesRaw?: AccountUpdateStylesRaw;
  taxId?: AccountUpdateTaxId;
  zipCode?: AccountUpdateZipCode;
}

export type AccountUpdateStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AccountUpdateStyles = AccountUpdateStylesAnyOf | null;

export type AccountUpdateStatus = AccountStatusType | null;

export type AccountUpdatePlans = AccountPlanSchema[] | null;

export type AccountUpdatePhoneNumber = string | null;

export type AccountUpdateOptions = AccountOptions | null;

export type AccountUpdateNewResellerId = number | null;

export type AccountUpdateNewOwnerId = number | null;

export type AccountUpdateLogoId = number | null;

export type AccountUpdateLastName = string | null;

export type AccountUpdateFirstName = string | null;

export type AccountUpdateExpiredAt = string | null;

export type AccountUpdateCountry = string | null;

export type AccountUpdateChatVideoId = number | null;

export type AccountUpdateButtonTextColor = string | null;

export type AccountUpdateButtonBackgroundColor = string | null;

export type AccountUpdateBusinessName = string | null;

export type AccountUpdateBusinessId = string | null;

export type AccountUpdateBillingEmail = string | null;

export type AccountUpdateAnalyticsDisplayFieldsAnyOf = {[key: string]: boolean};

export type AccountUpdateAnalyticsDisplayFields = AccountUpdateAnalyticsDisplayFieldsAnyOf | null;

export type AccountUpdateAnalyticsDefaultChannel = EventChannelType | null;

export type AccountUpdateAddress = string | null;

/**
 * Account status.
 */
export type AccountStatusType = typeof AccountStatusType[keyof typeof AccountStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusType = {
  Active: 'Active',
  Suspended: 'Suspended',
  Free_Trial: 'Free Trial',
} as const;

export type AccountSimpleWithAppearanceReadStylesRaw = string | null;

export type AccountSimpleWithAppearanceReadStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AccountSimpleWithAppearanceReadStyles = AccountSimpleWithAppearanceReadStylesAnyOf | null;

export type AccountSimpleWithAppearanceReadPlans = AccountPlanSchema[] | null;

export type AccountSimpleWithAppearanceReadOptions = AccountOptions | null;

export type AccountSimpleWithAppearanceReadLogo = MediaReadOutput | null;

export type AccountSimpleWithAppearanceReadChatVideo = MediaReadOutput | null;

export type AccountSimpleWithAppearanceReadButtonTextColor = string | null;

export type AccountSimpleWithAppearanceReadButtonBackgroundColor = string | null;

export type AccountSimpleWithAppearanceReadAnalyticsDisplayFieldsAnyOf = {[key: string]: boolean};

export type AccountSimpleWithAppearanceReadAnalyticsDisplayFields = AccountSimpleWithAppearanceReadAnalyticsDisplayFieldsAnyOf | null;

export type AccountSimpleWithAppearanceReadAnalyticsDefaultChannel = EventChannelType | null;

export interface AccountSimpleWithAppearanceRead {
  analyticsDefaultChannel?: AccountSimpleWithAppearanceReadAnalyticsDefaultChannel;
  analyticsDisplayFields?: AccountSimpleWithAppearanceReadAnalyticsDisplayFields;
  businessName: string;
  buttonBackgroundColor?: AccountSimpleWithAppearanceReadButtonBackgroundColor;
  buttonTextColor?: AccountSimpleWithAppearanceReadButtonTextColor;
  chatVideo?: AccountSimpleWithAppearanceReadChatVideo;
  id: number;
  logo?: AccountSimpleWithAppearanceReadLogo;
  options?: AccountSimpleWithAppearanceReadOptions;
  plans?: AccountSimpleWithAppearanceReadPlans;
  status: AccountStatusType;
  styles?: AccountSimpleWithAppearanceReadStyles;
  stylesRaw?: AccountSimpleWithAppearanceReadStylesRaw;
}

export interface AccountSimpleRead {
  businessName: string;
  id: number;
  status: AccountStatusType;
}

export interface AccountProfileUpdate {
  appearance: AppearanceUpdate;
  profile: ProfileDetailsUpdate;
}

export interface AccountProfileRead {
  appearance: AppearanceRead;
  profile: ProfileDetailsRead;
}

/**
 * Account plans.
 */
export type AccountPlanType = typeof AccountPlanType[keyof typeof AccountPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountPlanType = {
  basic: 'basic',
  trial: 'trial',
} as const;

/**
 * Account plans.
 */
export type AccountPlanStatus = typeof AccountPlanStatus[keyof typeof AccountPlanStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountPlanStatus = {
  active: 'active',
  cancelled: 'cancelled',
} as const;

export interface AccountPlanSchema {
  accountId: number;
  expiredAt: string;
  isExpired: boolean;
  planType: AccountPlanType;
  status: AccountPlanStatus;
}

export type AccountOrderBy = typeof AccountOrderBy[keyof typeof AccountOrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountOrderBy = {
  id: 'id',
  business_name: 'business_name',
  status: 'status',
} as const;

export type AccountOptionsMaxVideoDuration = number | null;

export type AccountOptionsDisableAnalytics = boolean | null;

export interface AccountOptions {
  disableAnalytics?: AccountOptionsDisableAnalytics;
  maxVideoDuration?: AccountOptionsMaxVideoDuration;
}

export type AccountListReadZipCode = string | null;

export type AccountListReadTaxId = string | null;

export type AccountListReadPlans = AccountPlanSchema[] | null;

export type AccountListReadPhoneNumber = string | null;

export type AccountListReadLastName = string | null;

export type AccountListReadFirstName = string | null;

export type AccountListReadCountry = string | null;

export type AccountListReadAddress = string | null;

export interface AccountListRead {
  address?: AccountListReadAddress;
  billingEmail: string;
  businessId: string;
  businessName: string;
  country?: AccountListReadCountry;
  firstName?: AccountListReadFirstName;
  id: number;
  lastName?: AccountListReadLastName;
  owner: UserRead;
  phoneNumber?: AccountListReadPhoneNumber;
  plans?: AccountListReadPlans;
  status: AccountStatusType;
  taxId?: AccountListReadTaxId;
  zipCode?: AccountListReadZipCode;
}

export type AccountInviteCreateRole = typeof AccountInviteCreateRole[keyof typeof AccountInviteCreateRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountInviteCreateRole = {
  Member: 'Member',
  Admin: 'Admin',
} as const;

export interface AccountInviteCreate {
  role: AccountInviteCreateRole;
  userEmail: string;
}

export type AccountDetailReadZipCode = string | null;

export type AccountDetailReadTaxId = string | null;

export type AccountDetailReadStylesRaw = string | null;

export type AccountDetailReadStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AccountDetailReadStyles = AccountDetailReadStylesAnyOf | null;

export type AccountDetailReadPlans = AccountPlanSchema[] | null;

export type AccountDetailReadPhoneNumber = string | null;

export type AccountDetailReadOptions = AccountOptions | null;

export type AccountDetailReadLogo = MediaReadOutput | null;

export type AccountDetailReadLastName = string | null;

export type AccountDetailReadFirstName = string | null;

export type AccountDetailReadCountry = string | null;

export type AccountDetailReadChatVideo = MediaReadOutput | null;

export type AccountDetailReadButtonTextColor = string | null;

export type AccountDetailReadButtonBackgroundColor = string | null;

export type AccountDetailReadAnalyticsDisplayFieldsAnyOf = {[key: string]: boolean};

export type AccountDetailReadAnalyticsDisplayFields = AccountDetailReadAnalyticsDisplayFieldsAnyOf | null;

export type AccountDetailReadAnalyticsDefaultChannel = EventChannelType | null;

export type AccountDetailReadAddress = string | null;

export interface AccountDetailRead {
  address?: AccountDetailReadAddress;
  analyticsDefaultChannel?: AccountDetailReadAnalyticsDefaultChannel;
  analyticsDisplayFields?: AccountDetailReadAnalyticsDisplayFields;
  billingEmail: string;
  businessId: string;
  businessName: string;
  buttonBackgroundColor?: AccountDetailReadButtonBackgroundColor;
  buttonTextColor?: AccountDetailReadButtonTextColor;
  chatVideo?: AccountDetailReadChatVideo;
  country?: AccountDetailReadCountry;
  firstName?: AccountDetailReadFirstName;
  id: number;
  lastName?: AccountDetailReadLastName;
  logo?: AccountDetailReadLogo;
  options?: AccountDetailReadOptions;
  phoneNumber?: AccountDetailReadPhoneNumber;
  plans?: AccountDetailReadPlans;
  status: AccountStatusType;
  styles?: AccountDetailReadStyles;
  stylesRaw?: AccountDetailReadStylesRaw;
  taxId?: AccountDetailReadTaxId;
  zipCode?: AccountDetailReadZipCode;
}

export type AccountCurrentUserReadStylesRaw = string | null;

export type AccountCurrentUserReadStylesAnyOf = {[key: string]: {[key: string]: string}};

export type AccountCurrentUserReadStyles = AccountCurrentUserReadStylesAnyOf | null;

export type AccountCurrentUserReadRoleStatus = RoleStatusType | null;

export type AccountCurrentUserReadRoleId = number | null;

export type AccountCurrentUserReadPlans = AccountPlanSchema[] | null;

export type AccountCurrentUserReadButtonTextColor = string | null;

export type AccountCurrentUserReadButtonBackgroundColor = string | null;

export interface AccountCurrentUserRead {
  billingEmail: string;
  businessId: string;
  businessName: string;
  buttonBackgroundColor?: AccountCurrentUserReadButtonBackgroundColor;
  buttonTextColor?: AccountCurrentUserReadButtonTextColor;
  id: number;
  plans?: AccountCurrentUserReadPlans;
  role: RoleType;
  roleId?: AccountCurrentUserReadRoleId;
  roleStatus?: AccountCurrentUserReadRoleStatus;
  status: AccountStatusType;
  styles?: AccountCurrentUserReadStyles;
  stylesRaw?: AccountCurrentUserReadStylesRaw;
}

export type AccountCurrentPlanReadCurrentPlan = AccountPlanSchema | null;

export interface AccountCurrentPlanRead {
  accountId: number;
  currentPlan?: AccountCurrentPlanReadCurrentPlan;
}

export type AccountCreateZipCode = string | null;

export type AccountCreateTaxId = string | null;

export type AccountCreatePlans = AccountPlanSchema[] | null;

export type AccountCreatePhoneNumber = string | null;

export type AccountCreateLastName = string | null;

export type AccountCreateFirstName = string | null;

export type AccountCreateCountry = string | null;

export type AccountCreateAddress = string | null;

export interface AccountCreate {
  address?: AccountCreateAddress;
  billingEmail: string;
  businessId: string;
  businessName: string;
  country?: AccountCreateCountry;
  firstName?: AccountCreateFirstName;
  lastName?: AccountCreateLastName;
  ownerEmail: string;
  phoneNumber?: AccountCreatePhoneNumber;
  plans?: AccountCreatePlans;
  taxId?: AccountCreateTaxId;
  zipCode?: AccountCreateZipCode;
}

